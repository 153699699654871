import {
  Button,
  CloseIcon,
  Form,
  FormInput,
  FormSelect,
  TInputUpload,
} from '@common-ui';
import { BookingFlightItem } from '@sky-booking-config/components/OrderManagement';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, Modal, Radio, UploadFile } from 'antd';
import ModalContentNotify from './ModalContentNotify';
import { useParams } from 'react-router-dom';
import {
  GENDER_TYPE_LABEL,
  PASSENGER_TYPE_LABEL,
} from '@sky-booking-config/utils';
import { useTime, useToast } from '@sky-booking-config/hooks/internals';
import useNotification from './useNotification';
import { NotificationSenderInfo } from '@api/notification/web_partnership/notification_pb';
import {
  IBookingDetail,
  IBookingNotification,
  IFileAttachment,
} from '@tixlabs/grpc-client';

import CustomEditor from './CustomEditor';
import BtnAttachFile from './BtnAttachFile';
import useAirport from '@sky-booking-config/hooks/stores/useAirport';
import { generateUUID } from '@core/utils';
import { uploadMedia } from '@tixlabs/axios-client';
import { STORAGE_URL } from '@core/constants';

// import { ClassicEditor, Alignment } from 'ckeditor5';
type Props = {
  //
};

type PropsEmail = {
  emailSend: {
    label: string;
    value: NotificationSenderInfo.AsObject;
  };
  otherEmail: string;
  titleEmail: string;
  editorContent: string;
  attachedFiles: UploadFile<any>[];
  // attachmentsList: IFileAttachment[];
};

export const DetailSendNotification = (props: Props) => {
  const { bookingCode } = useParams();
  const methodsEmail = useForm<PropsEmail>();
  const { control: controlEmail, watch, setValue } = methodsEmail;
  const otherEmail = watch('otherEmail');
  const editorContent = watch('editorContent');
  const [showOtherEmailInput, setShowOtherEmailInput] = useState(false);
  const resetFileListRef = useRef<() => void>(() => {
    ///
  });
  const handleRadioClick = () => {
    setShowOtherEmailInput(!showOtherEmailInput);
    setValue('otherEmail', '');
  };

  const selectSendFrom: {
    label: string;
    value: NotificationSenderInfo.AsObject;
  } = watch('emailSend');
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const [notificationMethod, setNotificationMethod] = useState(1);

  const [btnEmail, setBtnEmail] = useState(false);
  const [btnZalo, setBtnZalo] = useState(false);
  const [btnSMS, setBtnSMS] = useState(false);
  const [listItemSelect, setListItemSelect] = useState<
    {
      label: string;
      value: NotificationSenderInfo.AsObject;
    }[]
  >([]);

  const [modalContent, setModalContent] = useState(false);
  const [infoEmail, setInfoEmail] = useState<IBookingNotification>();

  const { formatDateTime } = useTime();
  const {
    getBookingInfo,
    getHistoryBookingNotifications,
    sendRawBookingNotification,
    getSenders,
    NotificationMethods,
  } = useNotification();
  const { fetchAirports } = useAirport();

  const [prevUploadFiles, setPrevUploadFiles] = useState<TInputUpload[]>([]);
  const [bookingFlightData, setBookingFlightData] = useState<IBookingDetail>();
  const [checkSentEmail, setCheckSentEmail] = useState<boolean>(false);
  const [listSender, setListSender] =
    useState<Array<NotificationSenderInfo.AsObject>>();
  const toast = useToast();

  const [historyNotification, setHistoryNotification] =
    useState<IBookingNotification[]>();

  const uniqueReservationCodes = Array.from(
    new Set(
      bookingFlightData?.itinerariesList.map(
        (itinerary) => itinerary?.reservationCode
      )
    )
  );
  const pnrCode = uniqueReservationCodes.join(', ');

  const handleGetBookingInfo = async (bookingCode: string) => {
    await getBookingInfo({
      bookingCode,
    }).then((res) => {
      if (res?.isSuccess) {
        setBookingFlightData(res?.data);

        const objectAirportCode = {};
        res?.data?.itinerariesList.forEach((item) => {
          item.segmentsList.forEach((sItem) => {
            objectAirportCode[sItem.departPlace] = true;
            objectAirportCode[sItem.arrivalPlace] = true;
          });
        });

        const listCode = Object.keys(objectAirportCode);
        if (listCode.length) {
          fetchAirports(listCode);
        }
      }
    });
  };

  const handleGetHistoryBookingNotifications = async (bookingCode: string) => {
    await getHistoryBookingNotifications({
      bookingCode,
    }).then((res) => {
      if (res?.isSuccess) {
        setHistoryNotification(res?.itemsList);
      }
    });
  };

  // useEffect(() => {
  //   setValue('uploadFile', prevUploadFiles);
  // }, [prevUploadFiles]);
  // useEffect(() => {
  //   if (showOtherEmailInput) {
  //     setValue(
  //       'titleEmail',
  //       `${
  //         watch('otherEmail') ? `${watch('otherEmail')} -` : ''
  //       } Thông báo cho chuyến có mã đặt chỗ ${pnrCode}`
  //     );
  //   } else {
  //     setValue(
  //       'titleEmail',
  //       `${
  //         watch('emailSend') && watch('emailSend')?.value?.senderName
  //           ? `${watch('emailSend')?.value?.senderName} -`
  //           : ''
  //       } Thông báo cho chuyến có mã đặt chỗ ${pnrCode}`
  //     );
  //   }
  // }, [otherEmail]);

  useEffect(() => {
    if (watch('attachedFiles') && watch('attachedFiles')?.length > 0)
      setCheckSentEmail(false);
  }, [watch('attachedFiles')]);

  useEffect(() => {
    if (bookingCode) {
      handleGetBookingInfo(bookingCode);
      handleGetHistoryBookingNotifications(bookingCode);
    }
  }, []);

  useEffect(() => {
    setValue(
      'titleEmail',
      `${
        watch('emailSend') && watch('emailSend')?.value?.senderName
          ? `${watch('emailSend')?.value?.senderName} -`
          : ''
      } Thông báo cho chuyến có mã đặt chỗ ${pnrCode}`
    );
  }, [selectSendFrom, bookingFlightData]);

  const handleConvertItemSelect = () => {
    if (listSender) {
      const listConvert = listSender?.map((sender) => ({
        label: `Gửi từ ${sender?.senderName} - ${sender?.sender}`,
        value: { ...sender },
      }));
      setListItemSelect(listConvert);
    }
  };

  useEffect(() => {
    handleConvertItemSelect();
  }, [listSender]);
  const handleSendEmail = async (data: PropsEmail) => {
    if (data?.attachedFiles.length > 0) {
      const fileListTransfer = data?.attachedFiles.map((f) => ({
        fileId: generateUUID(),
        fileInfo: f?.originFileObj,
        url: URL.createObjectURL(f?.originFileObj as File),
      }));
      const formData = new FormData();
      fileListTransfer.forEach((f) => {
        f.fileInfo && formData.append('files', f.fileInfo);
      });
      const { data: uploadDataRes, error } = await uploadMedia(formData);
      if (!error) {
        const attachmentsList: Array<IFileAttachment> =
          uploadDataRes.result.map((r, i) => {
            return {
              url: `${STORAGE_URL}/${r.filename}`,
              name: `${data?.attachedFiles[i]?.name}`,
            };
          });
        if (
          data?.emailSend?.value?.senderId &&
          data?.emailSend?.value?.method &&
          bookingCode
        ) {
          setIsSendingEmail(true);
          await sendRawBookingNotification({
            body: data?.editorContent,
            senderId: data?.emailSend?.value?.senderId,
            bookingCode: bookingCode,
            title: data?.titleEmail,
            method: data?.emailSend?.value?.method,
            recipientsList:
              data?.otherEmail?.length > 0 ? [data?.otherEmail] : [],
            attachmentsList: attachmentsList,
          })
            .then((res) => {
              setIsSendingEmail(false);
              if (res?.isSuccess) {
                methodsEmail.reset({
                  emailSend: { label: '', value: {} }, // Reset to empty to enforce validation
                  titleEmail: '',
                  editorContent: '',
                  otherEmail: '',
                  attachedFiles: [],
                });
                toast.success('Gửi email thành công');
                handleGetHistoryBookingNotifications(bookingCode);
                setCheckSentEmail(true);
              } else {
                toast.error(`Gửi email thất bại`);
              }
            })
            .catch(() => {
              setIsSendingEmail(false);
              toast.error(`Gửi email thất bại`);
            });
        }
      }
    } else {
      if (
        data?.emailSend?.value?.senderId &&
        data?.emailSend?.value?.method &&
        bookingCode
      ) {
        setIsSendingEmail(true);
        await sendRawBookingNotification({
          body: data?.editorContent,
          senderId: data?.emailSend?.value?.senderId,
          bookingCode: bookingCode,
          title: data?.titleEmail,
          method: data?.emailSend?.value?.method,
          recipientsList:
            data?.otherEmail?.length > 0 ? [data?.otherEmail] : [],
          attachmentsList: [],
        })
          .then((res) => {
            setIsSendingEmail(false);
            if (res?.isSuccess) {
              methodsEmail.reset({
                emailSend: { label: '', value: {} }, // Reset to empty to enforce validation
                titleEmail: '',
                editorContent: '',
                otherEmail: '',
                attachedFiles: [],
              });
              toast.success('Gửi email thành công');
              handleGetHistoryBookingNotifications(bookingCode);
            } else {
              toast.error(`Gửi email thất bại`);
            }
          })
          .catch(() => {
            setIsSendingEmail(false);
            toast.error(`Gửi email thất bại`);
          });
      }
    }
  };

  const handleGetSenders = async () => {
    await getSenders({ method: notificationMethod }).then(
      (res) => res?.isSuccess && setListSender(res?.itemsList)
    );
  };
  const handleDeleteFile = (index: number) => {
    setPrevUploadFiles((arr) => arr.filter((val, ind) => ind !== index));
  };
  const handleShowModalContent = (item: IBookingNotification) => {
    setModalContent(true);
    setInfoEmail(item);
  };

  const handleBtnEmail = () => {
    setBtnEmail(!btnEmail);
    setNotificationMethod(1);
    handleGetSenders();
  };

  const handleBtnZalo = () => {
    setBtnZalo(!btnZalo);
    setNotificationMethod(2);
  };

  const handleBtnSMS = () => {
    setBtnSMS(!btnSMS);
    setNotificationMethod(3);
  };

  const handleCloseModalContent = () => {
    setModalContent(false);
  };

  return (
    <div className='space-y-5 '>
      <div className='w-full bg-white rounded p-5 flex flex-col gap-5'>
        <div className='border-2'>
          <div className='w-full p-4 rounded flex justify-between border-b-2 text-lg/4'>
            <div>
              Thông tin đặt chỗ:{' '}
              <span className='font-semibold'>{bookingCode}</span>
            </div>

            <div>
              Tạo bởi:{' '}
              <span className='font-semibold'>
                {bookingFlightData?.partnerName}
              </span>
            </div>
          </div>

          <div className='w-full p-5 rounded flex flex-col gap-3'>
            {bookingFlightData?.itinerariesList.map((item, index) => (
              <BookingFlightItem key={index} data={item} />
            ))}

            {bookingFlightData?.listPaxList?.map((pax, index) => (
              <div key={index} className='flex gap-3 text-md'>
                <span className='font-semibold '>{`${pax?.surname} ${pax?.givenName}`}</span>
                <span>{GENDER_TYPE_LABEL[pax?.gender]}</span>
                <span>{PASSENGER_TYPE_LABEL[pax?.type]}</span>
              </div>
            ))}
          </div>
        </div>

        {historyNotification && historyNotification.length > 0 && (
          <div className='w-full border-2 p-5 rounded'>
            <div className='text-lg/4 mb-5 font-semibold'>
              Lịch sử gửi thông báo
            </div>

            <div className='grid grid-cols-7 font-semibold text-md mb-3'>
              <span className='col-span-2'>Thời gian</span>
              <span className='col-span-2'>Người gửi</span>
              <span>Gửi qua</span>
              <span>Trạng thái</span>
              <span>Nội dung</span>
            </div>

            {historyNotification?.map((item, index) => (
              <div key={index} className='grid grid-cols-7 text-md mb-2'>
                <span className='col-span-2'>
                  {formatDateTime(item?.createdAt)}
                </span>

                <span className='col-span-2'>{item?.actorName}</span>

                <span>{NotificationMethods[item?.method]}</span>
                <span>
                  {item?.status === 1
                    ? `Thành công`
                    : item?.status === 2
                    ? 'Lỗi'
                    : ``}
                </span>

                <span
                  className='text-blue-600 hover:cursor-pointer hover:text-blue-300 w-fit'
                  onClick={() => handleShowModalContent(item)}>
                  Xem nội dung
                </span>
                <Modal
                  title={
                    <span className='text-xl font-bold p-2'>
                      Nội dung thông báo
                    </span>
                  }
                  centered
                  closeIcon={
                    <CloseIcon
                      className='w-5 h-5 text-[#999999]'
                      onClick={handleCloseModalContent}
                    />
                  }
                  footer={null}
                  open={modalContent}
                  width={800}>
                  <ModalContentNotify infoEmail={infoEmail} />
                </Modal>
              </div>
            ))}
          </div>
        )}

        <div className='flex gap-3'>
          <Button
            size='sm'
            theme='primary'
            onClick={() => handleBtnEmail()}
            className='!px-5 w-1/3 h-12'>
            <span className='whitespace-nowrap'>Thông báo email</span>
          </Button>

          <Button
            size='sm'
            theme='secondary'
            onClick={() => handleBtnZalo()}
            className='!px-5 w-1/3 h-12'>
            <span className='whitespace-nowrap'>Thông báo Zalo</span>
          </Button>

          <Button
            size='sm'
            theme='neutral'
            onClick={() => handleBtnSMS()}
            className='!px-5 w-1/3 h-12'>
            <span className='whitespace-nowrap'>Thông báo SMS</span>
          </Button>
        </div>

        {btnEmail && (
          <Form
            className='w-full border-2 border-solid p-4 flex flex-col gap-4 relative'
            onSubmit={handleSendEmail}
            methods={methodsEmail}>
            <div className='flex w-full justify-between'>
              <div className='font-semibold text-end leading-[38px] text-lg/4 '>
                Gửi email
              </div>

              <FormSelect
                rules={{
                  required: 'Chọn email gửi đi',
                  validate: (value) => {
                    // Check if the value is empty or matches the undesired value
                    if (
                      value &&
                      (value.label === '' ||
                        JSON.stringify(value.value) === '{}')
                    ) {
                      return 'Chọn email gửi'; // Custom error message
                    }
                    return true; // Validation passed
                  },
                }}
                className='w-2/5'
                name='emailSend'
                placeholder='Chọn email gửi'
                selectOptions={listItemSelect}
              />
            </div>

            <div>
              <Checkbox
                className='text-md'
                title='Gửi đến email khác'
                checked={showOtherEmailInput}
                onClick={handleRadioClick}>
                Gửi đến email khác
              </Checkbox>
              {showOtherEmailInput && (
                <FormInput
                  className='w-1/2 mt-3'
                  placeholder='Nhập địa chỉ email'
                  name='otherEmail'
                  rules={{ required: 'Nhập địa chỉ email' }}
                  // value={otherEmail}
                  // onChange={(e) => setOtherEmail(e.target.value)}
                />
              )}
            </div>

            <FormInput
              className='w-full font-semibold leading-[36px] text-lg/4'
              name='titleEmail'
              placeholder='Nhập tiêu đề cho Email'
            />

            <div className='prose max-w-none'>
              <Controller
                name='editorContent'
                control={controlEmail}
                defaultValue=''
                render={({ field }) => (
                  <CustomEditor
                    data={field.value}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      field.onChange(data);
                    }}
                  />
                )}
              />
            </div>
            <BtnAttachFile checkSentEmail={checkSentEmail} />
            <Button
              disabled={isSendingEmail}
              size='sm'
              theme='primary'
              type='submit'
              className='!px-5 w-1/5 h-12'>
              <span className='whitespace-nowrap'>Gửi</span>
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default DetailSendNotification;
