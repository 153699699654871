const LogoType = {
  BLUE: 'logo-blue',
  WHITE: 'logo-white',
} as const;

type TLogoType = typeof LogoType[keyof typeof LogoType];

type Props = {
  className?: string;
  type?: TLogoType;
};

const Logo = ({ className, type = 'logo-blue' }: Props) => {
  const domain = process.env.NX_STORAGE_ASSETS || 'https://assets.deeptech.vn';
  return (
    <div className={className}>
      <img
        className='w-full h-full'
        src={`${domain}/apps/flighthub/${type}.png`}
        alt='Logo'
      />
    </div>
  );
};

export default Logo;
