import { getApiErrorMessages } from '@core/utils';
import { useAppMutation } from '@sky-booking-config/hooks/internals';
import { ROLE_USER } from '@sky-booking-config/utils';
import { EUserStatusPartner, IUserBasicInfo } from '@tixlabs/grpc-client';
import {
  partnerService,
  partnerShopUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export type TPagination = {
  pageCurrent: number;
  totalPage: number;
  totalRecord: number;
  pageLimit: number;
};

type TIssuerListForm = {
  name: string;
  userName: string;
  partnershopId: string;
  role: string;
  creatorId: string;
  status: EUserStatusPartner;
};

const useManageOfficeId = () => {
  const manageOfficeIdMethod = useForm<TIssuerListForm>({
    defaultValues: {
      name: '',
      userName: '',
      partnershopId: '',
      role: ROLE_USER.AGENCY_ISSUER,
      creatorId: '',
      status: EUserStatusPartner.NONE,
    },
  });

  const [issuerList, setIssuerList] = useState<IUserBasicInfo[]>();
  const [pagination, setPagination] = useState<TPagination>({
    pageCurrent: 1,
    pageLimit: 10,
    totalPage: 1,
    totalRecord: 1,
  });
  const [allProviders, setAllProviderOption] = useState<
    SelectOptionItem[] | undefined
  >([]);

  const {
    mutateAsync: listPartnerUserBasicInfo,
    isLoading: isLoadingGetUserList,
  } = useAppMutation({
    mutationKey: ['partnerShopUserServiceClient', 'listPartnerUserBasicInfo'],
    mutationFn: partnerShopUserServiceClient.listPartnerUserBasicInfo,
  });

  const { mutateAsync: getProviders, isLoading: isLoadingProviders } =
    useAppMutation({
      mutationKey: ['partnerService', 'getProviders'],
      mutationFn: partnerService.getProviders,
    });

  const handleSubmit = async (formData: TIssuerListForm) => {
    try {
      const { isSuccess, errorCode, data } = await listPartnerUserBasicInfo({
        filter: {
          ...formData,
        },
        pagination: {
          pageNumber: 1,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setIssuerList(data?.itemsList);

      data?.pagination && setPagination(data?.pagination);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  const changePage = async (pageNumber: number) => {
    const formData = manageOfficeIdMethod.getValues();
    try {
      const { isSuccess, errorCode, data } = await listPartnerUserBasicInfo({
        filter: {
          ...formData,
        },
        pagination: {
          pageNumber,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      if (data?.itemsList) {
        setIssuerList(data?.itemsList);
      }

      data?.pagination && setPagination(data?.pagination);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  const getAllProviderOption = async () => {
    try {
      const { itemsList } = await getProviders({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setAllProviderOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get provider:', msg);
    }
  };

  return {
    allProviders,
    issuerList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    changePage,
    handleSubmit,
    getAllProviderOption,
  };
};

export default useManageOfficeId;
