import {
  BaggageHandIcon,
  BaggageIcon,
  DollarIcon,
  PlaneFillIcon,
  SeatClassIcon,
} from '@common-ui';
import { IBookingItinerary, IHubItinerary } from '@tixlabs/grpc-client';
import { ISegment } from '@tixlabs/grpc-client';

export function PriceSegmentInfo({
  segmentInfo,
  hubItinerary,
}: {
  hubItinerary: IHubItinerary | IBookingItinerary;
  segmentInfo: ISegment;
}) {
  const handBaggage = hubItinerary?.freeBaggageList?.find(
    (item) => item.isHandBaggage
  )?.name;

  const baggage = hubItinerary?.freeBaggageList?.find(
    (item) => !item.isHandBaggage
  )?.name;

  return (
    <div className=' rounded border  border-neutral-5 overflow-hidden'>
      <div className='text-sm flex items-center gap-x-[5px] px-2.5 py-1.5 rounded bg-neutral-5'>
        <span>Chặng bay</span>
        <span className='text-neutral-10 font-semibold'>
          {segmentInfo.departPlace}
        </span>
        <PlaneFillIcon className='w-4 h-4 text-neutral-7 rotate-90' />{' '}
        <span className='text-neutral-10 font-semibold'>
          {segmentInfo.arrivalPlace}
        </span>
      </div>
      <div className=' bg-neutral-1 grid grid-cols-[3fr,2fr] divide-x'>
        <div className='grid grid-cols-2 grid-rows-2 gap-1 px-2.5 py-4'>
          {[
            {
              label: 'Hạng chỗ',
              Icon: SeatClassIcon,
              value: hubItinerary.bookingClass,
            },
            {
              label: 'Fare basis',
              Icon: DollarIcon,
              value: hubItinerary.fareBasis,
            },
            {
              label: '',
              Icon: null,
              value: '',
            },
            {
              label: 'Khoang',
              Icon: PlaneFillIcon,
              value: (
                <span title={hubItinerary.cabinClass}>
                  {hubItinerary.cabinClass}
                </span>
              ),
            },
          ].map(({ Icon, label, value }, index) => (
            <div
              key={index}
              className='flex flex-nowrap gap-x-[5px] items-center text-sm text-neutral-10 leading-4'>
              {Icon && <Icon className='w-4 h-4 text-neutral-7 shrink-0' />}
              {label && (
                <span className='whitespace-nowrap min-w-[90px]'>
                  {label}:{' '}
                </span>
              )}
              <span className='whitespace-nowrap font-bold truncate'>
                {value}
              </span>
            </div>
          ))}
        </div>
        <div className='grid grid-cols-1 grid-rows-2 gap-1 px-2.5 py-4'>
          {[
            {
              label: 'Hành lý ký gửi',
              Icon: BaggageIcon,
              value: baggage ? (
                <div title={baggage} className='line-clamp-2'>
                  {baggage}
                </div>
              ) : (
                <span className='text-red-5 font-normal'>N/A</span>
              ),
            },
            {
              label: 'Hành lý xách tay',
              Icon: BaggageHandIcon,
              value: handBaggage ? (
                <div title={handBaggage} className='line-clamp-2'>
                  {handBaggage}
                </div>
              ) : (
                <span className='text-red-5 font-normal'> N/A</span>
              ),
            },
          ].map(({ Icon, label, value }, index) => (
            <div
              key={index}
              className='flex flex-nowrap gap-x-[5px] items-center text-sm text-neutral-10 leading-4'>
              <Icon className='w-4 h-4 text-neutral-7 shrink-0' />
              <span className='whitespace-nowrap min-w-[130px]'>{label}: </span>
              <span className='font-bold'>{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PriceSegmentInfo;
