import { BasicRes, Empty, PaginationReq } from '@api/base/base_pb';
import { PartnerUserServiceClient } from '@api/partner/web_partner_admin/partner_user_grpc_web_pb';
import {
  ChangePasswordReq,
  CreatePartnerUserReq,
  CreatePartnerUserRes,
  CreatePartnerUserWithRolesReq,
  GetMeReq,
  GetMeRes,
  ListPartnerUserReq,
  ListPartnerUserRes,
  RetrievePartnerUserReq,
  RetrievePartnerUserRes,
  UpdateUserReq,
} from '@api/partner/web_partner_admin/partner_user_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class PartnerUserApi extends BaseApi<PartnerUserServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerUserServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getMe = (): Promise<GetMeRes.AsObject> => {
    const req = new GetMeReq();
    return this.grpc<GetMeReq, GetMeRes, GetMeRes.AsObject>(
      this.serviceClient.getMe,
      req,
      {}
    );
  };

  updatePartnerUser = ({
    id,
    email = '',
    name = '',
    password = '',
    phone = '',
    status,
  }: UpdateUserReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateUserReq();

    req.setId(id);
    req.setEmail(email);
    req.setName(name);
    req.setPassword(password);
    req.setPhone(phone);
    req.setStatus(status);

    return this.grpc<UpdateUserReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateUser,
      req,
      {}
    );
  };

  retrievePartnerUser = ({
    id,
  }: RetrievePartnerUserReq.AsObject): Promise<RetrievePartnerUserRes.AsObject> => {
    const req = new RetrievePartnerUserReq();

    req.setId(id);

    return this.grpc<
      RetrievePartnerUserReq,
      RetrievePartnerUserRes,
      RetrievePartnerUserRes.AsObject
    >(this.serviceClient.retrievePartnerUser, req, {});
  };

  createPartnerUser = ({
    email,
    name,
    password,
    phone,
    userName,
    status,
  }: CreatePartnerUserReq.AsObject): Promise<CreatePartnerUserRes.AsObject> => {
    const req = new CreatePartnerUserReq();

    req.setEmail(email);
    req.setName(name);
    req.setPassword(password);
    req.setPhone(phone);
    req.setUserName(userName);
    req.setStatus(status);

    return this.grpc<
      CreatePartnerUserReq,
      CreatePartnerUserRes,
      CreatePartnerUserRes.AsObject
    >(this.serviceClient.createPartnerUser, req, {});
  };
  listPartnerUser = ({
    filter,
    pagination,
  }: ListPartnerUserReq.AsObject): Promise<ListPartnerUserRes.AsObject> => {
    const req = new ListPartnerUserReq();

    if (filter) {
      const filterObj = new ListPartnerUserReq.Filter();
      filterObj.setUsername(filter.username || '');
      filterObj.setName(filter.name || '');
      filterObj.setPhone(filter.phone || '');
      filterObj.setStatus(filter.status);
      req.setFilter(filterObj);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListPartnerUserReq,
      ListPartnerUserRes,
      ListPartnerUserRes.AsObject
    >(this.serviceClient.listPartnerUser, req, {});
  };
  changePassword = ({ oldPassword, password }: ChangePasswordReq.AsObject) => {
    const req = new ChangePasswordReq();
    req.setOldPassword(oldPassword);
    req.setPassword(password);
    return this.grpc<ChangePasswordReq, Empty, Empty.AsObject>(
      this.serviceClient.changePassword,
      req,
      {}
    );
  };

  createPartnerUserWithRoles = ({
    email,
    name,
    password,
    phone,
    userName,
    status,
    rolesList,
  }: CreatePartnerUserWithRolesReq.AsObject): Promise<CreatePartnerUserRes.AsObject> => {
    const req = new CreatePartnerUserWithRolesReq();

    req.setEmail(email);
    req.setName(name);
    req.setPassword(password);
    req.setPhone(phone);
    req.setUserName(userName);
    req.setStatus(status);
    req.setRolesList(rolesList);

    return this.grpc<
      CreatePartnerUserWithRolesReq,
      CreatePartnerUserRes,
      CreatePartnerUserRes.AsObject
    >(this.serviceClient.createPartnerUserWithRoles, req, {});
  };
}

export const partnerUserServiceClient = new PartnerUserApi();
export default partnerUserServiceClient;
