import {
  Button,
  ButtonLoading,
  Form,
  FormInput,
  FormRadio,
  FormSelect,
  LoadingRing,
  User3FillIcon,
} from '@common-ui';
import ModalContent from '@payment-portal/components/Modal/ModalContent';
import { useAppMutation } from '@payment-portal/hooks/internals';
import { useErrorMessageData } from '@payment-portal/hooks/stores';
import { EModalMode, EStepChangePassword } from '@payment-portal/types';
import { EModalEditUser } from '@payment-portal/types/ui/modal';
import {
  EPartnershipRole,
  SELECT_ROLELIST_OPTIONS,
  USER_PARTNERSHIP_STATUS_OPTIONS,
  patternValidateEmail,
  patternValidatePhoneNumber,
} from '@payment-portal/utils';
import { EUserStatus } from '@tixlabs/grpc-client';
import {
  IUpdateUserV2Req,
  IUserInfo,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalChangePassword } from './components';

type Props = {
  //
};

export const EditUser = (props: Props) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [partnerUserInfo, setPartnerUserInfo] = useState<IUserInfo>();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { getErrorMessage } = useErrorMessageData();
  const [stepChangePassword, setStepChangePassword] =
    useState<EStepChangePassword>(EStepChangePassword.CHANGE_PASSWORD);
  const methods = useForm<
    Omit<IUpdateUserV2Req, 'roleList'> & { roleList: EPartnershipRole }
  >({
    defaultValues: {
      contactInfo: {
        email: '',
        name: '',
        phoneNumber: '',
      },
      userId: userId,
      roleList: EPartnershipRole.ACCOUNTANT,
      status: EUserStatus.ACTIVE,
    },
    mode: 'onBlur',
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [stateModal, setStateModal] = useState<EModalEditUser>(
    EModalEditUser.CONFIRM
  );

  const { mutateAsync: updateUserV2, isLoading: isLoadingUpdateUserV2 } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'updateUserV2'],
      mutationFn: partnerUserServiceClient.updateUserV2,
      onSuccess: ({ isSuccess, errorCode }) => {
        //
        if (isSuccess) {
          setIsOpenModal(true);
        } else {
          // handle errorCode when not successful
          setErrorMsg(getErrorMessage(errorCode));
        }
      },
      onError: (error) => {
        // handle error (system, network)
      },
    });
  const { mutateAsync: getUserDetailV2, isLoading: isLoadingRetrieve } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'getUserDetailV2'],
      mutationFn: partnerUserServiceClient.getUserDetailV2,
      onSuccess: ({ isSuccess, errorCode, data }) => {
        if (isSuccess && data && data.userInfo) {
          setPartnerUserInfo(data.userInfo);
          methods.reset({
            userId: data.userInfo?.id,
            contactInfo: {
              email: data.userInfo?.contact?.email,
              name: data.userInfo?.contact?.name,
              phoneNumber: data.userInfo?.contact?.phoneNumber,
            },
            // roleList: [...data.userInfo.rolesList, 'super_admin'],
            roleList: data.userInfo.rolesList[0] as EPartnershipRole,
            status: data.userInfo.status,
          });
        } else {
          // handle errorCode when not successful
        }
      },
      onError: (error) => {
        // handle error (system, network)
      },
    });

  const handleSubmit = async (
    data: Omit<IUpdateUserV2Req, 'roleList'> & { roleList: EPartnershipRole }
  ) => {
    data.status = +data.status;
    await updateUserV2({
      ...data,
      roleList: [data.roleList],
    });
  };

  const handleOk = () => {
    setIsOpenModal(false);
    navigate('/dashboard/user-management/');
  };

  const genarateModalFooter = (state: EModalEditUser, onClick: () => void) => {
    const content = {
      [EModalEditUser.CONFIRM]: (
        <div className='flex flex-col'>
          <Button onClick={onClick} className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
      [EModalEditUser.CANCEL]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={() => {
              navigate('/dashboard/user-management/');
            }}>
            Xác nhận huỷ bỏ
          </Button>
          <Button
            className='flex-1'
            onClick={() => {
              setIsOpenModal(false);
              setStateModal(EModalEditUser.CONFIRM);
            }}>
            Tiếp tục chỉnh sửa
          </Button>
        </div>
      ),
      [EModalEditUser.CHANGE_PASSWORD]: {
        [EStepChangePassword.SUCCESS]: (
          <div className='flex flex-col'>
            <Button
              onClick={() => {
                setIsOpenModal(false);
              }}
              className='px-7 py-2.5'>
              Đóng
            </Button>
          </div>
        ),
        [EStepChangePassword.CHANGE_PASSWORD]: null,
      },
    };

    if (state === EModalEditUser.CHANGE_PASSWORD) {
      return content[state][stepChangePassword];
    }

    return content[state];
  };

  const genarateModalContent = (state: EModalEditUser) => {
    const content = {
      [EModalEditUser.CONFIRM]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.SUCCESS}
          content='Chỉnh sửa người dùng thành công'
        />
      ),
      [EModalEditUser.CANCEL]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.WARNING}
          content='Huỷ chỉnh sửa người dùng'
        />
      ),
      [EModalEditUser.CHANGE_PASSWORD]: {
        [EStepChangePassword.CHANGE_PASSWORD]: (
          <ModalChangePassword
            onChangePasswordSuccess={() =>
              setStepChangePassword(EStepChangePassword.SUCCESS)
            }
            onCloseModal={() => {
              setIsOpenModal(false);
              setStateModal(EModalEditUser.CONFIRM);
            }}
            userInfo={partnerUserInfo}
          />
        ),
        [EStepChangePassword.SUCCESS]: (
          <ModalContent
            className='w-full'
            mode={EModalMode.SUCCESS}
            title='Đổi mật khẩu thành công'
          />
        ),
      },
    };
    if (state === EModalEditUser.CHANGE_PASSWORD) {
      return content[state][stepChangePassword];
    }
    return content[state];
  };

  const getPartnerUserInfo = async () => {
    userId && (await getUserDetailV2({ userId: userId }));
  };
  const data = methods.watch();

  useEffect(() => {
    getPartnerUserInfo();
  }, []);

  useEffect(() => {
    setErrorMsg(null);
  }, [methods.formState.isDirty]);

  return isLoadingRetrieve ? (
    <div className='w-screen h-screen flex items-center justify-center'>
      <LoadingRing />
    </div>
  ) : (
    partnerUserInfo && (
      <>
        <Modal
          open={isOpenModal}
          closeIcon={false}
          footer={genarateModalFooter(stateModal, handleOk)}
          centered={true}>
          {genarateModalContent(stateModal)}
        </Modal>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex flex-col space-y-3 max-w-[900px]'>
          <div className='flex flex-col space-y-5'>
            <div className='flex space-x-2.5 items-center'>
              <User3FillIcon className='w-5 h-5 shrink-0' />
              <span className='font-semibold text-lg'>
                Sửa thông tin người dùng
              </span>
            </div>
            <div className='flex flex-col space-y-5 bg-white border rounded p-4'>
              <div className='flex flex-col space-y-1.5'>
                <div className='flex flex-col space-y-2 5'>
                  <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                    Thông tin tài khoản
                  </h3>
                  <div className='border-b border-neutral-5'></div>
                </div>
                <div className='grid grid-cols-3 items-baseline gap-2.5'>
                  <FormInput
                    name='username'
                    placeholder='Nhập tên đăng nhập'
                    label='Tên đăng nhập'
                    inputProps={{
                      disabled: true,
                    }}
                    value={partnerUserInfo.username}
                  />

                  <div className='flex-1 self-end'>
                    <Button
                      className='min-h-[39px] min-w-[180px] w-full'
                      onClick={() => {
                        setIsOpenModal(true);
                        setStateModal(EModalEditUser.CHANGE_PASSWORD);
                        setStepChangePassword(
                          EStepChangePassword.CHANGE_PASSWORD
                        );
                      }}>
                      Đổi mật khẩu
                    </Button>
                  </div>
                  <FormSelect
                    name='roleList'
                    label='Vai trò'
                    inputProps={{
                      isOnlyValue: true,
                    }}
                    selectOptions={SELECT_ROLELIST_OPTIONS}
                  />

                  <div className='self-stretch flex flex-col space-y-3 align-baseline'>
                    <label>Trạng thái</label>
                    <div className='flex items-center gap-5 pt-1.5'>
                      {USER_PARTNERSHIP_STATUS_OPTIONS.map((option, index) => {
                        return (
                          <FormRadio
                            key={index}
                            name='status'
                            value={option.value as EUserStatus}
                            inputProps={{
                              defaultChecked:
                                option.value === partnerUserInfo.status,
                            }}
                            label={option.label}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col space-y-1.5'>
                <div className='flex flex-col space-y-2 5'>
                  <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                    Thông tin liên hệ
                  </h3>
                  <div className='border-b border-neutral-5'></div>
                </div>
                <div className='flex items-baseline space-x-2.5'>
                  <FormInput
                    name='contactInfo.name'
                    placeholder='Nhập tên thành viên'
                    label='Tên thành viên'
                    inputProps={{
                      disabled: true,
                      defaultValue: partnerUserInfo.name,
                    }}
                  />
                  <FormInput
                    rules={{
                      validate: {
                        emailPattern: (value: string) => {
                          if (value.length > 0) {
                            return (
                              value.match(patternValidateEmail) ||
                              'Email không hợp lệ'
                            );
                          } else {
                            return true;
                          }
                        },
                      },
                      onChange(event) {
                        methods.clearErrors('contactInfo.email');
                      },
                    }}
                    name='contactInfo.email'
                    label='Email'
                    placeholder='Nhập email'
                    inputProps={{
                      defaultValue: partnerUserInfo.email,
                    }}
                  />
                  <FormInput
                    rules={{
                      validate: {
                        phonePattern: (value: string) => {
                          if (value.length > 0) {
                            return (
                              value.match(patternValidatePhoneNumber) ||
                              'Số điện thoại không hợp lệ'
                            );
                          } else {
                            return true;
                          }
                        },
                      },
                      onChange(event) {
                        methods.clearErrors('contactInfo.phoneNumber');
                      },
                      maxLength: {
                        value: 13,
                        message: 'Số điện thoại tối đa 13 kí tự.',
                      },
                    }}
                    name='contactInfo.phoneNumber'
                    label='Số điện thoại'
                    placeholder='Nhập số điện thoại'
                    inputProps={{
                      defaultValue: partnerUserInfo.phoneNumber,
                    }}
                  />
                </div>
              </div>
              {errorMsg && <span className='text-red-5'>{errorMsg}</span>}
            </div>
          </div>
          <div className='flex flex-row items-center justify-end gap-4'>
            <Button
              theme='primary'
              variant='outline'
              onClick={() => {
                setStateModal(EModalEditUser.CANCEL);
                setIsOpenModal(true);
              }}>
              Huỷ bỏ
            </Button>
            <ButtonLoading
              theme='primary'
              loading={isLoadingUpdateUserV2}
              isShowChildWhenLoading
              type='submit'
              disabled={
                partnerUserInfo.contact?.phoneNumber ===
                  data.contactInfo?.phoneNumber &&
                partnerUserInfo.contact?.email === data.contactInfo?.email &&
                partnerUserInfo.contact?.name === data.contactInfo?.name &&
                partnerUserInfo.status === +data.status &&
                partnerUserInfo.rolesList.join(',') === data.roleList
              }>
              Cập nhật
            </ButtonLoading>
          </div>
        </Form>
      </>
    )
  );
};
