import { Button, Edit2FillIcon, InfoIcon } from '@common-ui';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { TPagination } from './useManageOfficeId';
import {
  LabelUserStatusPartner,
  ROLE_USER,
  checkRoleUser,
} from '@sky-booking-config/utils';
import cn from 'classnames';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import { EUserStatusPartner, IOfficeInfo } from '@tixlabs/grpc-client';

type Props = {
  data: IOfficeInfo[];
  isLoading?: boolean;
  pagination: TPagination;
  onChangePage: (pageNumber: number) => Promise<void>;
};

const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const StatusUser = ({ status }: { status: EUserStatusPartner }) => {
  return (
    <span
      className={cn('font-bold', {
        'text-green-7': status === EUserStatusPartner.ACTIVE,
        'text-orange-500': status === EUserStatusPartner.INACTIVE,
      })}>
      {LabelUserStatusPartner[status]}
    </span>
  );
};

export const ListOfficeId = ({
  data,
  isLoading,
  onChangePage,
  pagination,
}: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserStore();
  const columns: ColumnsType<IOfficeInfo> = [
    {
      key: 'agentCode',
      dataIndex: 'agentCode',
      title: <TitleColumns title='Agent Code' />,
      render: (_, record) => {
        return <span>{record.agentCode}</span>;
      },
    },
    {
      key: 'domain',
      dataIndex: 'domain',
      title: <TitleColumns title='Domain' />,
      render: (_, record) => {
        return <span>{record.domain?.name}</span>;
      },
    },
    {
      key: 'providerConfigsList',
      dataIndex: 'providerConfigsList',
      title: <TitleColumns title='Provider' />,
      render: (_, record) => {
        return (
          <span>
            {record.providerConfigsList
              .filter((item) => item.enabled === true)
              .map((item) => item.name)
              .join(', ')}
          </span>
        );
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TitleColumns title='Trạng thái' />,
      render: (_, record) => {
        return <StatusUser status={record.status} />;
      },
    },
  ];

  checkRoleUser(ROLE_USER.SUPER_ADMIN, userData) &&
    [
      {
        key: 'managerName',
        dataIndex: 'managerName',
        title: <TitleColumns title='AM' />,
      },
      {
        key: 'action',
        dataIndex: 'action',
        title: <TitleColumns title='Thao tác' />,
        render: (_, record) => {
          return (
            <div className='flex items-center gap-x-1.5'>
              <Button
                variant='ghost'
                onClick={() => {
                  navigate(`./detail-agent?id=${record.id}`);
                }}
                prefixIcon={<InfoIcon />}>
                Chi tiết
              </Button>
            </div>
          );
        },
      },
    ].forEach((item) => columns.push(item));
  checkRoleUser(ROLE_USER.OFFICE_MANAGER, userData) &&
    columns.push({
      key: 'action',
      dataIndex: 'action',
      title: <TitleColumns title='Thao tác' />,
      render: (_, record) => {
        return (
          <div className='flex items-center gap-x-1.5'>
            <Button
              variant='ghost'
              onClick={() => {
                navigate(`./update-agent?id=${record.id}`);
              }}
              prefixIcon={<Edit2FillIcon />}>
              Cập nhật
            </Button>
          </div>
        );
      },
    });

  return (
    <Table
      dataSource={data}
      columns={columns}
      size='small'
      loading={isLoading}
      pagination={
        pagination.totalPage > 1 && {
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onChangePage,
        }
      }
    />
  );
};

export default ListOfficeId;
