import {
  BookingIcon,
  Button,
  ButtonLoading,
  FileExcelLineIcon,
  Form,
  FormSelect,
  SELECT_TRANSACTION_TYPE_OPTIONS,
  SearchLineIcon,
} from '@common-ui';
import { addDay, subDay } from '@core/utils';
import {
  IGetTransactionsReq,
  transactionServiceApi,
} from '@tixlabs/grpc-client/web';

import { FormInput, FormInputDateFromTo, TFromToDate } from '@common-ui';
import { useAppMutation, useTime } from '@payment-portal/hooks/internals';
import { ONE_DAY_TIMESTAMP } from '@payment-portal/utils';
import { IPaginationRes, ITransaction } from '@tixlabs/grpc-client';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import TableContent from './TableContent';
import { exportTransactions } from '@tixlabs/axios-client';
import { TransactionType } from '@api/base/enum_pb';

type Props = {
  //
};

const DEFAULT_PAGE_LIMIT = 50;

const MAX_FILTER_DAY = 30;

export const ListTransactionCtn = (props: Props) => {
  const DEFALT_TRANSACTION_FILTER: Pick<IGetTransactionsReq, 'filter'> & {
    filterDate: TFromToDate;
  } = {
    filter: {
      bookingCode: '',
      from: new Date().setHours(0, 0, 0, 0) - ONE_DAY_TIMESTAMP * 7,
      agentCode: '',
      agentName: '',
      provider: '',
      to: new Date().setHours(23, 59, 59, 999),
      transactionType: 0,
    },
    filterDate: {
      startDate: new Date(
        new Date(Date.now() - ONE_DAY_TIMESTAMP * 7).setHours(0, 0, 0, 0)
      ),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    },
  };

  const { formatDateCustom } = useTime();
  const methods = useForm<
    Pick<IGetTransactionsReq, 'filter'> & {
      filterDate: TFromToDate;
    }
  >({
    defaultValues: DEFALT_TRANSACTION_FILTER,
  });

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });
  const [filterReq, setFilterReq] = useState<
    Pick<IGetTransactionsReq, 'filter'>
  >({
    filter: DEFALT_TRANSACTION_FILTER.filter,
  });
  const [listTransaction, setListTransaction] = useState<ITransaction[]>([]);
  const { mutateAsync: getTransactions, isLoading: isLoadingGetTransactions } =
    useAppMutation({
      mutationKey: ['transactionServiceApi', 'getTransactions'],
      mutationFn: transactionServiceApi.getTransactions,
      onSuccess: ({ isSuccess, itemsList, errorCode, pagination }) => {
        if (isSuccess) {
          pagination && setPaginationRes(pagination);
          // filter order has itinerariesList empty
          setListTransaction(itemsList);
        } else {
          console.log('errorCode', errorCode);
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    });

  const handleSubmit = async (
    data: Pick<IGetTransactionsReq, 'filter'> & {
      filterDate: TFromToDate;
    }
  ) => {
    if (data.filter) {
      setFilterReq({
        filter: {
          ...data.filter,
          from: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.from,
          to: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.to,
        },
      });
      await getTransactions({
        filter: {
          ...data.filter,
          from: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.from,
          to: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.to,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
        orderByList: [],
      });
    }
  };

  const handlePagination = async (page: number) => {
    await getTransactions({
      filter: filterReq.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
      orderByList: [],
    });
  };

  const handleExportListTransactions = async () => {
    if (filterReq.filter) {
      const data = await exportTransactions({
        agent_code: filterReq.filter.agentCode,
        from: filterReq.filter.from,
        to: filterReq.filter.to,
        agent_name: filterReq.filter.agentName,
        provider: filterReq.filter.provider,
        transactionType: filterReq.filter.transactionType,
      });
      if (data.error) {
        toast.error('Lỗi xảy ra khi tải về danh sách giao dịch.');
        return;
      }
      const blob = new Blob([data.data], { type: 'text/csv' });

      // Create a link to the Blob
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Danhsachgiaodich_${formatDateCustom('DDMMYYYY')(
        filterReq.filter.from
      )}_${formatDateCustom('DDMMYYYY')(filterReq.filter.to)}.csv`;
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();
      document.body.removeChild(link);
    }
  };

  const getListTransaction = useCallback(async () => {
    await getTransactions({
      filter: DEFALT_TRANSACTION_FILTER.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
      orderByList: [],
    });
  }, []);

  useEffect(() => {
    getListTransaction();
  }, []);

  const { startDate: fromDate, endDate: toDate } = methods.watch('filterDate');

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <BookingIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Quản lý giao dịch</span>
      </div>
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4'>
          <div className='flex space-x-2.5'>
            <FormInputDateFromTo
              inputProps={{
                placeholderStart: 'Từ ngày',
                placeholderEnd: 'Đến ngày',
                showFilter: true,
                inputSize: 'sm',
                minStartDate: toDate
                  ? subDay(toDate, MAX_FILTER_DAY)
                  : undefined,
                maxStartDate: new Date(),
                maxEndDate: fromDate
                  ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                    ? addDay(fromDate, MAX_FILTER_DAY)
                    : new Date()
                  : new Date(),
                isFullTime: true,
              }}
              label='Thời gian'
              name='filterDate'
            />
            <FormInput
              name='filter.agentCode'
              label='Mã đại lý'
              placeholder='Nhập mã đại lý'
            />
            <FormInput
              name='filter.agentName'
              label='Tên đại lý'
              placeholder='Nhập tên đại lý'
            />
            <FormInput
              name='filter.bookingCode'
              label='Mã đơn hàng'
              placeholder='Nhập mã đơn hàng'
            />
            <FormSelect
              name='filter.transactionType'
              selectOptions={SELECT_TRANSACTION_TYPE_OPTIONS}
              label={'Loại'}
              inputProps={{
                isOnlyValue: true,
              }}
            />
            <div className='flex flex-col justify-end'>
              <ButtonLoading
                loading={isLoadingGetTransactions}
                isShowChildWhenLoading
                type='submit'
                theme='secondary'
                size='sm'
                prefixIcon={<SearchLineIcon />}
                className='font-semibold !px-5'>
                Tìm kiếm
              </ButtonLoading>
            </div>
          </div>
          <div className='flex space-x-2.5'>
            <div className='flex flex-col justify-end'>
              <Button
                size='sm'
                theme='green'
                onClick={handleExportListTransactions}
                prefixIcon={<FileExcelLineIcon />}
                className='!px-5'>
                <span className='whitespace-nowrap'>Tải về</span>
              </Button>
            </div>
          </div>
        </Form>
        <TableContent
          listTransaction={listTransaction}
          isLoading={isLoadingGetTransactions}
          pagination={paginationRes}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default ListTransactionCtn;
