import { SelectOptionItem } from '@tixlabs/types';

import { EUserStatus, EUserStatusPartner } from '@tixlabs/grpc-client';
import { EPartnershipRole } from './permission';

export const USER_PARTNER_STATUS_LABEL = {
  [EUserStatusPartner.ACTIVE]: 'Hoạt động',
  [EUserStatusPartner.INACTIVE]: 'Tạm khoá',
};

export const USER_PARTNER_STATUS_OPTIONS = [
  {
    label: USER_PARTNER_STATUS_LABEL[EUserStatusPartner.ACTIVE],
    value: EUserStatusPartner.ACTIVE,
  },
  {
    label: USER_PARTNER_STATUS_LABEL[EUserStatusPartner.INACTIVE],
    value: EUserStatusPartner.INACTIVE,
  },
];

export const SELECT_PARTNER_STATUS_OPTIONS: SelectOptionItem[] = [
  {
    value: EUserStatusPartner.NONE,
    label: 'Tất cả',
  },
  {
    value: EUserStatusPartner.ACTIVE,
    label: 'Hoạt động',
  },
  {
    value: EUserStatusPartner.INACTIVE,
    label: 'Tạm khoá',
  },
];

export const USER_PARTNERSHIP_STATUS_LABEL = {
  [EUserStatus.ACTIVE]: 'Hoạt động',
  [EUserStatus.INACTIVE]: 'Tạm khoá',
};

export const USER_PARTNERSHIP_STATUS_OPTIONS: SelectOptionItem[] = [
  {
    label: USER_PARTNERSHIP_STATUS_LABEL[EUserStatus.ACTIVE],
    value: EUserStatus.ACTIVE,
  },
  {
    label: USER_PARTNERSHIP_STATUS_LABEL[EUserStatus.INACTIVE],
    value: EUserStatus.INACTIVE,
  },
];

export const SELECT_PARTNERSHIP_STATUS_OPTIONS: SelectOptionItem[] = [
  {
    value: EUserStatus.USER_STATUS_INVALID,
    label: 'Tất cả',
  },
  {
    value: EUserStatus.ACTIVE,
    label: 'Hoạt động',
  },
  {
    value: EUserStatus.INACTIVE,
    label: 'Tạm khoá',
  },
];

export const SELECT_ROLELIST_OPTIONS: SelectOptionItem[] = [
  {
    label: 'Accountant',
    value: 'accountant',
  },
  {
    label: 'Appraisal',
    value: 'appraisal',
  },
  {
    label: 'Appraisal manager',
    value: 'appraisal_manager',
  },
  {
    label: 'Ev operator',
    value: 'e_operation',
  },
  {
    label: 'Customer support',
    value: 'customer_support',
  },
  {
    label: 'Monitor',
    value: 'monitor',
  },
];

export const SELECT_ROLELIST_OPTIONS_FILTER: SelectOptionItem[] = [
  {
    label: 'Tất cả',
    value: EPartnershipRole.ALL,
  },
  {
    label: 'Accountant',
    value: EPartnershipRole.ACCOUNTANT,
  },
  {
    label: 'Appraisal',
    value: EPartnershipRole.APPRAISAL,
  },
  {
    label: 'Appraisal manager',
    value: EPartnershipRole.APPRAISAL_MANAGER,
  },
];
