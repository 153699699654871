import {
  CloseIcon,
  FormInput,
  FormSelect,
  FormSelectSearch,
  InfoIcon,
  FormMultipleSelectSearch,
  FormGroupRadio,
  FormInputPrice,
} from '@common-ui';
import { useCurrency } from '@sky-booking-config/hooks/internals';
import { OPTIONS_FLIGHT_ITINERARY } from '@sky-booking-config/utils';
import { IBookingClassDetailHub } from '@tixlabs/grpc-client';
import { useEffect, useMemo, useState } from 'react';
import { useVICountry } from '@sky-booking-config/hooks/apps';
import { FlightItineraryType } from '@api/airplane/base_pb';
import { useFormContext } from 'react-hook-form';

type Props = {
  title: string;
  onClose: () => void;
  airlineOptions: {
    label: string;
    value: string;
    bookingClass: Array<IBookingClassDetailHub>;
  }[];
  handleSearch: (throttleQuery: string) => void;
  isUpdate?: boolean;
};

const HIDDEN_FEE_TYPE_AMOUNT = 'amount';
const HIDDEN_FEE_TYPE_PERCENT = 'percent';

const HiddenFeeFormAction = ({
  title,
  onClose,
  handleSearch,
  airlineOptions,
  isUpdate,
}: Props) => {
  const { formatPrice } = useCurrency();
  const { countrySelectOptions } = useVICountry();
  const { watch, setValue } = useFormContext();
  const watchFlightType = watch('type');
  const watchFeeType = watch('feeType') || HIDDEN_FEE_TYPE_AMOUNT;
  const watchAirline = watch('airlineCode');
  const watchRouteFrom = watch('routeFrom');
  const watchBookingClass: string[] = watch('bookingClassList');
  const watchAmount = watch('amount');

  const [isLengthExceeded, setIsLengthExceeded] = useState(false);

  const FeeOptions = [
    {
      label: 'Số tiền (VND)',
      value: HIDDEN_FEE_TYPE_AMOUNT,
    },
    {
      label: 'Phần trăm (%)',
      value: HIDDEN_FEE_TYPE_PERCENT,
    },
  ];

  const bookingClass = useMemo(() => {
    let temp: IBookingClassDetailHub[] = [];

    airlineOptions.forEach((item) => {
      if (item.value === watchAirline) {
        temp = item.bookingClass;
      }
    });
    return [
      { label: 'Tất cả', value: '' },
      ...temp.map((item) => ({
        label: item.name,
        value: item.code,
      })),
    ];
  }, [watchAirline, airlineOptions]);

  useEffect(() => {
    setValue('amount', '');
  }, [watchFeeType, setValue]);

  useEffect(() => {
    setValue('bookingClassList', []);
    setValue('bookingClass', '');
  }, [watchAirline]);

  useEffect(() => {
    if (watchBookingClass.includes('') && watchBookingClass.length > 1) {
      setValue('bookingClassList', ['']);
    }
  }, [watchBookingClass, setValue]);

  useEffect(() => {
    if (watchAirline) {
      setValue('bookingClassList', ['']);
    }
  }, [watchAirline]);

  const handleModalClose = () => {
    onClose();
    setValue('amount', '');
    setIsLengthExceeded(false);
  };

  return (
    <div className='w-[500px] space-y-5'>
      <div className='flex items-center '>
        <h3 className='flex-1 text-lg font-semibold text-black'>{title}</h3>
        <CloseIcon
          className='w-5 h-5 text-neutral-7 hover:text-neutral-8 cursor-pointer'
          onClick={handleModalClose}
        />
      </div>
      <div className='space-y-5'>
        <FormSelect
          name='type'
          selectOptions={OPTIONS_FLIGHT_ITINERARY}
          label='Loại chuyến bay'
          placeholder='Chọn loại chuyến bay'
          inputProps={{ isOnlyValue: true }}
          rules={{
            required: {
              value: true,
              message: 'Chọn loại chuyến bay',
            },
          }}
        />

        <div className='flex flex-col space-y-1.5'>
          <FormSelectSearch
            name='routeFrom'
            label='Bay từ'
            placeholder='Chọn quốc gia'
            selectOptions={countrySelectOptions}
            inputProps={{
              isOnlyValue: true,
              disabled: watchFlightType === FlightItineraryType.IDOMESTIC,
            }}
          />
          <FormSelectSearch
            name='routeTo'
            label='Bay đến'
            placeholder='Chọn quốc gia'
            selectOptions={countrySelectOptions}
            inputProps={{
              isOnlyValue: true,
              disabled: watchFlightType === FlightItineraryType.IDOMESTIC,
            }}
            rules={{
              validate: {
                checkValid: (v) => {
                  return watchRouteFrom && v === '' ? 'Chọn quốc gia' : true;
                },
              },
            }}
          />
        </div>

        <div className='flex flex-col space-y-1.5'>
          <FormSelectSearch
            name='airlineCode'
            label='Hãng hàng không'
            placeholder='Chọn hãng hàng không'
            handleSearch={handleSearch}
            selectOptions={airlineOptions}
            labelProps={{ isRequired: true }}
            inputProps={{
              isOnlyValue: true,
            }}
            rules={{
              validate: {
                checkValid: (v) => {
                  return v === '' || !!v || 'Chọn hãng hàng không';
                },
              },
            }}
          />
        </div>
        {isUpdate ? (
          <FormSelectSearch
            name='bookingClass'
            label='Hạng ghế'
            placeholder='Chọn Hạng đặt chỗ'
            selectOptions={bookingClass}
            inputProps={{
              isOnlyValue: true,
              disabled: !watchAirline,
            }}
          />
        ) : (
          <FormMultipleSelectSearch
            key={watchAirline || 'default'}
            name='bookingClassList'
            label='Hạng ghế'
            placeholder='Chọn Hạng đặt chỗ'
            selectOptions={bookingClass}
            inputProps={{
              itemSelectedClassName: '!max-w-full',
              disabled: !watchAirline,
            }}
          />
        )}

        <FormGroupRadio
          name='feeType'
          label='Phí cộng thêm'
          className='flex'
          radioOptions={FeeOptions}
          defaultOption={HIDDEN_FEE_TYPE_AMOUNT}
        />
        {watchFeeType === HIDDEN_FEE_TYPE_PERCENT ? (
          <FormInput
            name='amount'
            placeholder='Nhập số tiền'
            inputProps={{
              disabled: !watchFeeType,
              type: 'number',
            }}
            rules={{
              required: 'Nhập giá trị',
              validate: (value) =>
                (value >= 0 && value <= 100) ||
                'Giá trị phải nằm trong khoảng từ 0 đến 100',
            }}
          />
        ) : (
          <FormInputPrice
            name='amount'
            placeholder='Nhập số tiền'
            inputProps={{
              disabled: !watchFeeType,
              type: 'number',
              maxLength: 10,
              setIsLengthExceeded: setIsLengthExceeded,
              isLengthExceeded: isLengthExceeded,
            }}
            rules={{
              required: 'Nhập giá trị',
              validate: {
                checkValid: (value) => {
                  const numValue = Number(value);
                  if (numValue < 0) {
                    return 'Giá trị phải lớn hơn 0';
                  } else if (numValue > 9999999999) {
                    return 'Giá trị không vượt quá 10 số';
                  }
                  return true;
                },
              },
            }}
          />
        )}
      </div>
      <div className='flex items-center gap-[5px]'>
        <InfoIcon className='w-5 h-5 text-neutral-7' />
        <span className='text-neutral-7 text-base leading-[25px]'>
          Hidden fee được áp dụng cho tất cả người dùng thuộc đại lý.
        </span>
      </div>
    </div>
  );
};

export default HiddenFeeFormAction;
