import { Card, SeatIcon } from '@common-ui';
import { ISeat, ISegment } from '@tixlabs/grpc-client';
import { IBookingPassenger } from '@tixlabs/grpc-client/web-partner';
import { AirlineInfo } from '@web-admin/components/Flights/AirlineInfo';

import { useCurrency } from '@web-admin/hooks/internals';
import { PAX_TYPE_SHORT_LABEL } from '@web-admin/utils';

type Props = {
  getSegmentInfo: (
    itineraryId: string,
    segmentIndex: number
  ) => ISegment | undefined;
  passengersList: IBookingPassenger[];
};

function FieldItem({
  label,
  seat,
  segment,
}: {
  label: string;
  seat: ISeat;
  segment: ISegment;
}) {
  const { formatPrice } = useCurrency();

  return (
    <tr>
      <td align='left' className='uppercase'>
        {label}
      </td>
      <td className=' font-semibold' align='right'>
        <span>
          {segment.departPlace} → {segment.arrivalPlace}
        </span>
      </td>
      <td className=' font-semibold' align='right'>
        <div className='flex justify-end'>
          <AirlineInfo code={segment.carrierMarketing} showName={false} />
        </div>
      </td>
      <td className=' font-semibold' align='right'>
        <span> Số ghế: {seat.displayName}</span>
      </td>
      <td className=' font-semibold' align='right'>
        <span className='text-secondary'>
          {formatPrice(seat.seatCharge?.totalAmount || 0)}
        </span>
      </td>
    </tr>
  );
}

export const BookingPriceChooseSeatInfo = ({
  getSegmentInfo,
  passengersList,
}: Props) => {
  return (
    <Card
      title={'Chỗ ngồi'}
      classNameTitle='border-b-0'
      className='w-full border-0'
      classNameChildren='p-2.5 '
      icon={<SeatIcon />}>
      <table className='table-auto w-full view-table'>
        <thead>
          <tr>
            <th align='left'>Hành khách</th>
            <th align='right'>Chặng bay</th>
            <th align='right'>Hãng</th>
            <th align='right'>Số ghế</th>
            <th align='right'>Giá</th>
          </tr>
        </thead>
        <tbody>
          {passengersList.map((pax, paxIndex) => {
            const label = `${pax.lastName} ${pax.firstName} (${
              PAX_TYPE_SHORT_LABEL[pax.type]
            })`;
            return pax.seatsList.map((seat, seatIndex) => {
              const segment = getSegmentInfo(
                seat.itineraryId,
                seat.segmentIndex
              );

              return (
                segment && (
                  <FieldItem
                    seat={seat.seatInfo as ISeat}
                    segment={segment}
                    key={`${paxIndex}-${seatIndex}`}
                    label={label}
                  />
                )
              );
            });
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default BookingPriceChooseSeatInfo;
