import { LoadingRing } from '@common-ui';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import { useAppDispatch } from '@sky-booking-config/stores';
import { fetchUserData } from '@sky-booking-config/stores/reducers/user';
// import { useUserStore } from '@web-booker/hooks/stores';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  //
};

export const NotFound = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isFetched, setIsFetched] = useState(false);
  const { isFetching, isLogin, userData } = useUserStore();

  const getUserData = async () => {
    await dispatch(fetchUserData());
    setIsFetched(true);
  };

  useEffect(() => {
    if (isLogin && !userData) {
      getUserData();
      return;
    }

    if (!isLogin) {
      navigate('/');
    }
    setIsFetched(true);
  }, [isLogin, userData]);
  return (
    <div className='w-full h-[100vh] flex justify-center items-center'>
      {isFetched ? <span>Not found</span> : <LoadingRing />}
    </div>
  );
};

export default NotFound;
