import { Toggle } from '@common-ui';
import { SelectOptionItem } from '@tixlabs/types';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TCreateUserForm } from './useCreateAndUpdateUser';

type Props = {
  label: string;
  value: SelectOptionItem['value'];
};

const ToggleProvider = ({ label, value }: Props) => {
  const methods = useFormContext<TCreateUserForm>();
  const providerListValue = useWatch({ name: 'providerIdsList' });

  const toggleValue = useMemo(() => {
    return providerListValue?.some((formValue) => formValue === value);
  }, [providerListValue, value]);

  function onChangeToggle(event: boolean) {
    if (event) {
      const updateProviderList = [...providerListValue, value];
      methods.setValue('providerIdsList', updateProviderList);
    } else {
      const updateProviderList = providerListValue.filter(
        (formValue) => formValue !== value
      );
      methods.setValue('providerIdsList', updateProviderList);
    }
  }

  return (
    <div className='flex gap-x-5 items-center'>
      <Toggle value={toggleValue} onChange={onChangeToggle} />
      <span>{label}</span>
    </div>
  );
};

export default ToggleProvider;
