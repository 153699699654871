import { getApiErrorMessages } from '@core/utils';
import { useAppMutation, useToast } from '@sky-booking-config/hooks/internals';
import { ROLE_USER } from '@sky-booking-config/utils';
import { EUserStatus } from '@tixlabs/grpc-client';
import {
  ICreateUserV2Req,
  IUpdateUserV2Req,
  IUserInfo,
  partnerService,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export type TCreateUserForm = ICreateUserV2Req;
export type TUpdateUserForm = IUpdateUserV2Req & {
  username: string;
};

export const useCreateAndUpdateUser = () => {
  const toast = useToast();
  const navigate = useNavigate();

  // null is for idle, if not found => undefined
  const [userInfo, setUserInfo] = useState<IUserInfo | undefined | null>(null);

  const createUserMethod = useForm<TCreateUserForm>({
    defaultValues: {
      username: '',
      contactInfo: {
        email: '',
        name: '',
        phoneNumber: '',
      },
      domainId: undefined,
      officeIdsList: [],
      password: '',
      status: EUserStatus.ACTIVE,
      roleList: [ROLE_USER.OFFICE_MANAGER],
      providerIdsList: [],
    },
    mode: 'onBlur',
  });

  const updateUserMethod = useForm<TUpdateUserForm>({
    defaultValues: {
      contactInfo: {
        email: '',
        name: '',
        phoneNumber: '',
      },
      domainId: undefined,
      officeIdsList: [],
      status: EUserStatus.ACTIVE,
      roleList: [ROLE_USER.OFFICE_MANAGER],
      providerIdsList: [],
      userId: '',
    },
    mode: 'onBlur',
  });

  const [domainListOption, setDomainListOption] = useState<SelectOptionItem[]>(
    []
  );

  const [providerListOption, setProviderListOption] = useState<
    SelectOptionItem[]
  >([]);

  const [officeIdListOption, setOfficeIdListOption] = useState<
    SelectOptionItem[]
  >([]);

  const { mutateAsync: getUserDetailV2, isLoading: isLoadingGetUserDetailV2 } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'getUserDetailV2'],
      mutationFn: partnerUserServiceClient.getUserDetailV2,
    });

  const { mutateAsync: createUserV2, isLoading: isLoadingCreateUserV2 } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'createUserV2'],
      mutationFn: partnerUserServiceClient.createUserV2,
    });

  const { mutateAsync: updateUserV2, isLoading: isLoadingUpdateUserV2 } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'updateUserV2'],
      mutationFn: partnerUserServiceClient.updateUserV2,
    });

  const {
    mutateAsync: adminChangeUserPassword,
    isLoading: isLoadingChangePassword,
  } = useAppMutation({
    mutationKey: ['partnerUserServiceClient', 'adminChangeUserPassword'],
    mutationFn: partnerUserServiceClient.adminChangeUserPassword,
  });

  const { mutateAsync: getDomains, isLoading: isLoadingDomains } =
    useAppMutation({
      mutationKey: ['partnerService', 'getDomains'],
      mutationFn: partnerService.getDomains,
    });

  const { mutateAsync: getProviders, isLoading: isLoadingProviders } =
    useAppMutation({
      mutationKey: ['partnerService', 'getProviders'],
      mutationFn: partnerService.getProviders,
    });

  const { mutateAsync: getUnassignedOffices, isLoading: isLoadingOfficeIds } =
    useAppMutation({
      mutationKey: ['partnerService', 'getUnassignedOffices'],
      mutationFn: partnerService.getUnassignedOffices,
    });

  const getUserInfoById = async (userId: string) => {
    try {
      const { isSuccess, errorCode, data } = await getUserDetailV2({ userId });
      if (!isSuccess && errorCode) {
      }
      setUserInfo(data?.userInfo || undefined);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get domain:', msg);
    }
  };

  const getDomainListOption = async () => {
    try {
      const { itemsList, isSuccess, errorCode } = await getDomains({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setDomainListOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get domain:', msg);
    }
  };

  const getProviderListOption = async () => {
    try {
      const { isSuccess, errorCode, itemsList } = await getProviders({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setProviderListOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get provider:', msg);
    }
  };

  const getOfficeIdListOption = async () => {
    try {
      const { itemsList, errorCode, isSuccess } = await getUnassignedOffices(
        {}
      );
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setOfficeIdListOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get office:', msg);
    }
  };

  const handleSubmitCreateUser = async (formData: TCreateUserForm) => {
    try {
      const { isSuccess, errorCode, data } = await createUserV2(formData);
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Tạo user thành công!');
      createUserMethod.reset();
      navigate('/dashboard/manage-agent-users');
    } catch (error) {
      const errCode = getApiErrorMessages(error)[0];
      switch (errCode) {
        case 'PARTNERSHIP_USERNAME_IS_EXISTS':
          createUserMethod.setError('username', {
            message: 'Tên tài khoản đã tồn tại!',
          });
          break;
        case 'INVALID_INPUT':
          toast.error('Thông tin user không hợp lệ!');
          break;
        default:
          toast.error('Lỗi tạo user!');
          break;
      }
    }
  };

  const handleSubmitUpdateUser = async (formData: TUpdateUserForm) => {
    try {
      const { isSuccess, errorCode } = await updateUserV2({ ...formData });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật thông tin thành công!');
      // navigate('/dashboard/manage-agent-users');
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      switch (msg) {
        case 'INVALID_INPUT':
          toast.error('Thông tin user không hợp lệ!');
          break;
        default:
          toast.error('Lỗi tạo user!');
          break;
      }
    }
  };

  const handleChangePassword = async (newPassword: string) => {
    return await adminChangeUserPassword({
      userId: userInfo?.id || '',
      newPassword,
    });
  };

  return {
    userInfo,
    createUserMethod,
    updateUserMethod,
    isLoadingChangePassword,
    isLoadingUpdateUserV2,
    isLoadingGetUserDetailV2,
    isLoadingCreateUserV2,
    isLoadingOption:
      isLoadingDomains || isLoadingProviders || isLoadingOfficeIds,
    domainListOption,
    providerListOption,
    officeIdListOption,

    handleChangePassword,
    handleSubmitUpdateUser,
    handleSubmitCreateUser,
    getUserInfoById,
    getDomainListOption,
    getProviderListOption,
    getOfficeIdListOption,
  };
};

export default useCreateAndUpdateUser;
