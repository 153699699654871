import {
  BookingIcon,
  Email,
  GroupLineIcon,
  ListSettingsFillIcon,
  MoneyDollarCircleLineIcon,
  OrdersIcon,
  TicketIcon,
  TopUpIcon,
  User2FillIcon,
} from '@icon';
import { GROUP_FEATURE, MenuItem } from '@sky-booking-config/utils';
import React from 'react';

export function getItem({
  label,
  key,
  icon,
  children,
  type = undefined,
}: {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: 'group';
}): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const ALL_MENU_GROUP_INFO: Record<
  string,
  { label: string; icon: React.ReactNode; listFeature: string[] }
> = {
  [GROUP_FEATURE.PORTAL_AGENTS_MANAGER]: {
    label: 'Agent Management',
    icon: <User2FillIcon className='shrink-0 w-4 h-4' />,
    listFeature: [
      'manage-agent-users',
      //  'providers',
      'agents',
    ],
  },
  [GROUP_FEATURE.PORTAL_ORDERS_MANAGER]: {
    label: 'Order Management',
    icon: <OrdersIcon className='shrink-0 w-4 h-4' />,
    listFeature: ['list-booking', 'list-transaction'],
  },
  [GROUP_FEATURE.SKYHUB_CONFIG_MANAGER]: {
    label: 'Config Fee Management',
    icon: <ListSettingsFillIcon className='shrink-0 w-4 h-4' />,
    listFeature: ['config-wallet-and-fee', 'hidden-fee'],
  },
  [GROUP_FEATURE.PORTAL_PAYMENT_MANAGER]: {
    label: 'Finance Management',
    icon: <TopUpIcon className='shrink-0 w-4 h-4' />,
    listFeature: [
      'top-up-request-management',
      'transaction-management',
      'create-top-up-request',
      'user-management',
    ],
  },
  [GROUP_FEATURE.PORTAL_CUSTOMER_SUPPORT]: {
    label: 'Customer Support',
    icon: <TopUpIcon className='shrink-0 w-4 h-4' />,
    listFeature: ['send-notification', 'send-notification/:bookingCode'],
  },
  [GROUP_FEATURE.PORTAL_ISSUER_MANAGER]: {
    label: 'Quản lý xuất hộ',
    icon: <TopUpIcon className='shrink-0 w-4 h-4' />,
    listFeature: [
      'issuer-management',
      'list-transfer-booking-request',
      'transfer-booking-fee-management',
    ],
  },
  [GROUP_FEATURE.PORTAL_REPORT_MANAGER]: {
    label: 'Report Management',
    icon: <OrdersIcon className='shrink-0 w-4 h-4' />,
    listFeature: ['report-booking-management'],
  },
};
export const allMenuItems = {
  'manage-agent-users': getItem({
    label: 'Tài khoản Quản lý Agent',
    key: 'manage-agent-users',
    icon: <User2FillIcon className='shrink-0 w-4 h-4' />,
  }),
  // sửa
  'send-notification': getItem({
    label: 'Gửi thông báo',
    key: 'send-notification',
    icon: <Email className='shrink-0 w-4 h-4' />,
  }),
  // providers: getItem({
  //   label: 'Provider',
  //   key: 'providers',
  //   icon: <OrdersIcon className='shrink-0 w-4 h-4' />,
  // }),
  agents: getItem({
    label: 'Agents',
    key: 'agents',
    icon: <TicketIcon className='shrink-0 w-4 h-4' />,
  }),
  'config-wallet-and-fee': getItem({
    label: 'Cấu hình ví & phí',
    key: 'config-wallet-and-fee',
    icon: <MoneyDollarCircleLineIcon className='shrink-0 w-4 h-4' />,
  }),
  'hidden-fee': getItem({
    label: 'Hidden fee',
    key: 'hidden-fee',
    icon: <User2FillIcon className='shrink-0 w-4 h-4' />,
  }),
  'user-management': getItem({
    label: 'Quản lý nguời dùng',
    key: 'user-management',
    icon: <GroupLineIcon className='shrink-0 w-4 h-4' />,
  }),
  // 'add-user': getItem({
  //   label: 'Thêm người dùng',
  //   key: 'add-user',
  //   icon: <UserAddFillIcon className='shrink-0 w-4 h-4' />,
  // }),
  'top-up-request-management': getItem({
    label: 'Quản lý yêu cầu nạp tiền',
    key: 'top-up-request-management',
    icon: <TopUpIcon className='shrink-0 w-4 h-4' />,
  }),
  'create-top-up-request': getItem({
    label: 'Tạo yêu cầu nạp tiền',
    key: 'create-top-up-request',
    icon: <TopUpIcon className='shrink-0 w-4 h-4' />,
  }),
  'transaction-management': getItem({
    label: 'Quản lý thông tin giao dịch',
    key: 'transaction-management',
    icon: <BookingIcon className='shrink-0 w-4 h-4' />,
  }),
  'list-transaction': getItem({
    label: 'Quản lý giao dịch',
    key: 'list-transaction',
    icon: <BookingIcon className='shrink-0 w-4 h-4' />,
  }),
  'list-booking': getItem({
    label: 'Quản lý đặt chổ',
    key: 'list-booking',
    icon: <BookingIcon className='shrink-0 w-4 h-4' />,
  }),
  'issuer-management': getItem({
    label: 'Quản lý người xuất hộ',
    key: 'issuer-management',
    icon: <User2FillIcon className='shrink-0 w-4 h-4' />,
  }),
  'list-transfer-booking-request': getItem({
    label: 'Danh sách xuất hộ',
    key: 'list-transfer-booking-request',
    icon: <BookingIcon className='shrink-0 w-4 h-4' />,
  }),
  'transfer-booking-fee-management': getItem({
    label: 'Quản lý phí xuất hộ',
    key: 'transfer-booking-fee-management',
    icon: <ListSettingsFillIcon className='shrink-0 w-4 h-4' />,
  }),
  'report-booking-management': getItem({
    label: 'Báo cáo',
    key: 'report-booking-management',
    icon: <ListSettingsFillIcon className='shrink-0 w-4 h-4' />,
  }),
};
export function generateGroupMenu({
  label,
  key,
  icon,
  children,
}: {
  label: string;
  key: GROUP_FEATURE;
  icon: React.ReactNode;
  children?: MenuItem[];
}): MenuItem {
  return getItem({
    label: label,
    key: key,
    icon: icon,
    children: children,
  });
}
