import { TransferBookingRequestStatus } from '@api/airplane/base_pb';
import { getApiErrorMessages } from '@core/utils';
import { useAppMutation } from '@sky-booking-config/hooks/internals';
import {
  ETransferBookingRequestStatus,
  ITransferBookingRequestInfo,
} from '@tixlabs/grpc-client';
import { flightApiService } from '@tixlabs/grpc-client/web-partnership';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export type TPagination = {
  pageCurrent: number;
  totalPage: number;
  totalRecord: number;
  pageLimit: number;
};

type TOfficeIdListForm = {
  agentCode: string;
  requestId: string;
  issuerName: string;
  requesterName: string;
  status: ETransferBookingRequestStatus;
};

const useManageOfficeId = () => {
  const manageOfficeIdMethod = useForm<TOfficeIdListForm>({
    defaultValues: {
      agentCode: '',
      issuerName: '',
      requesterName: '',
      requestId: '',
      status: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_NONE,
    },
  });
  const navigate = useNavigate();

  const [officeIdList, setOfficeIdList] = useState<
    ITransferBookingRequestInfo[]
  >([]);
  const [pagination, setPagination] = useState<TPagination>({
    pageCurrent: 1,
    pageLimit: 10,
    totalPage: 1,
    totalRecord: 1,
  });

  const {
    mutateAsync: listTransferBookingRequest,
    isLoading: isLoadingGetUserList,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'listTransferBookingRequest'],
    mutationFn: flightApiService.listTransferBookingRequest,
  });

  const handleSubmit = async (formData: TOfficeIdListForm) => {
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listTransferBookingRequest({
        filter: {
          ...formData,
        },
        pagination: {
          pageNumber: 1,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setOfficeIdList(itemsList);

      paginationRes && setPagination(paginationRes);
    } catch (error) {
      const errCode = getApiErrorMessages(error)[0];
      switch (errCode) {
        case 'INVALID_INPUT':
          toast.error('Thông tin tạo phí không hợp lệ!');
          break;
        case 'PERMISSION_DENIED':
          toast.error('Bạn không được quyền làm điều đó!');
          break;
        default:
          toast.error('Đã có sự cố trong việc truy cập tài nguyên!');
          break;
      }
    }
  };

  const changePage = async (pageNumber: number) => {
    const formData = manageOfficeIdMethod.getValues();
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listTransferBookingRequest({
        // filter: {
        //   ...formData,
        // },
        pagination: {
          pageNumber,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setOfficeIdList(itemsList);

      paginationRes && setPagination(paginationRes);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  return {
    officeIdList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    changePage,
    handleSubmit,
  };
};

export default useManageOfficeId;
