import { Empty, PaginationReq } from '@api/base/base_pb';

import {
  LoginReq,
  LoginRes,
  LoginV2Req,
  LoginV2Res,
  LogoutReq,
} from '@api/partnership/web_partnership/auth_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { BookingServiceClient } from '@api/skyhub/web_partnership/booking_grpc_web_pb';
import {
  GetBookingInfoReq,
  GetBookingInfoRes,
  GetUpcomingBookingsReq,
  GetUpcomingBookingsReqFilter,
  GetUpcomingBookingsRes,
} from '@api/skyhub/web_partnership/booking_pb';

class BookingServiceApi extends BaseApi<BookingServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new BookingServiceClient(this.url, null, this.optsDev);
  }

  getBookingInfo = ({
    bookingCode,
  }: GetBookingInfoReq.AsObject): Promise<GetBookingInfoRes.AsObject> => {
    const req = new GetBookingInfoReq();
    req.setBookingCode(bookingCode);

    return this.grpc<
      GetBookingInfoReq,
      GetBookingInfoRes,
      GetBookingInfoRes.AsObject
    >(this.serviceClient.getBookingInfo, req, {});
  };

  getUpcomingBookings = ({
    filter,
    pagination,
  }: GetUpcomingBookingsReq.AsObject): Promise<GetUpcomingBookingsRes.AsObject> => {
    const req = new GetUpcomingBookingsReq();

    const reqFilter = new GetUpcomingBookingsReqFilter();
    filter?.text && reqFilter.setText(filter?.text);
    filter?.notificationStatus &&
      reqFilter.setNotificationStatus(filter?.notificationStatus);
    filter?.maximumDays && reqFilter.setMaximumDays(filter?.maximumDays);

    const reqPagination = new PaginationReq();
    pagination?.pageNumber &&
      reqPagination.setPageNumber(pagination?.pageNumber);

    pagination?.pageLimit && reqPagination.setPageLimit(pagination?.pageLimit);

    req.setFilter(reqFilter);
    req.setPagination(reqPagination);

    return this.grpc<
      GetUpcomingBookingsReq,
      GetUpcomingBookingsRes,
      GetUpcomingBookingsRes.AsObject
    >(this.serviceClient.getUpcomingBookings, req, {});
  };
}

export const bookingServiceApi = new BookingServiceApi();
export default bookingServiceApi;
