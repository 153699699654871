import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  FormSelect,
  LoadingRing,
} from '@common-ui';
import {
  PartnerUserStatusListCreateOptions,
  patternValidateEmail,
  patternValidatePhoneNumber,
} from '@sky-booking-config/utils';
import { SelectOptionItem } from '@tixlabs/types';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCreateAndUpdateOfficeId from '../useCreateAndUpdateOfficeId';
import ModalUpdateOfficeId, {
  ESwitchModal,
  TModalInfo,
} from './ModalUpdateOfficeId';

type Props = {
  //
};

export const UpdateOfficeIdCtn = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const {
    officeIdInfo,
    isLoadingGetOfficeDetail,
    isLoadingChangeOfficePassword,
    updateOfficeIdMethod,
    domainListOption,
    allProviders,
    isLoadingUpdateOfficeProviders,
    isLoadingUpdateOffice,
    handleUpdateOfficeProviders,
    handleChangePassword,
    handleSubmitUpdateOfficeId,
    getOfficeDetailInfo,
    getDomainListOption,
    getAllProviderOption,
    // getProviderListOption,
  } = useCreateAndUpdateOfficeId();

  const providerOptions: SelectOptionItem[] = useMemo(() => {
    return (
      (allProviders.length > 0 &&
        officeIdInfo &&
        officeIdInfo.providerConfigsList.length > 0 &&
        officeIdInfo.providerConfigsList.map((providerConfig) => {
          const provider = allProviders.find(
            (provider) => provider.value === providerConfig.value
          ) as SelectOptionItem;
          return providerConfig.enabled
            ? {
                ...provider,
                enabled: true,
              }
            : provider;
        })) ||
      []
    );
  }, [officeIdInfo, allProviders]);

  const [modalInfo, setModalInfo] = useState<TModalInfo>({
    open: false,
    state: ESwitchModal.NONE,
    ownerId: '',
    id: '',
    providerListOption: providerOptions,
    closable: true,
  });

  useEffect(() => {
    if (id) {
      getOfficeDetailInfo(id);
    }
  }, [id]);

  useEffect(() => {
    if (officeIdInfo !== undefined && officeIdInfo !== null) {
      const { domain, email, id, name, phone, shopName, status, userName } =
        officeIdInfo;
      updateOfficeIdMethod.setValue('email', email);
      updateOfficeIdMethod.setValue('id', id);
      updateOfficeIdMethod.setValue('name', name);
      updateOfficeIdMethod.setValue('phone', phone);
      updateOfficeIdMethod.setValue('shopName', shopName);
      updateOfficeIdMethod.setValue('status', status);
      updateOfficeIdMethod.setValue('userName', userName);
      domain && updateOfficeIdMethod.setValue('domain', domain.value);
    }
  }, [officeIdInfo]);

  useEffect(() => {
    getDomainListOption();
    getAllProviderOption();
  }, []);

  return (
    <Form
      methods={updateOfficeIdMethod}
      onSubmit={handleSubmitUpdateOfficeId}
      className='h-full'>
      {isLoadingGetOfficeDetail || officeIdInfo === null ? (
        <div className='w-full h-full flex justify-center items-center gap-y-5'>
          <LoadingRing />
        </div>
      ) : officeIdInfo === undefined ? (
        <div className='w-full h-full flex justify-center items-center gap-y-5'>
          <span>Not found user</span>
        </div>
      ) : (
        <div className='max-w-[1100px] flex flex-col gap-y-5'>
          <div className='flex flex-col gap-y-5 p-4 border rounded'>
            <div className='flex gap-x-5'>
              <div className='w-1/3'>
                <FormInput
                  name='userName'
                  label='Tên đăng nhập'
                  placeholder='Nhập tên đăng nhập'
                  inputProps={{ disabled: true }}
                />
              </div>
              <div className='w-1/3 flex flex-col justify-end'>
                <Button
                  className='max-w-[200px]'
                  onClick={() =>
                    setModalInfo({
                      ...modalInfo,
                      open: true,
                      state: ESwitchModal.CHANGE_PASSWORD,
                      //! Owner id
                      ownerId: officeIdInfo?.ownerId || '',
                      id: officeIdInfo?.id || '',
                    })
                  }>
                  Đổi mật khẩu
                </Button>
              </div>
              <div className='w-1/3'>
                <FormGroupRadio
                  name='status'
                  label='Trạng thái'
                  className='flex'
                  radioOptions={PartnerUserStatusListCreateOptions}
                />
              </div>
            </div>
            <div className='w-2/3'>
              <FormSelect
                name='domain'
                label='Domain'
                placeholder='Chọn domain'
                selectOptions={domainListOption}
                inputProps={{ isOnlyValue: true, disabled: true }}
              />
            </div>
          </div>

          <div className='flex flex-col gap-y-2 p-4 border rounded'>
            <span className='text-lg font-semibold'>Thông tin liên hệ</span>
            <div className='flex flex-col gap-y-5'>
              <div className='flex gap-x-5'>
                <div className='w-1/3'>
                  <FormInput
                    name='name'
                    label='Họ tên'
                    placeholder='Nhập họ tên'
                    rules={{ required: 'Nhập họ tên' }}
                  />
                </div>
                <div className='w-1/3'>
                  <FormInput
                    name='phone'
                    label='Điện thoại'
                    placeholder='Nhập điện thoại'
                    rules={{
                      // required: 'Nhập diện thoại',
                      // validate: {
                      //   phonePattern: (value: string) => {
                      //     if (value.length > 0) {
                      //       return (
                      //         value.match(patternValidatePhoneNumber) ||
                      //         'Số điện thoại không hợp lệ'
                      //       );
                      //     } else {
                      //       return true;
                      //     }
                      //   },
                      // },
                      pattern: {
                        value: patternValidatePhoneNumber,
                        message: 'Số điện thoại không hợp lệ',
                      },
                      onChange(event) {
                        updateOfficeIdMethod.clearErrors('phone');
                      },
                    }}
                  />
                </div>
                <div className='w-1/3'>
                  <FormInput
                    name='email'
                    label='Email'
                    placeholder='Nhập email'
                    rules={{
                      // required: 'Nhập email',
                      // validate: {
                      //   emailPattern: (value: string) => {
                      //     if (value.length > 0) {
                      //       return (
                      //         value.match(patternValidateEmail) ||
                      //         'Email không hợp lệ'
                      //       );
                      //     } else {
                      //       return true;
                      //     }
                      //   },
                      // },
                      pattern: {
                        value: patternValidateEmail,
                        message: 'Email không hợp lệ',
                      },
                      onChange(event) {
                        updateOfficeIdMethod.clearErrors('email');
                      },
                    }}
                  />
                </div>
              </div>
              <div className='w-full'>
                <FormInput
                  name='shopName'
                  label='Công ty/Đại lý'
                  placeholder='Tên công ty/đại lý'
                  rules={{
                    required: 'Nhập công ty/đại lý',
                    onChange(event) {
                      updateOfficeIdMethod.clearErrors('shopName');
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-y-2 p-4 border rounded'>
            <div className='flex justify-between items-center'>
              <span className='text-lg font-semibold'>Danh sách Provider</span>
              <Button
                onClick={() =>
                  setModalInfo({
                    ...modalInfo,
                    open: true,
                    state: ESwitchModal.UPDATE_PROVIDER,
                    //! Owner id
                    ownerId: officeIdInfo?.id || '',
                    providerListOption: providerOptions,
                  })
                }>
                Cập nhật Provider
              </Button>
            </div>
          </div>

          <div className='flex justify-end gap-x-5'>
            <Button
              variant='outline'
              onClick={() => {
                // Go back router
                navigate(-1);
              }}>
              Quay lại
            </Button>
            <ButtonLoading
              loading={isLoadingUpdateOffice}
              isShowChildWhenLoading
              type='submit'>
              Cập nhật Agent
            </ButtonLoading>
          </div>
          <ModalUpdateOfficeId
            modalInfo={modalInfo}
            isLoadingChangePassword={isLoadingChangeOfficePassword}
            isLoadingUpdateOfficeProviders={isLoadingUpdateOfficeProviders}
            handleUpdateModalInfo={(value) => setModalInfo(value)}
            handleChangePassword={handleChangePassword}
            handleUpdateOfficeProviders={handleUpdateOfficeProviders}
          />
        </div>
      )}
    </Form>
  );
};

export default UpdateOfficeIdCtn;
