import { grpcErrorObservable } from '@core/grpc-client/grpc-observers';
import { IObservableData, LOCAL_STORAGE_KEY } from '@core/utils';
import { useAppLanguage, useToast } from '@sky-booking-config/hooks/internals';
import { useAppDispatch } from '@sky-booking-config/stores';
import {
  fetchUserData,
  logout,
} from '@sky-booking-config/stores/reducers/user';
import { LANGUAGES_CODE } from '@tixlabs/types';
import type * as grpcWeb from 'grpc-web';
import debounce from 'lodash.debounce';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useAuth, useCities, useErrorMessage } from '../../hooks/apps';
import { useAirlines } from '../../hooks/stores';
import { useNavigate } from 'react-router-dom';

type Props = {
  isCheckAuth?: boolean;
};

export function AppLogicProvider({
  children,
  isCheckAuth,
}: PropsWithChildren<Props>) {
  const { changeLanguage } = useAppLanguage();
  const navigate = useNavigate();

  useCities();
  useAirlines();
  useErrorMessage();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  isCheckAuth && useAuth();
  const dispatch = useAppDispatch();
  const { warning } = useToast();

  function handleInvalidToken(message?: string) {
    dispatch(logout())
      .unwrap()
      .then(() => {
        warning(message || 'Phiên làm việc hết hiệu lực');
      });
  }

  const handleGrpcError = useCallback(
    (data: IObservableData<grpcWeb.RpcError>) => {
      if (data.data) {
        const { message } = data.data;

        switch (message) {
          case 'INVALID_TOKEN':
            handleInvalidToken();
            break;
          case 'PERMISSION_DENIED':
            handleInvalidToken('PERMISSION DENIED');
            break;

          default:
            break;
        }
      }
    },
    []
  );

  const handleStorageChange = useCallback(async (e) => {
    if (e.key === LOCAL_STORAGE_KEY.REFRESH_TOKEN) {
      if (e.oldValue !== null) {
        await dispatch(fetchUserData()).unwrap();
        navigate('/dashboard');
      }
    }
  }, []);

  const handleGrpcErrorDebounce = debounce(handleGrpcError, 300);
  useEffect(() => {
    changeLanguage(LANGUAGES_CODE.VIETNAM);
    grpcErrorObservable.subscribe(handleGrpcErrorDebounce);
    window.addEventListener('storage', handleStorageChange);
    return () => {
      grpcErrorObservable.unsubscribe(handleGrpcErrorDebounce);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return children;
}

export default AppLogicProvider;
