import { ConfigServiceClient } from '@api/airplane/web_partner_admin/config_grpc_web_pb';
import {
  GetConfigRes,
  UpdateConfigReq,
} from '@api/airplane/web_partner_admin/config_pb';

import { BasicRes, Empty } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ConfigApi extends BaseApi<ConfigServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ConfigServiceClient(this.url, null, this.optsDev);
  }
  getConfig = (): Promise<GetConfigRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, GetConfigRes, GetConfigRes.AsObject>(
      this.serviceClient.getConfig,
      req,
      {}
    );
  };
  updateConfig = ({ vat }: UpdateConfigReq.AsObject) => {
    const req = new UpdateConfigReq();
    req.setVat(vat);
    return this.grpc<UpdateConfigReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateConfig,
      req,
      {}
    );
  };
}

export const serviceConfigApi = new ConfigApi();
export default serviceConfigApi;
