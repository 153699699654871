import { MenuLineIcon, TopUpIcon } from '@icon';
import { MenuHeader } from '@sky-booking-config/components/Header';
import Logo from '@sky-booking-config/components/Logo';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import {
  FEATURE_ACCESS_BY_ROLE,
  GROUP_FEATURE_BY_ROLE,
  MenuItem,
} from '@sky-booking-config/utils';
import { Button, Layout, Menu } from 'antd';
import { useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ALL_MENU_GROUP_INFO, allMenuItems, getItem } from './MenuItem';
const { Header, Content, Sider } = Layout;

export const DashboardLayout = () => {
  const { userData } = useUserStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const generateGroupMenuSideBar = useMemo(() => {
    if (!userData || userData.rolesList.length === 0) {
      return [];
    }

    const groupMenus: MenuItem[] = [];
    const groupFeatureByRoleUser = GROUP_FEATURE_BY_ROLE[userData.rolesList[0]];

    const featureAccessByRoleUser =
      FEATURE_ACCESS_BY_ROLE[userData.rolesList[0]];

    groupFeatureByRoleUser.forEach((i) => {
      groupMenus.push(
        getItem({
          label: ALL_MENU_GROUP_INFO[i].label,
          key: i,
          icon: ALL_MENU_GROUP_INFO[i].icon,
          children: ALL_MENU_GROUP_INFO[i].listFeature
            .filter((fea) => featureAccessByRoleUser.includes(fea))
            .map((featureAccess) => {
              return allMenuItems[featureAccess];
            }),
        })
      );
    });
    return groupMenus;
  }, [userData?.rolesList]);

  return (
    <Layout
      style={{
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}>
      <Header>
        <div className='flex justify-between h-full items-center text-base'>
          <div className='flex space-x-5 items-center'>
            <div>
              <Logo className='h-[21px]' type='logo-white' />
            </div>

            <Button
              type='text'
              icon={<MenuLineIcon />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                color: 'white',
                fontSize: '16px',
              }}
            />
          </div>
          <MenuHeader name={userData?.contact?.name || userData?.name || ''} />
        </div>
      </Header>
      <Layout>
        <Sider
          breakpoint='lg'
          onBreakpoint={(isBroken) => {
            if (isBroken) {
              setCollapsed(true);
            }
          }}
          collapsed={collapsed}
          style={{ padding: '20px 10px' }}
          collapsedWidth={80}
          width={270}>
          <Menu
            mode='inline'
            // defaultSelectedKeys={['manage-agent-users']}
            // defaultOpenKeys={['portal-agents-manager']}
            items={generateGroupMenuSideBar}
            theme='light'
            selectedKeys={pathname.split('/')}
            onClick={(info) => {
              navigate(info.keyPath[0]);
            }}
          />
        </Sider>

        <Content
          className='p-5 overflow-x-hidden overflow-y-auto'
          rootClassName='bg-[#F0F2F5]'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
