import { IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import { EPartnershipRole } from '../constants';

export const patternValidateUsername = /^[a-zA-Z0-9]+$/;
export const patternValidateNameBooker = /^[a-zA-ZÀ-ỹ\s]+$/;
export const patternValidatePhoneNumber = /^[0-9]+$/;
export const patternValidatePrice = /^(?!.*,$)(?!.*\.$)[0-9,.]+$/;
export const patternValidateAgentCode = /^(OTA\d{4}|[0-9]{4})$/;
export const patternValidateEmail =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const checkRoleUser = (
  role: EPartnershipRole,
  userData: IUserInfo | null
) => {
  if (!userData) return false;
  return role === userData.rolesList[0];
};

export const getLabelRoleUser = (userData: IUserInfo | null) => {
  if (!userData) return false;
  let roleUser = '';
  switch (userData.rolesList[0]) {
    case EPartnershipRole.ACCOUNTANT:
      roleUser = 'Accountant';
      break;
    case EPartnershipRole.APPRAISAL:
      roleUser = 'Appraisal';
      break;
    case EPartnershipRole.APPRAISAL_MANAGER:
      roleUser = 'Appraisal Manager';
      break;
    case EPartnershipRole.SUPER_ADMIN:
      roleUser = 'Super Admin';
      break;

    default:
      break;
  }

  return roleUser;
};
