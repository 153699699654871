import { LayoutErrorBoundary, withSuspense } from '@common-ui';
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthLayout, DashboardLayout, RootLayout } from '../layout';

import {
  AddTopUp,
  AddUser,
  DetailTopUp,
  EditTopUp,
  EditUser,
  ListTopUpCtn,
  ListTransactionCtn,
  ListUserCtn,
} from '@payment-portal/containers';
import { DetailSendNotification } from '@sky-booking-config/containers';
import { ListFlightByCode } from '@sky-booking-config/containers/ListFlightByCode';

const Login = withSuspense(lazy(() => import('../pages/Auth/LoginPage')));

const ConfigWalletAndFeePage = withSuspense(
  lazy(() => import('../pages/ConfigWalletAndFee/ConfigWalletAndFee'))
);

const ConfigHiddenFeePage = withSuspense(
  lazy(() => import('../pages/ConfigHiddenFee/ConfigHiddenFee'))
);

const ManageOfficeIdUserPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeIdUser/ManageOfficeIdUserPage'))
);

const CreateManageOfficeIdUserPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeIdUser/CreateManageOfficeIdUserPage'))
);

const UpdateManageOfficeIdUserPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeIdUser/UpdateManageOfficeIdUserPage'))
);

const ManageOfficeIdPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeId/ManageOfficeIdPage'))
);
const CreateOfficeIdPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeId/CreateOfficeIdPage'))
);
const UpdateOfficeIdPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeId/UpdateOfficeIdPage'))
);
const DetailOfficeIdPage = withSuspense(
  lazy(() => import('../pages/ManageOfficeId/DetailOfficeIdPage'))
);

const ListTransaction = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/TransactionManagement/ListTransaction'
      )
  )
);
const ListOrder = withSuspense(
  lazy(() => import('@sky-booking-config/containers/OrderManagement/ListOrder'))
);
const DetailOrder = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/OrderManagement/DetailOrder/DetailOrder'
      )
  )
);
const PageNotFound = withSuspense(
  lazy(() => import('../pages/NotFound/NotFound'))
);

const ManageIssuerPage = withSuspense(
  lazy(() => import('../pages/ManageIssuer/ManageIssuerPage'))
);

const CreateIssuer = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/IssuerManagerment/CreateAndUpdateIssuer/CreateIssuer/CreateIssuer'
      )
  )
);

const UpdateIssuer = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/IssuerManagerment/CreateAndUpdateIssuer/UpdateIssuer/UpdateIssuer'
      )
  )
);
const ListTransferBookingRequest = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/TransferBookingRequest/ListTransferBookingRequest'
      )
  )
);

const ListTransferBookingFee = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/TransferBookingFee/ListTransferBookingFee'
      )
  )
);

const CreateFee = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/TransferBookingFee/CreateFee/CreateFee'
      )
  )
);

const UpdateFee = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/TransferBookingFee/UpdateFee/UpdateFee'
      )
  )
);

const ExportReportBooking = withSuspense(
  lazy(
    () =>
      import(
        '@sky-booking-config/containers/ReportBookingManagement/ExportReportBooking'
      )
  )
);

export const genRoutStructure = (additionalRouteStructure: any[]) => {
  return [
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          hasErrorBoundary: true,
          ErrorBoundary: LayoutErrorBoundary,
          path: 'auth',
          element: <AuthLayout />,
          children: [
            {
              index: true,
              element: <Navigate to='/auth/login' replace />,
            },
            {
              path: 'login',
              element: <Login />,
            },
          ],
        },
        ...additionalRouteStructure,
      ],
    },
    { path: '*', element: <PageNotFound /> },
  ];
};

export const fullRouteStructureDashBoard = {
  element: <DashboardLayout />,
  path: '/dashboard',
  hasErrorBoundary: true,
  ErrorBoundary: LayoutErrorBoundary,
  children: [
    // Route for "Index" and route "ErrorBoundary(*)" is added in App.tsx
    {
      path: 'manage-agent-users',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ManageOfficeIdUserPage />,
        },
        {
          path: 'create-user',
          element: <CreateManageOfficeIdUserPage />,
        },
        {
          path: 'update-user',
          element: <UpdateManageOfficeIdUserPage />,
        },
      ],
    },
    {
      path: 'providers',
      element: <Outlet />,
    },
    {
      path: 'agents',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ManageOfficeIdPage />,
        },
        {
          path: 'create-agent',
          element: <CreateOfficeIdPage />,
        },
        {
          path: 'update-agent',
          element: <UpdateOfficeIdPage />,
        },
        {
          path: 'detail-agent',
          element: <DetailOfficeIdPage />,
        },
      ],
    },
    {
      path: 'config-wallet-and-fee',
      element: <ConfigWalletAndFeePage />,
    },
    {
      path: 'hidden-fee',
      element: <ConfigHiddenFeePage />,
    },
    {
      path: 'user-management',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListUserCtn />,
        },
        {
          path: 'edit/:userId',
          element: <EditUser />,
        },
      ],
    },
    {
      path: 'add-user',
      element: <AddUser />,
    },
    {
      path: 'top-up-request-management',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListTopUpCtn />,
        },
        {
          path: 'edit/:topUpId',
          element: <EditTopUp />,
        },
        {
          path: 'detail/:topUpId',
          element: <DetailTopUp />,
        },
      ],
    },
    {
      path: 'create-top-up-request',
      element: <AddTopUp />,
    },
    {
      path: 'transaction-management',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListTransactionCtn />,
        },
      ],
    },
    {
      path: 'send-notification',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListFlightByCode />,
        },
      ],
    },
    {
      path: 'send-notification/:bookingCode',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <DetailSendNotification />,
        },
      ],
    },
    {
      path: 'list-booking',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListOrder />,
        },
        {
          path: ':bookingCode',
          element: <DetailOrder />,
        },
      ],
    },
    {
      path: 'list-transaction',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListTransaction />,
        },
      ],
    },
    {
      path: 'issuer-management',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ManageIssuerPage />,
        },
        {
          path: 'create-issuer',
          element: <CreateIssuer />,
        },
        {
          path: 'update-issuer',
          element: <UpdateIssuer />,
        },
      ],
    },
    {
      path: 'list-transfer-booking-request',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListTransferBookingRequest />,
        },
      ],
    },
    {
      path: 'transfer-booking-fee-management',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ListTransferBookingFee />,
        },
        {
          path: 'create',
          element: <CreateFee />,
        },
        {
          path: 'update',
          element: <UpdateFee />,
        },
      ],
    },

    {
      path: 'report-booking-management',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ExportReportBooking />,
        },
      ],
    },
  ],
};
