import { Button, ButtonLoading, Form, FormInput, FormSelect } from '@common-ui';
import { UserStatusListFilterOptions } from '@sky-booking-config/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListAccount from './ListUser';
import useManageUser from './useManageUser';

type Props = {
  //
};

export const ManageOfficeIdUser = (props: Props) => {
  const navigate = useNavigate();

  const {
    userList,
    pagination,
    isLoadingGetUserList,
    manageAccountMethod,
    changePage,
    handleSubmit,
  } = useManageUser();

  useEffect(() => {
    manageAccountMethod.handleSubmit(handleSubmit)();
  }, []);

  return (
    <div className='max-w-[1100px]'>
      <Form
        methods={manageAccountMethod}
        onSubmit={handleSubmit}
        className='w-full flex flex-col gap-y-2.5'>
        <div className='flex justify-between'>
          <div className='flex gap-x-3'>
            <div className='w-40'>
              <FormInput name='name' label='Họ tên' placeholder='Nhập họ tên' />
            </div>
            <div className='w-40'>
              <FormInput
                name='username'
                label='Tên đăng nhập'
                placeholder='Nhập tên đăng nhập'
              />
            </div>
            <div className='w-40'>
              <FormSelect
                name='status'
                label='Trạng thái'
                placeholder='Chọn trạng thái'
                selectOptions={UserStatusListFilterOptions}
                inputProps={{ isOnlyValue: true }}
              />
            </div>
            <div className='flex flex-col justify-end'>
              <ButtonLoading
                type='submit'
                className='!px-10'
                loading={isLoadingGetUserList}>
                Tìm kiếm
              </ButtonLoading>
            </div>
          </div>
          <div className='flex flex-col justify-end'>
            <Button
              theme='secondary'
              onClick={() => {
                navigate('./create-user');
              }}>
              Thêm tài khoản
            </Button>
          </div>
        </div>
        <ListAccount
          isLoading={isLoadingGetUserList}
          data={userList}
          pagination={pagination}
          onChangePage={changePage}
        />
      </Form>
    </div>
  );
};

export default ManageOfficeIdUser;
