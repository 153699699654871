import { LANGUAGES_CODE } from '@tixlabs/types';
import {
  EModalMode,
  ILanguageData,
  IModal,
} from '@payment-portal/types/common';

export const LANGUAGE_SUPPORT: ILanguageData[] = [
  {
    code: LANGUAGES_CODE.VIETNAM,
    text: 'Việt Nam',
  },
  {
    code: LANGUAGES_CODE.ENGLISH,
    text: 'English',
  },
];

export const IP_COUNTRY_KEY = 'cf-ipcountry';
export const IP_COUNTRY_DEFAULT = 'VN';
export const ONE_YEAR_TIMESTAMP = 365 * 24 * 60 * 60 * 1000;
export const ONE_DAY_TIMESTAMP = 24 * 60 * 60 * 1000;

export const SUPPORT_FILES = [
  'image/jpeg',
  'image/png',
  'image/avif',
  'image/tiff',
  'image/heic',
  'image/heif',
  'image/webp',
  'application/pdf',
];

export const SUPPORT_IMAGES_TYPE = ['image/png', 'image/jpg', 'image/jpeg'];

export const DEFAULT_PAGE_LIMIT = 20;

export const DEFAULT_MODAL_CONTENT: IModal = {
  open: false,
  title: '',
  cancelText: 'Hủy',
  okText: 'Ok',
  centered: true,
  mode: EModalMode.CONFIRM,
  width: 'auto',
};
