import {
  Calendar2LineIcon,
  FlightLandingIcon,
  FlightTakeOffIcon,
  PlaneFillIcon,
  PlaneIcon,
  TimeLineIcon,
} from '@icon';

import { ISegment } from '@tixlabs/grpc-client';
import { useMemo } from 'react';
import { AirlineInfo } from './AirlineInfo';
import AirportNameByCode from './AirportNameByCode';
import cn from 'classnames';
import useAirport from '@sky-booking-config/hooks/stores/useAirport';
import { useAirlines } from '@sky-booking-config/hooks/stores';
import { useCities } from '@sky-booking-config/hooks/apps';
import { useTime } from '@sky-booking-config/hooks/internals';
type Props = {
  data: ISegment;
  nextSegment?: ISegment;
  segmentClassName?: string;
};

export function SegmentInfo({ data, nextSegment, segmentClassName }: Props) {
  const { getCityNameByCode } = useCities();
  const { getAirlineNameByCode } = useAirlines();
  const {
    formatDateUTCOnlyTime,
    formatDuration,
    formatDateUTC,
    convertDateToTimeZone,
  } = useTime();
  const { airportsObjectByCode } = useAirport();

  const groupFlightNumber = data.flightNumber.split(',');

  const flightDuration = useMemo(() => {
    try {
      const { arrivalPlace, departPlace, departDate, arrivalDate } = data;
      const departAirportInfo = airportsObjectByCode?.[departPlace];
      const arrivalAirportInfo = airportsObjectByCode?.[arrivalPlace];
      if (arrivalAirportInfo && departAirportInfo) {
        const a = convertDateToTimeZone(departDate, departAirportInfo.timezone);
        const b = convertDateToTimeZone(
          arrivalDate,
          arrivalAirportInfo.timezone
        );

        return (
          Math.abs(
            new Date(b.toDate()).getTime() - new Date(a.toDate()).getTime()
          ) /
          1000 /
          60
        );
      }
      return 0;
    } catch (error) {
      return 0;
    }
  }, [data, airportsObjectByCode]);

  return (
    <div>
      <div className={cn('bg-neutral-1 px-5 py-2.5 mb-2.5', segmentClassName)}>
        <div className='flex gap-x-10 mb-2.5'>
          <div className='basis-[160px] flex flex-col items-start'>
            <AirlineInfo className='ml-1' code={data.carrierMarketing} />
            <div className='flex items-center gap-x-[5px] text-neutral-10 flex-nowrap'>
              <PlaneIcon className='w-5 h-5 text-neutral-7' />
              <span className='text-xs'>Số hiệu:</span>
              <span
                title={data.flightNumber}
                className='font-bold text-[15px] leading-6 line-clamp-1'>
                {data.carrierMarketing}
                {groupFlightNumber.length > 1
                  ? `${groupFlightNumber[0]}`
                  : groupFlightNumber[0]}
              </span>
            </div>
          </div>
          <div className='text-neutral-10 text-[13px] basis-[160px]'>
            <div className='flex gap-[5px] flex-nowrap items-center'>
              <FlightTakeOffIcon className='text-neutral-7 w-5 h-5' />
              <span className='text-xl font-bold '>
                {formatDateUTCOnlyTime(data.departDate)}
              </span>
            </div>
            <div className='divide-x flex '>
              <div className='pr-2 text-center whitespace-nowrap'>
                {data.departPlace}
              </div>
              <div className='pl-2 text-center whitespace-nowrap'>
                {getCityNameByCode(data.departPlace)}
              </div>
            </div>
            <AirportNameByCode
              code={data.departPlace}
              className='leading-normal'
            />
          </div>
          <div className='text-neutral-10 text-[13px] basis-[160px]'>
            <div className='flex gap-[5px] flex-nowrap items-center'>
              <FlightLandingIcon className='text-neutral-7 w-5 h-5' />
              <span className='text-xl font-bold '>
                {formatDateUTCOnlyTime(data.arrivalDate)}
              </span>
            </div>
            <div className='divide-x flex '>
              <div className='pr-2 text-center whitespace-nowrap'>
                {data.arrivalPlace}
              </div>
              <div className='pl-2 text-center whitespace-nowrap'>
                {getCityNameByCode(data.arrivalPlace)}
              </div>
            </div>

            <AirportNameByCode
              code={data.arrivalPlace}
              className='leading-normal'
            />
          </div>
          <div className='basis-[160px] space-y-1'>
            {[
              {
                label: 'Thời gian bay',
                Icon: TimeLineIcon,
                value: formatDuration(flightDuration),
              },
              {
                label: 'Máy bay',
                Icon: PlaneFillIcon,
                value: data.aircraft || '-',
              },
              {
                label: 'Ngày khởi hành',
                Icon: Calendar2LineIcon,
                value: formatDateUTC(data.departDate),
              },
              {
                label: 'Ngày hạ cánh',
                Icon: Calendar2LineIcon,
                value: formatDateUTC(data.arrivalDate),
              },
            ].map(({ Icon, label, value }, index) => (
              <div
                key={index}
                className='flex flex-nowrap gap-x-[5px] items-center text-[13px] leading-4'>
                <Icon className='w-4 h-4 text-neutral-7 shrink-0' />
                <span className='whitespace-nowrap'>{label}: </span>
                <span className='whitespace-nowrap font-bold'>{value}</span>
              </div>
            ))}
          </div>
        </div>
        <div className='bg-[#FFEFEF] text-xs text-center py-1.5 rounded-[3px]'>
          <span className='text-red-6 mr-[5px]'>Hãng vận chuyển: </span>
          <span className='font-semibold'>{data.carrierOperator}</span> -{' '}
          {getAirlineNameByCode(data.carrierOperator)}
        </div>
      </div>
      {nextSegment && (
        <div className='bg-[#FFFBDB] text-xs text-center py-1.5 rounded-[3px] text-red-6 '>
          <span className=' mr-[5px]'>Đổi nhà ga tại: </span>
          <AirportNameByCode
            code={data.arrivalPlace}
            className='font-semibold inline text-red-6'
          />

          <span>
            {'. '}
            Thời gian dừng:{' '}
            <span className='font-semibold'>
              {formatDuration(
                (nextSegment.departDate - data.arrivalDate) / 1000 / 60
              )}
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

export default SegmentInfo;
