import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  FormMultipleSelectSearch,
  FormSelect,
  LabelInput,
  LoadingRing,
} from '@common-ui';
import useCreateAndUpdateUser from '../useCreateAndUpdateUser';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  UserStatusListCreateOptions,
  patternValidateEmail,
  patternValidatePhoneNumber,
} from '@sky-booking-config/utils';
import ToggleProvider from '../ToggleProvider';
import { SelectOptionItem } from '@tixlabs/types';
import ModalChangePassword, {
  ESwitchModal,
  TModalInfo,
} from './ModalChangePassword';

type Props = {
  //
};

export const UpdateUserManageOfficeId = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const [modalInfo, setModalInfo] = useState<TModalInfo>({
    open: false,
    state: ESwitchModal.NONE,
    userId: '',
  });

  const {
    userInfo,
    isLoadingGetUserDetailV2,
    isLoadingUpdateUserV2,
    isLoadingChangePassword,
    updateUserMethod,
    officeIdListOption,
    domainListOption,
    providerListOption,

    handleChangePassword,
    getUserInfoById,
    getDomainListOption,
    getOfficeIdListOption,
    getProviderListOption,
    handleSubmitUpdateUser,
  } = useCreateAndUpdateUser();

  const officeIdListOptionMapped: SelectOptionItem[] = useMemo(() => {
    // Append officeId list of user into unassignList
    if (!userInfo?.assignedOfficesList) {
      return officeIdListOption;
    }

    const userOfficeIdsList: SelectOptionItem[] =
      userInfo.assignedOfficesList.map((item) => ({
        label: item.name,
        value: item.value,
      }));

    return [...officeIdListOption, ...userOfficeIdsList];
  }, [userInfo?.assignedOfficesList, officeIdListOption]);

  useEffect(() => {
    if (userId) {
      getUserInfoById(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userInfo !== undefined && userInfo !== null) {
      const {
        username,
        status,
        assignedOfficesList,
        domainId,
        providerIdsList,
        id: userId,
        contact,
      } = userInfo;

      updateUserMethod.setValue('username', username);
      updateUserMethod.setValue('status', status);

      contact?.name &&
        updateUserMethod.setValue('contactInfo.name', contact.name);

      contact?.phoneNumber &&
        updateUserMethod.setValue(
          'contactInfo.phoneNumber',
          contact.phoneNumber
        );

      contact?.email &&
        updateUserMethod.setValue('contactInfo.email', contact.email);

      updateUserMethod.setValue(
        'officeIdsList',
        assignedOfficesList.map((item) => item.value)
      );
      updateUserMethod.setValue('domainId', domainId);
      updateUserMethod.setValue('providerIdsList', providerIdsList);
      updateUserMethod.setValue('userId', userId);
    }
  }, [userInfo]);

  useEffect(() => {
    getDomainListOption();
    getOfficeIdListOption();
    getProviderListOption();
  }, []);

  return (
    <Form
      methods={updateUserMethod}
      onSubmit={handleSubmitUpdateUser}
      className='h-full'>
      {isLoadingGetUserDetailV2 || userInfo === null ? (
        <div className='w-full h-full flex justify-center items-center gap-y-5'>
          <LoadingRing />
        </div>
      ) : userInfo === undefined ? (
        <div className='w-full h-full flex justify-center items-center gap-y-5'>
          <span>Not found user</span>
        </div>
      ) : (
        <div className='max-w-[1100px] flex flex-col gap-y-5'>
          <div className='flex gap-x-5 p-4 border rounded'>
            <div className='w-52'>
              <FormInput
                name='username'
                label='Tên tài khoản'
                placeholder='Nhập tên tài khoản'
                inputProps={{
                  disabled: true,
                }}
              />
            </div>
            <div className='w-52 flex flex-col justify-end'>
              <Button
                onClick={() =>
                  setModalInfo({
                    ...modalInfo,
                    open: true,
                    state: ESwitchModal.CHANGE_PASSWORD,
                  })
                }>
                Đổi mật khẩu
              </Button>
            </div>
            <div className='w-52'>
              <FormGroupRadio
                name='status'
                label='Trạng thái'
                className='flex'
                radioOptions={UserStatusListCreateOptions}
              />
            </div>
          </div>

          <div className='flex flex-col gap-y-2 p-4 border rounded'>
            <span className='text-lg font-semibold'>Thông tin liên hệ</span>
            <div className='flex gap-x-5'>
              <div className='w-52'>
                <FormInput
                  name='contactInfo.name'
                  label='Họ tên'
                  placeholder='Nhập họ tên'
                  rules={{ required: 'Nhập họ tên' }}
                />
              </div>
              <div className='w-52'>
                <FormInput
                  name='contactInfo.phoneNumber'
                  label='Điện thoại'
                  placeholder='Nhập điện thoại'
                  rules={{
                    // required: 'Nhập diện thoại',
                    // validate: {
                    //   phonePattern: (value: string) => {
                    //     if (value.length > 0) {
                    //       return (
                    //         value.match(patternValidatePhoneNumber) ||
                    //         'Số điện thoại không hợp lệ'
                    //       );
                    //     } else {
                    //       return true;
                    //     }
                    //   },
                    // },
                    pattern: {
                      value: patternValidatePhoneNumber,
                      message: 'Số điện thoại không hợp lệ',
                    },
                    onChange(event) {
                      updateUserMethod.clearErrors('contactInfo.phoneNumber');
                    },
                  }}
                />
              </div>
              <div className='w-52'>
                <FormInput
                  name='contactInfo.email'
                  label='Email'
                  placeholder='Nhập email'
                  rules={{
                    // required: 'Nhập email',
                    // validate: {
                    //   emailPattern: (value: string) => {
                    //     if (value.length > 0) {
                    //       return (
                    //         value.match(patternValidateEmail) ||
                    //         'Email không hợp lệ'
                    //       );
                    //     } else {
                    //       return true;
                    //     }
                    //   },
                    // },
                    pattern: {
                      value: patternValidateEmail,
                      message: 'Email không hợp lệ',
                    },
                    onChange(event) {
                      updateUserMethod.clearErrors('contactInfo.email');
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-y-2 p-4 border rounded'>
            <span className='text-lg font-semibold'>Chỉ định</span>
            <div className='w-full'>
              <FormMultipleSelectSearch
                name='officeIdsList'
                label='Agent'
                placeholder='Chọn Agent'
                selectOptions={officeIdListOptionMapped}
                inputProps={{
                  itemSelectedClassName: '!max-w-full',
                }}
                // rules={{
                //   validate: (value: string[]) =>
                //     value.length >= 1 || 'Chọn Office ID',
                // }}
              />
            </div>
            <div className='w-full'>
              <FormSelect
                name='domainId'
                label='Domain'
                placeholder='Chọn domain'
                selectOptions={domainListOption}
                inputProps={{ isOnlyValue: true, isAllowUncheck: true }}
                rules={{ required: 'Chọn domain' }}
              />
            </div>
            <div className='w-full flex flex-col gap-y-3'>
              <LabelInput label='Chọn provider' />
              {providerListOption.map((item, index) => {
                return (
                  <ToggleProvider
                    key={index}
                    label={item.label}
                    value={item.value}
                  />
                );
              })}
            </div>
          </div>

          <div className='flex justify-end gap-x-5'>
            <Button
              variant='outline'
              onClick={() => {
                // Go back router
                navigate(-1);
              }}>
              Quay lại
            </Button>
            <ButtonLoading
              loading={isLoadingUpdateUserV2}
              isShowChildWhenLoading
              type='submit'>
              Cập nhật
            </ButtonLoading>
          </div>
          <ModalChangePassword
            modalInfo={modalInfo}
            isLoadingChangePassword={isLoadingChangePassword}
            handleUpdateModalInfo={(value) => setModalInfo(value)}
            handleChangePassword={handleChangePassword}
          />
        </div>
      )}
    </Form>
  );
};

export default UpdateUserManageOfficeId;
