import {
  Button,
  ButtonLoading,
  CloseIcon,
  Form,
  FormInput,
  FormSelect,
  LoadingRing,
} from '@common-ui';
import ModalContent from '@sky-booking-config/components/Modal/ModalContent';
import useConfigWalletAndFee from '@sky-booking-config/hooks/apps/useConfigWalletAndFee';
import { useCurrency } from '@sky-booking-config/hooks/internals';
import { EModalMode, IModal } from '@sky-booking-config/types';
import { DEFAULT_MODAL_CONTENT } from '@sky-booking-config/utils';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';

export const ConfigWalletAndFeeCtn = () => {
  const { formatCurrency } = useCurrency();

  const [modalInfo, setModalInfo] = useState<IModal>(DEFAULT_MODAL_CONTENT);

  const {
    methods,
    handleSubmit,
    getOfficeIdList,
    isLoadingUpdate,
    isLoadingGetConfig,
    officeIdOptionList,
    handleChangeOfficeId,
  } = useConfigWalletAndFee();

  const officeIdValue = methods.watch('officeId');

  const openModal = async () => {
    const isValid = await methods.trigger();
    isValid && setModalInfo((pre) => ({ ...pre, open: true }));
  };

  const closeModal = () => {
    setModalInfo((pre) => ({ ...pre, open: false }));
  };

  const onOk = async () => {
    await methods.handleSubmit(handleSubmit)();
    closeModal();
  };

  useEffect(() => {
    if (officeIdValue) {
      handleChangeOfficeId(officeIdValue);
    }
  }, [officeIdValue]);

  useEffect(() => {
    if (officeIdOptionList.length > 0) {
      methods.setValue('officeId', officeIdOptionList[0].value);
    }
  }, [officeIdOptionList]);

  useEffect(() => {
    getOfficeIdList();
  }, []);

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <div className='flex flex-col gap-5 max-w-[1100px]'>
        <FormSelect
          label={<span className='font-semibold'>Agent</span>}
          name='officeId'
          selectOptions={officeIdOptionList}
          placeholder='Sky Booking'
          inputProps={{ isOnlyValue: true }}
        />

        <div className='flex flex-col gap-5 relative'>
          {isLoadingGetConfig && (
            <div className='w-full h-full absolute top-0 right-0 z-10 flex justify-center items-center bg-neutral-6 opacity-30'>
              <LoadingRing />
            </div>
          )}
          <div className='flex justify-between p-3 gap-x-2 border bg-white rounded'>
            <FormInput
              name='officeBalance'
              label='Số dư tài khoản'
              inputProps={{ disabled: true }}
              placeholder='90.000.000'
            />
            <FormInput
              name='walletConfig.depositBalance'
              label='Số tiền ký quỹ'
              placeholder='90.000.000'
              inputProps={{ type: 'number' }}
              rules={{ required: 'Nhập số tiền ký quỹ' }}
            />
            <FormInput
              label='Số tiền tạm ứng'
              name='walletConfig.advanceBalance'
              placeholder='90.000.000'
              inputProps={{ type: 'number' }}
              rules={{ required: 'Nhập số tiền tạm ứng' }}
            />
            <FormInput
              label='Số tiền bảo lãnh'
              name='walletConfig.guaranteeAmount'
              placeholder='90.000.000'
              inputProps={{ type: 'number' }}
              rules={{ required: 'Nhập số  tiền bảo lãnh' }}
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <span className='font-semibold'>Hoa hồng dịch vụ (%)</span>
            <div className='flex justify-between p-3 gap-x-2 border bg-white rounded'>
              <FormInput
                name='commission.airTicket'
                label='Vé máy bay'
                placeholder='90.000.000'
                inputProps={{ type: 'number' }}
                rules={{ required: 'Nhập số hoa hồng vé máy bay' }}
              />
              <FormInput
                name='commission.sim'
                label='SIM'
                placeholder='90.000.000'
                inputProps={{ type: 'number' }}
                rules={{ required: 'Nhập số hoa hồng SIM' }}
              />
              <FormInput
                name='commission.visa'
                label='Dịch vụ Visa'
                placeholder='90.000.000'
                inputProps={{ type: 'number' }}
                rules={{ required: 'Nhập số hoa hồng dịch vụ Visa' }}
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <span className='font-semibold'>Phí dịch vụ bán hàng (%)</span>
            <div className='flex justify-between p-3 gap-x-2 border bg-white rounded'>
              <FormInput
                name='saleServiceFee.supportRate'
                label='Hỗ trợ không tính phí'
                placeholder='5'
                inputProps={{ type: 'number' }}
                rules={{ required: 'Nhập dịch vụ không tính phí' }}
              />
              <FormInput
                name='saleServiceFee.rate'
                label='Tỷ lệ tính phí'
                placeholder='5'
                inputProps={{ type: 'number' }}
                rules={{ required: 'Nhập dịch vụ tính phí' }}
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <span className='font-semibold'>Các phí khác</span>
            <div className='flex justify-between p-3 gap-x-2 border bg-white rounded'>
              <FormInput
                name='platformFee'
                label='Phí thuê nền tảng'
                placeholder='90.000.000'
                inputProps={{ type: 'number' }}
                rules={{ required: 'Nhập phí thuê nền tảng' }}
              />
            </div>
          </div>
        </div>
        <div className='flex justify-end'>
          <Button
            className='px-8'
            onClick={openModal}
            disabled={methods.formState.errors.root?.type === 'disabled'}>
            Cập nhật
          </Button>
        </div>
      </div>
      <Modal
        {...modalInfo}
        closeIcon={
          modalInfo.closeIcon ? (
            <CloseIcon
              className='w-5 h-5 text-[#999999] '
              onClick={closeModal}
            />
          ) : null
        }
        footer={
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button
              theme='neutral'
              className='flex-1'
              onClick={closeModal}
              disabled={isLoadingUpdate}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              loading={isLoadingUpdate}
              className='flex-1'
              onClick={onOk}>
              Cập nhật
            </ButtonLoading>
          </div>
        }>
        <ModalContent
          mode={EModalMode.CONFIRM}
          title='Xác nhận cập nhật'
          className='gap-y-3'
          content={
            <div className='flex flex-col gap-y-2'>
              <span className='font-medium'>
                Vui lòng xác nhận lại thông tin cập nhật:
              </span>
              <div className='flex justify-between gap-x-5'>
                <div className='flex flex-col gap-y-1'>
                  <span className='whitespace-nowrap'>
                    Agent ID:{' '}
                    <span className='font-semibold'>
                      {methods.getValues('officeId')}
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Phí thuê nền tảng:{' '}
                    <span className='font-semibold'>
                      {formatCurrency(methods.getValues('platformFee'))}
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Số tiền ký quỹ:{' '}
                    <span className='font-semibold'>
                      {formatCurrency(
                        methods.getValues('walletConfig.depositBalance')
                      )}
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Số tiền tạm ứng:{' '}
                    <span className='font-semibold'>
                      {formatCurrency(
                        methods.getValues('walletConfig.advanceBalance')
                      )}
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Số tiền bảo lãnh:{' '}
                    <span className='font-semibold'>
                      {formatCurrency(
                        methods.getValues('walletConfig.guaranteeAmount')
                      )}
                    </span>
                  </span>
                </div>
                <div className='flex flex-col gap-y-1'>
                  <span className='whitespace-nowrap'>
                    Hoa hồng vé máy bay:{' '}
                    <span className='font-semibold'>
                      {methods.getValues('commission.airTicket')} %
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Hoa hồng SIM:{' '}
                    <span className='font-semibold'>
                      {methods.getValues('commission.sim')} %
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Hoa hồng Visa:{' '}
                    <span className='font-semibold'>
                      {methods.getValues('commission.visa')} %
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Tỷ lệ không tính phí:{' '}
                    <span className='font-semibold'>
                      {methods.getValues('saleServiceFee.supportRate')} %
                    </span>
                  </span>
                  <span className='whitespace-nowrap'>
                    Tỷ lệ tính phí:{' '}
                    <span className='font-semibold'>
                      {methods.getValues('saleServiceFee.rate')} %
                    </span>
                  </span>
                </div>
              </div>
            </div>
          }
        />
      </Modal>
    </Form>
  );
};

export default ConfigWalletAndFeeCtn;
