import { Toggle } from '@common-ui';
import { SelectOptionItem } from '@tixlabs/types';
import { useMemo } from 'react';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';
import { TCreateOfficeIdForm } from './useCreateAndUpdateOfficeId';

type Props = {
  label: string;
  value: SelectOptionItem['value'];
  nameField: FieldPath<TCreateOfficeIdForm>;
  disabled?: boolean;
};

//! Should enhance to be a Form component in Common UI
const ToggleProvider = ({
  label,
  value,
  nameField,
  disabled = false,
}: Props) => {
  const methods = useFormContext<TCreateOfficeIdForm>();
  const providerListValue = useWatch({ name: nameField });

  const toggleValue = useMemo(() => {
    return providerListValue?.some((formValue) => formValue === value);
  }, [providerListValue, value]);

  function onChangeToggle(event: boolean) {
    if (event) {
      const updateProviderList = [...providerListValue, value];
      methods.setValue(nameField, updateProviderList);
    } else {
      const updateProviderList = providerListValue.filter(
        (formValue) => formValue !== value
      );
      methods.setValue(nameField, updateProviderList);
    }
  }

  return (
    <div className='flex gap-x-5 items-center'>
      <Toggle
        disabled={disabled}
        value={toggleValue}
        onChange={onChangeToggle}
      />
      <span>{label}</span>
    </div>
  );
};

export default ToggleProvider;
