// components/custom-editor.js
'use client'; // only in App Router

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  DecoupledEditor,
  AccessibilityHelp,
  Alignment,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  CodeBlock,
  Essentials,
  Heading,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  Paragraph,
  SelectAll,
  SpecialCharacters,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TodoList,
  Underline,
  Undo,
  EventInfo,
  ClassicEditor,
  Strikethrough,
  Code,
  Subscript,
  Superscript,
  Font,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Base64UploadAdapter,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

interface PropsEditor {
  data?: string | null | undefined;
  onChange?: ((event: EventInfo, editor: ClassicEditor) => void) | undefined;
}

function CustomEditor({ data, onChange }: PropsEditor) {
  return (
    <CKEditor
      data={data}
      onChange={onChange}
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'code',
            'subscript',
            'superscript',
            '|',
            'specialCharacters',
            'horizontalLine',
            'link',
            'insertTable',
            'blockQuote',
            'codeBlock',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'outdent',
            'indent',
            'insertImage',
          ],
          shouldNotGroupWhenFull: false,
        },
        plugins: [
          Base64UploadAdapter,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsertViaUrl,
          // ImageUpload,
          // ImageInsert,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          Font,
          AccessibilityHelp,
          Alignment,
          AutoLink,
          Autosave,
          BlockQuote,
          Bold,
          CodeBlock,
          Essentials,
          Heading,
          HorizontalLine,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          Paragraph,
          SelectAll,
          SpecialCharacters,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TodoList,
          Underline,
          Undo,
          Strikethrough,
          Code,
          Subscript,
          Superscript,
        ],
      }}
    />
  );
}

export default CustomEditor;
