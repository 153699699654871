import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  FormInputPassword,
  FormMultipleSelectSearch,
  FormSelect,
  LabelInput,
} from '@common-ui';
import {
  UserStatusListCreateOptions,
  patternValidateEmail,
  patternValidatePhoneNumber,
  patternValidateUsername,
} from '@sky-booking-config/utils';
import { useEffect } from 'react';
import useCreateAndUpdateUser from '../useCreateAndUpdateUser';
import ToggleProvider from '../ToggleProvider';
import { useNavigate } from 'react-router-dom';

type Props = {
  //
};

export const CreateUserManageOfficeId = (props: Props) => {
  const navigate = useNavigate();

  const {
    createUserMethod,
    domainListOption,
    officeIdListOption,
    providerListOption,
    isLoadingCreateUserV2,
    handleSubmitCreateUser,
    getDomainListOption,
    getOfficeIdListOption,
    getProviderListOption,
  } = useCreateAndUpdateUser();

  useEffect(() => {
    getDomainListOption();
    getOfficeIdListOption();
    getProviderListOption();
  }, []);
  return (
    <Form
      autoComplete='off'
      methods={createUserMethod}
      onSubmit={handleSubmitCreateUser}
      className='max-w-[1100px] flex flex-col gap-y-5'>
      <div className='flex gap-x-5 p-4 border rounded'>
        <div className='w-52'>
          <FormInput
            name='username'
            label='Tên tài khoản'
            placeholder='Nhập tên tài khoản'
            rules={{
              required: 'Nhập tên tài khoản',
              validate: {
                maxLength: (value: string) =>
                  value.length <= 20 || 'Tối đa 20 kí tự',
                minLength: (value: string) =>
                  value.length >= 8 || 'Tối thiểu 8 kí tự',
              },
              pattern: {
                value: patternValidateUsername,
                message: 'Tên đăng nhập không hợp lệ',
              },
              onChange(event) {
                createUserMethod.clearErrors('username');
              },
            }}
            inputProps={{
              autoComplete: 'new-username',
            }}
          />
        </div>
        <div className='w-52'>
          <FormInputPassword
            name='password'
            label='Mật khẩu'
            placeholder='Nhập mật khẩu'
            rules={{
              required: 'Nhập mật khẩu',
              validate: {
                minLength: (value: string) =>
                  value.length >= 8 || 'Tối thiểu 8 kí tự',
              },
              onChange(event) {
                createUserMethod.clearErrors('password');
              },
            }}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
        </div>
        <div className='w-52'>
          <FormGroupRadio
            name='status'
            label='Trạng thái'
            className='flex'
            radioOptions={UserStatusListCreateOptions}
          />
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Thông tin liên hệ</span>
        <div className='flex gap-x-5'>
          <div className='w-52'>
            <FormInput
              name='contactInfo.name'
              label='Họ tên'
              placeholder='Nhập họ tên'
              rules={{
                required: 'Nhập họ tên',
                onChange(event) {
                  createUserMethod.clearErrors('contactInfo.name');
                },
              }}
            />
          </div>
          <div className='w-52'>
            <FormInput
              name='contactInfo.phoneNumber'
              label='Điện thoại'
              placeholder='Nhập điện thoại'
              rules={{
                // required: 'Nhập diện thoại',
                validate: {
                  phonePattern: (value: string) => {
                    if (value.length > 0) {
                      return (
                        value.match(patternValidatePhoneNumber) ||
                        'Số điện thoại không hợp lệ'
                      );
                    } else {
                      return true;
                    }
                  },
                },
                onChange(event) {
                  createUserMethod.clearErrors('contactInfo.phoneNumber');
                },
              }}
            />
          </div>
          <div className='w-52'>
            <FormInput
              name='contactInfo.email'
              label='Email'
              placeholder='Nhập email'
              rules={{
                // required: 'Nhập email',
                validate: {
                  emailPattern: (value: string) => {
                    if (value.length > 0) {
                      return (
                        value.match(patternValidateEmail) ||
                        'Email không hợp lệ'
                      );
                    } else {
                      return true;
                    }
                  },
                },
                onChange(event) {
                  createUserMethod.clearErrors('contactInfo.email');
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Chỉ định</span>
        <div className='w-full'>
          <FormMultipleSelectSearch
            name='officeIdsList'
            label='Agent'
            placeholder='Chọn Agent'
            selectOptions={officeIdListOption}
            inputProps={{
              itemSelectedClassName: '!max-w-full',
            }}
            // rules={{
            //   validate: (value: string[]) =>
            //     value.length >= 1 || 'Chọn Office ID',
            // }}
          />
        </div>
        <div className='w-full'>
          <FormSelect
            name='domainId'
            label='Domain'
            placeholder='Chọn domain'
            selectOptions={domainListOption}
            inputProps={{ isOnlyValue: true }}
            rules={{ required: 'Chọn domain' }}
          />
        </div>
        <div className='w-full flex flex-col gap-y-3'>
          <LabelInput label='Chọn provider' />
          {providerListOption.map((item, index) => {
            return (
              <ToggleProvider
                key={index}
                label={item.label}
                value={item.value}
              />
            );
          })}
        </div>
      </div>

      <div className='flex justify-end gap-x-5'>
        <Button
          variant='outline'
          onClick={() => {
            // Go back router
            navigate(-1);
          }}>
          Quay lại
        </Button>
        <ButtonLoading
          loading={isLoadingCreateUserV2}
          isShowChildWhenLoading
          type='submit'>
          Thêm tài khoản
        </ButtonLoading>
      </div>
    </Form>
  );
};

export default CreateUserManageOfficeId;
