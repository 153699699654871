import {
  Button,
  ButtonLoading,
  Edit2FillIcon,
  Form,
  FormInput,
  FormSelect,
  User3FillIcon,
} from '@common-ui';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeeTableContent } from './FeeTableContent';
import useManageOfficeId from './useManageOfficeId';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import { ROLE_USER, checkRoleUser } from '@sky-booking-config/utils';
import { SELECT_TRANSFER_BOOKING_CONFIG_STATUS_OPTIONS } from '@web-admin/utils';

type Props = {
  //
};

export const ListTransferBookingFeeCtn = (props: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserStore();

  const {
    officeIdList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    changePage,
    handleSubmit,
  } = useManageOfficeId();

  useEffect(() => {
    manageOfficeIdMethod.handleSubmit(handleSubmit)();
  }, []);

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <User3FillIcon className='w-5 h-5 shrink-0' />
        <div className='font-semibold text-lg'>Quản lý phí xuất hộ</div>
        <Button
          theme='green'
          onClick={() => {
            navigate('./create');
          }}>
          Thêm phí
        </Button>
      </div>
      <div className='max-w-[1100px]'>
        <Form
          methods={manageOfficeIdMethod}
          onSubmit={handleSubmit}
          className='w-full flex flex-col gap-y-2.5'>
          <div className='flex justify-between'>
            <div className='flex gap-x-3'>
              <div className='w-40'>
                <FormInput
                  name='agentCodesList'
                  label='Đại lý'
                  placeholder='Tìm theo đại lý'
                  // rules={{
                  //   onChange(event) {
                  //     if (String(event.target.value) !== "") {
                  //       manageOfficeIdMethod.setValue('agentCodesList',[event.target.value]);
                  //     }
                  //   },
                  // }}
                />
              </div>
              <div className='w-40'>
                <FormInput
                  name='fee'
                  label='Giá trị phí'
                  placeholder='Giá trị phí'
                />
              </div>
              <div className='w-40'>
                <FormSelect
                  name='status'
                  selectOptions={SELECT_TRANSFER_BOOKING_CONFIG_STATUS_OPTIONS}
                  label={'Trạng thái'}
                  inputProps={{
                    isOnlyValue: true,
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col justify-end'>
              {checkRoleUser(ROLE_USER.OFFICE_MANAGER, userData) ? (
                <div className='flex flex-col justify-end'>
                  <ButtonLoading
                    type='submit'
                    theme='secondary'
                    className='!px-10'
                    prefixIcon={<Edit2FillIcon />}
                    loading={isLoadingGetUserList}>
                    Tìm kiếm
                  </ButtonLoading>
                </div>
              ) : null}
            </div>
          </div>
          <FeeTableContent
            isLoading={isLoadingGetUserList}
            data={officeIdList}
            pagination={pagination}
            onChangePage={changePage}
          />
        </Form>
      </div>
    </div>
  );
};

export default ListTransferBookingFeeCtn;
