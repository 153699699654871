/* eslint-disable no-case-declarations */
import { EPaxType, IBookingItinerary, IHubBaggage } from '@tixlabs/grpc-client';

export const validateName = (value: string) => {
  if (value.length > 999) {
    return 'Tối đa 999 kí tự';
  }
  // eslint-disable-next-line no-control-regex
  if (value.match(/[^\x00-\x7F]/)) {
    return 'Nhập chuỗi không chứa dấu';
  }
  if (value.match(/[^a-zA-Z0-9 ]/)) {
    return 'Nhập chuỗi không chứa ký tự đặc biệt';
  }
};

export const validatePhoneNumber = (value: string) => {
  const patternNumberString = /^\d+$/;
  return !!value.match(patternNumberString) || 'Số điện thoại không hợp lệ';
};

export const validateEmail = (value: string) => {
  const patternEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !!value.match(patternEmail) || 'Email không hợp lệ';
};

export const mapReservationCode = (itinerariesList: IBookingItinerary[]) => {
  return itinerariesList
    .reduce((reservationCodes: string[], itinerary) => {
      return itinerary.reservationCode
        ? [...reservationCodes, itinerary.reservationCode]
        : reservationCodes;
    }, [])
    .join(', ');
};

export const getBaggageForPaxByItinerary = (
  itinerary: IBookingItinerary,
  paxType: EPaxType
) => {
  const handBaggage: IHubBaggage[] = [];
  const checkedBaggage: IHubBaggage[] = [];

  itinerary.freeBaggageList.forEach((baggage) => {
    if (baggage.paxType === paxType) {
      if (baggage.isHandBaggage) {
        handBaggage.push(baggage);
      } else {
        checkedBaggage.push(baggage);
      }
    }
  });
  return {
    handBaggage,
    checkedBaggage,
  };
};
