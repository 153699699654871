import { Button, Edit2FillIcon, LockPasswordFillIcon } from '@common-ui';
import { IPaginationRes } from '@tixlabs/grpc-client';
import { IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import ModalContent from '@payment-portal/components/Modal/ModalContent';
import { EModalMode, EStepChangePassword } from '@payment-portal/types';
import { EUserStatus } from '@tixlabs/grpc-client';

import { USER_PARTNERSHIP_STATUS_LABEL } from '@payment-portal/utils';
import { Modal, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalChangePassword } from './ModalChangePassword';

type Props = {
  //
  pagination: IPaginationRes;
  listPartnerUser: IUserInfo[];
  isLoading: boolean;
  onPageChange: (page: number) => void;
};

export const TableContent = ({
  pagination,
  listPartnerUser,
  isLoading,
  onPageChange,
}: Props) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userInfoSelected, setUserInfoSelected] = useState<IUserInfo>();
  const [stepChangePassword, setStepChangePassword] =
    useState<EStepChangePassword>(EStepChangePassword.CHANGE_PASSWORD);

  const columns: ColumnProps<IUserInfo>[] = [
    {
      title: () => {
        return <span className='text-neutral-7'>Tên đăng nhập</span>;
      },
      dataIndex: 'username',
      key: 'username',
      // className: 'w-[242px]',
    },
    {
      title: () => {
        return <span className='text-neutral-7'>Tên thành viên</span>;
      },
      dataIndex: 'contact',
      key: 'contactName',
      // className: 'w-[453px]',
      render: (_, userInfo) => {
        return <span>{userInfo.contact?.name}</span>;
      },
    },
    {
      title: () => {
        return <span className='text-neutral-7'>Số điện thoại</span>;
      },
      dataIndex: 'contact',
      key: 'contactPhone',
      // className: 'w-[363px]',
      render: (_, userInfo) => {
        return <span>{userInfo.contact?.phoneNumber}</span>;
      },
    },
    {
      title: () => {
        return <span className='text-neutral-7'>Vai trò</span>;
      },
      dataIndex: 'rolesList',
      key: 'rolesList',
      // className: 'w-[363px]',
      render: (rolesList) => {
        return <span>{rolesList.map((role) => role).join(', ')}</span>;
      },
    },
    {
      title: () => {
        return <span className='text-neutral-7'>Trạng thái</span>;
      },
      dataIndex: 'status',
      key: 'status',
      // className: 'w-[239px]',
      render: (value: EUserStatus) => {
        return value === EUserStatus.ACTIVE ? (
          <span className='text-green-6'>
            {USER_PARTNERSHIP_STATUS_LABEL[value]}
          </span>
        ) : (
          <span className='text-neutral-7'>
            {USER_PARTNERSHIP_STATUS_LABEL[value]}
          </span>
        );
      },
    },
    {
      title: () => {
        return <span className='text-neutral-7'>Thao tác</span>;
      },
      dataIndex: 'id',
      key: 'action',
      render: (id, userInfo) => {
        return (
          <div className='flex gap-2.5 justify-end'>
            <Button
              className='space-x-[5px]'
              prefixIcon={<LockPasswordFillIcon className='' />}
              variant='ghost'
              theme='secondary'
              onClick={() => {
                setIsOpenModal(true);
                setStepChangePassword(EStepChangePassword.CHANGE_PASSWORD);
                setUserInfoSelected(userInfo);
              }}>
              Đổi mật khẩu
            </Button>
            <Button
              variant='ghost'
              theme='primary'
              className='space-x-[5px]'
              prefixIcon={<Edit2FillIcon />}
              onClick={() => {
                navigate(`/dashboard/user-management/edit/${id}`);
              }}>
              Sửa thông tin
            </Button>
          </div>
        );
      },
      className: 'flex justify-end',
    },
  ];

  const genarateModalFooter = (state: EStepChangePassword) => {
    const content = {
      [EStepChangePassword.SUCCESS]: (
        <div className='flex flex-col'>
          <Button
            onClick={() => {
              setIsOpenModal(false);
            }}
            className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
      [EStepChangePassword.CHANGE_PASSWORD]: null,
    };
    return content[state];
  };

  const genarateModalContent = (state: EStepChangePassword) => {
    const content = {
      [EStepChangePassword.CHANGE_PASSWORD]: (
        <ModalChangePassword
          userInfo={userInfoSelected}
          onCloseModal={() => {
            setIsOpenModal(false);
          }}
          onChangePasswordSuccess={() => {
            setStepChangePassword(EStepChangePassword.SUCCESS);
          }}
        />
      ),
      [EStepChangePassword.SUCCESS]: (
        <ModalContent
          mode={EModalMode.SUCCESS}
          className='w-full'
          title='Đổi mật khẩu thành công'
        />
      ),
    };
    return content[state];
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        closeIcon={false}
        footer={genarateModalFooter(stepChangePassword)}
        centered={true}>
        {genarateModalContent(stepChangePassword)}
      </Modal>
      <Table
        dataSource={listPartnerUser}
        columns={columns}
        size='small'
        pagination={{
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onPageChange,
        }}
        loading={isLoading}
      />
    </>
  );
};

export default TableContent;
