import { ETopUpRequestStatus } from '@tixlabs/grpc-client/';
import { SelectOptionItem } from '@tixlabs/types';

export const topUpRequestStatusLabel = {
  [ETopUpRequestStatus.TOPUPREQUESTINVALID]: 'Tất cả',
  [ETopUpRequestStatus.WAITINGAPPROVAL]: 'Chờ duyệt',
  [ETopUpRequestStatus.PROCESSING]: 'Đang xử lý',
  [ETopUpRequestStatus.APPROVED]: 'Đã duyệt',
  [ETopUpRequestStatus.COMPLETED]: 'Hoàn thành',
  [ETopUpRequestStatus.REJECTED]: 'Đã từ chối',
  [ETopUpRequestStatus.FAILED]: 'Thất bại',
};

export const SELECT_TOPUP_REQUEST_STATUS_OPTIONS: SelectOptionItem[] = [
  {
    value: ETopUpRequestStatus.TOPUPREQUESTINVALID,
    label: topUpRequestStatusLabel[ETopUpRequestStatus.TOPUPREQUESTINVALID],
  },
  {
    value: ETopUpRequestStatus.WAITINGAPPROVAL,
    label: topUpRequestStatusLabel[ETopUpRequestStatus.WAITINGAPPROVAL],
  },
  {
    value: ETopUpRequestStatus.PROCESSING,
    label: topUpRequestStatusLabel[ETopUpRequestStatus.PROCESSING],
  },
  {
    value: ETopUpRequestStatus.COMPLETED,
    label: topUpRequestStatusLabel[ETopUpRequestStatus.COMPLETED],
  },
  {
    value: ETopUpRequestStatus.REJECTED,
    label: topUpRequestStatusLabel[ETopUpRequestStatus.REJECTED],
  },
  {
    value: ETopUpRequestStatus.FAILED,
    label: topUpRequestStatusLabel[ETopUpRequestStatus.FAILED],
  },
];

export const SELECT_TOPUP_REQUEST_STATUS_OPTIONS_FOR_APPRAISAL_MANAGER: SelectOptionItem[] =
  [
    {
      value: ETopUpRequestStatus.TOPUPREQUESTINVALID,
      label: topUpRequestStatusLabel[ETopUpRequestStatus.TOPUPREQUESTINVALID],
    },

    {
      value: ETopUpRequestStatus.PROCESSING,
      label: topUpRequestStatusLabel[ETopUpRequestStatus.PROCESSING],
    },
    {
      value: ETopUpRequestStatus.COMPLETED,
      label: topUpRequestStatusLabel[ETopUpRequestStatus.COMPLETED],
    },
    {
      value: ETopUpRequestStatus.FAILED,
      label: topUpRequestStatusLabel[ETopUpRequestStatus.FAILED],
    },
  ];
