import { Card } from '@common-ui';
import { InformationFillIcon } from '@icon';
import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';
import { useTime } from '@web-admin/hooks/internals';

type Props = {
  //
  data: IRetrieveBookingFlightData;
};

export const BookingInformation = ({ data }: Props) => {
  const { formatDateTime } = useTime();
  return (
    <Card
      title='Thông tin đặt chỗ'
      icon={<InformationFillIcon className='w-4 h-4 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='bg-neutral-4 p-3 flex mt-1.5 rounded'>
      <div className='gap-y-2.5 flex-1'>
        <div className='w-full uppercase text-common-error font-bold'>
          Giữ chỗ sẽ bị hủy vào {formatDateTime(data.expiredAt)}, vui lòng xuất
          vé hoặc hủy booking trước thời hạn
        </div>
        <div className='flex'>
          {/* <div className='flex-1'>Thời hạn giữ giá</div>
          <div className='flex-1 font-semibold'>11:34, ngày 18/09/2023</div> */}
        </div>
      </div>
      {/* <div className='space-y-2.5 flex-1'>
        <div className='flex'>
          <div className='flex-1'>CA code</div>
          <div className='flex-1 font-semibold'>VJ - Vietjet Air</div>
        </div>
        <div className='flex'>
          <div className='flex-1'>Tourcode</div>
          <div className='flex-1 font-semibold'>IBE</div>
        </div>
      </div> */}
    </Card>
  );
};

export default BookingInformation;
