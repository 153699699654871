import { Button, ButtonLoading, Form, FormInput } from '@common-ui';
import { validatePasswordByCase } from '@core/utils';
import { CloseIcon } from '@icon';
import { useAppMutation } from '@payment-portal/hooks/internals';
import { useErrorMessageData } from '@payment-portal/hooks/stores';
import {
  IAdminChangeUserPasswordReq,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type Props = {
  //
  userInfo?: IUserInfo;
  onCloseModal: () => void;
  onChangePasswordSuccess: () => void;
};

export const ModalChangePassword = ({
  userInfo,
  onCloseModal,
  onChangePasswordSuccess,
}: Props) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { getErrorMessage } = useErrorMessageData();
  const methods = useForm<IAdminChangeUserPasswordReq>({
    defaultValues: {
      userId: userInfo?.id,
      newPassword: '',
    },
  });

  const {
    mutateAsync: adminChangeUserPassword,
    isLoading: isAdminChangeUserPassword,
  } = useAppMutation({
    mutationKey: ['partnerUserServiceClient', 'adminChangeUserPassword'],
    mutationFn: partnerUserServiceClient.adminChangeUserPassword,
    onSuccess: ({ isSuccess, errorCode }) => {
      //
      if (isSuccess) {
        onChangePasswordSuccess();
      } else {
        // handle show message error by errorCode
        setErrorMsg(getErrorMessage(errorCode));
      }
    },
    onError: (error) => {
      // handle case network/system error
    },
  });

  const handleSubmit = async (data: IAdminChangeUserPasswordReq) => {
    await adminChangeUserPassword(data);
  };
  return (
    <div className='flex flex-col space-y-6'>
      <div className='flex'>
        <h3 className='text-lg font-semibold text-neutral-11 flex-1'>
          Đổi mật khẩu
        </h3>
        <CloseIcon
          className='w-6 h-6 p-0.5 cursor-pointer text-neutral-7 hover:text-neutral-8'
          onClick={() => {
            methods.reset();
            methods.clearErrors();
            setErrorMsg(null);
            onCloseModal();
          }}
        />
      </div>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className='flex flex-col space-y-6'>
        <FormInput
          name='username'
          label={'Tên đăng nhập'}
          labelProps={{
            className: 'text-neutral-7 font-semibold',
          }}
          inputProps={{
            disabled: true,
          }}
          value={userInfo?.username}
        />
        <FormInput
          name='newPassword'
          label={'Mật khẩu'}
          labelProps={{
            className: 'text-neutral-7 font-semibold',
          }}
          inputProps={{
            type: 'password',
          }}
          rules={{
            required: 'Nhập mật khẩu mới',
            minLength: {
              value: 8,
              message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
            },
            validate: {
              checkValid: (v) => {
                const { hasNumeric, hasCharacter } = validatePasswordByCase(v);
                return (
                  (hasNumeric && hasCharacter) ||
                  'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
                );
              },
            },
            onChange(event) {
              methods.clearErrors('newPassword');
            },
          }}
          placeholder='Nhập mật khẩu mới'
        />
        {errorMsg && <span className='text-red-5'>{errorMsg}</span>}
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={() => {
              setErrorMsg(null);
              methods.reset();
              methods.clearErrors();
              onCloseModal();
            }}>
            Huỷ bỏ
          </Button>
          <ButtonLoading
            loading={isAdminChangeUserPassword}
            isShowChildWhenLoading
            className='flex-1'
            type='submit'>
            Đổi mật khẩu
          </ButtonLoading>
        </div>
      </Form>
    </div>
  );
};
