// source: airplane/web_partner_admin/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_flight_pb = require('../../airplane/flight_pb.js');
goog.object.extend(proto, airplane_flight_pb);
var airplane_hub_pb = require('../../airplane/hub_pb.js');
goog.object.extend(proto, airplane_hub_pb);
var airplane_seat_pb = require('../../airplane/seat_pb.js');
goog.object.extend(proto, airplane_seat_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partner_admin.BaggageCharge', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.BaggageInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.BaggageOption', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.BaggageSelectionInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.BookingContactInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.BookingPassenger', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ExportBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ExportBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ListBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ListBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ListBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.RetrieveBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.RetrieveBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.SearchAirlinesReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.SearchAirlinesReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.SearchAirlinesRes', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.SearchAirlinesRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.SortItem', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.SortItemType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.SearchAirlinesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.SearchAirlinesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.SearchAirlinesReq.displayName = 'proto.airplane.web_partner_admin.SearchAirlinesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.SearchAirlinesReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.displayName = 'proto.airplane.web_partner_admin.SearchAirlinesReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.SearchAirlinesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.SearchAirlinesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.SearchAirlinesRes.displayName = 'proto.airplane.web_partner_admin.SearchAirlinesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.SearchAirlinesRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.SearchAirlinesRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.SearchAirlinesRes.Data.displayName = 'proto.airplane.web_partner_admin.SearchAirlinesRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.BookingContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.BookingContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.BookingContactInfo.displayName = 'proto.airplane.web_partner_admin.BookingContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.BaggageSelectionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.BaggageSelectionInfo.displayName = 'proto.airplane.web_partner_admin.BaggageSelectionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.BaggageOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.BaggageOption.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.BaggageOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.BaggageOption.displayName = 'proto.airplane.web_partner_admin.BaggageOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.BaggageCharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.BaggageCharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.BaggageCharge.displayName = 'proto.airplane.web_partner_admin.BaggageCharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.BaggageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.BaggageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.BaggageInfo.displayName = 'proto.airplane.web_partner_admin.BaggageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.BookingPassenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.BookingPassenger.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.BookingPassenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.BookingPassenger.displayName = 'proto.airplane.web_partner_admin.BookingPassenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.RetrieveBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.RetrieveBookingFlightReq.displayName = 'proto.airplane.web_partner_admin.RetrieveBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.RetrieveBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.displayName = 'proto.airplane.web_partner_admin.RetrieveBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.displayName = 'proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.displayName = 'proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.displayName = 'proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ListBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.ListBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ListBookingFlightReq.displayName = 'proto.airplane.web_partner_admin.ListBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ListBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.displayName = 'proto.airplane.web_partner_admin.ListBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ListBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ListBookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ListBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ListBookingFlightRes.displayName = 'proto.airplane.web_partner_admin.ListBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.SortItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.SortItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.SortItem.displayName = 'proto.airplane.web_partner_admin.SortItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ExportBookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ExportBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ExportBookingFlightReq.displayName = 'proto.airplane.web_partner_admin.ExportBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.displayName = 'proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ExportBookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ExportBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ExportBookingFlightRes.displayName = 'proto.airplane.web_partner_admin.ExportBookingFlightRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.SearchAirlinesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.SearchAirlinesReq;
  return proto.airplane.web_partner_admin.SearchAirlinesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner_admin.SearchAirlinesReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.SearchAirlinesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.SearchAirlinesReq.Filter;
  return proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq.Filter} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.Filter.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner_admin.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner_admin.SearchAirlinesReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.SearchAirlinesReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner_admin.SearchAirlinesReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq} returns this
*/
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq} returns this
*/
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesReq} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.SearchAirlinesReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.SearchAirlinesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner_admin.SearchAirlinesRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.SearchAirlinesRes;
  return proto.airplane.web_partner_admin.SearchAirlinesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner_admin.SearchAirlinesRes.Data;
      reader.readMessage(value,proto.airplane.web_partner_admin.SearchAirlinesRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.SearchAirlinesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner_admin.SearchAirlinesRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.SearchAirlinesRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_hub_pb.Airline.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.SearchAirlinesRes.Data;
  return proto.airplane.web_partner_admin.SearchAirlinesRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new airplane_hub_pb.Airline;
      reader.readMessage(value,airplane_hub_pb.Airline.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.SearchAirlinesRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      airplane_hub_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated airplane.Airline items = 1;
 * @return {!Array<!proto.airplane.Airline>}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.Airline>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.Airline, 1));
};


/**
 * @param {!Array<!proto.airplane.Airline>} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} returns this
*/
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.Airline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.Airline}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.Airline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} returns this
*/
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes.Data} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner_admin.SearchAirlinesRes.Data}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner_admin.SearchAirlinesRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.SearchAirlinesRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner_admin.SearchAirlinesRes.Data|undefined} value
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes} returns this
*/
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.SearchAirlinesRes} returns this
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.SearchAirlinesRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.BookingContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.BookingContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BookingContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo}
 */
proto.airplane.web_partner_admin.BookingContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.BookingContactInfo;
  return proto.airplane.web_partner_admin.BookingContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.BookingContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo}
 */
proto.airplane.web_partner_admin.BookingContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.BookingContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.BookingContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BookingContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingContactInfo} returns this
 */
proto.airplane.web_partner_admin.BookingContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.BaggageSelectionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.BaggageSelectionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baggageOption: (f = msg.getBaggageOption()) && proto.airplane.web_partner_admin.BaggageOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.BaggageSelectionInfo}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.BaggageSelectionInfo;
  return proto.airplane.web_partner_admin.BaggageSelectionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.BaggageSelectionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.BaggageSelectionInfo}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner_admin.BaggageOption;
      reader.readMessage(value,proto.airplane.web_partner_admin.BaggageOption.deserializeBinaryFromReader);
      msg.setBaggageOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.BaggageSelectionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.BaggageSelectionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaggageOption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web_partner_admin.BaggageOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageSelectionInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BaggageOption baggage_option = 2;
 * @return {?proto.airplane.web_partner_admin.BaggageOption}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.getBaggageOption = function() {
  return /** @type{?proto.airplane.web_partner_admin.BaggageOption} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.BaggageOption, 2));
};


/**
 * @param {?proto.airplane.web_partner_admin.BaggageOption|undefined} value
 * @return {!proto.airplane.web_partner_admin.BaggageSelectionInfo} returns this
*/
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.setBaggageOption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.BaggageSelectionInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.clearBaggageOption = function() {
  return this.setBaggageOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.BaggageSelectionInfo.prototype.hasBaggageOption = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.BaggageOption.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.BaggageOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.BaggageOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    segmentIndexList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baggageInfoList: jspb.Message.toObjectList(msg.getBaggageInfoList(),
    proto.airplane.web_partner_admin.BaggageInfo.toObject, includeInstance),
    totalWeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalBaggageCharge: (f = msg.getTotalBaggageCharge()) && proto.airplane.web_partner_admin.BaggageCharge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.BaggageOption}
 */
proto.airplane.web_partner_admin.BaggageOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.BaggageOption;
  return proto.airplane.web_partner_admin.BaggageOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.BaggageOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.BaggageOption}
 */
proto.airplane.web_partner_admin.BaggageOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItineraryIndex(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSegmentIndex(values[i]);
      }
      break;
    case 4:
      var value = new proto.airplane.web_partner_admin.BaggageInfo;
      reader.readMessage(value,proto.airplane.web_partner_admin.BaggageInfo.deserializeBinaryFromReader);
      msg.addBaggageInfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalWeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 7:
      var value = new proto.airplane.web_partner_admin.BaggageCharge;
      reader.readMessage(value,proto.airplane.web_partner_admin.BaggageCharge.deserializeBinaryFromReader);
      msg.setTotalBaggageCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.BaggageOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.BaggageOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSegmentIndexList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getBaggageInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.web_partner_admin.BaggageInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalWeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalBaggageCharge();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web_partner_admin.BaggageCharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string option_id = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 itinerary_index = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 segment_index = 3;
 * @return {!Array<number>}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getSegmentIndexList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.setSegmentIndexList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.addSegmentIndex = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.clearSegmentIndexList = function() {
  return this.setSegmentIndexList([]);
};


/**
 * repeated BaggageInfo baggage_info = 4;
 * @return {!Array<!proto.airplane.web_partner_admin.BaggageInfo>}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getBaggageInfoList = function() {
  return /** @type{!Array<!proto.airplane.web_partner_admin.BaggageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner_admin.BaggageInfo, 4));
};


/**
 * @param {!Array<!proto.airplane.web_partner_admin.BaggageInfo>} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
*/
proto.airplane.web_partner_admin.BaggageOption.prototype.setBaggageInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.web_partner_admin.BaggageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.BaggageInfo}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.addBaggageInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.web_partner_admin.BaggageInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.clearBaggageInfoList = function() {
  return this.setBaggageInfoList([]);
};


/**
 * optional int64 total_weight = 5;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getTotalWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.setTotalWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string unit = 6;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional BaggageCharge total_baggage_charge = 7;
 * @return {?proto.airplane.web_partner_admin.BaggageCharge}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.getTotalBaggageCharge = function() {
  return /** @type{?proto.airplane.web_partner_admin.BaggageCharge} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.BaggageCharge, 7));
};


/**
 * @param {?proto.airplane.web_partner_admin.BaggageCharge|undefined} value
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
*/
proto.airplane.web_partner_admin.BaggageOption.prototype.setTotalBaggageCharge = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.BaggageOption} returns this
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.clearTotalBaggageCharge = function() {
  return this.setTotalBaggageCharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.BaggageOption.prototype.hasTotalBaggageCharge = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.BaggageCharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.BaggageCharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageCharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.BaggageCharge}
 */
proto.airplane.web_partner_admin.BaggageCharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.BaggageCharge;
  return proto.airplane.web_partner_admin.BaggageCharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.BaggageCharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.BaggageCharge}
 */
proto.airplane.web_partner_admin.BaggageCharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.BaggageCharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.BaggageCharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageCharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double base_amount = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageCharge} returns this
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tax_amount = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageCharge} returns this
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_amount = 3;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageCharge} returns this
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageCharge} returns this
 */
proto.airplane.web_partner_admin.BaggageCharge.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.BaggageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.BaggageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxWeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dimension: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subCode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.BaggageInfo}
 */
proto.airplane.web_partner_admin.BaggageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.BaggageInfo;
  return proto.airplane.web_partner_admin.BaggageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.BaggageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.BaggageInfo}
 */
proto.airplane.web_partner_admin.BaggageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxWeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDimension(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.BaggageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.BaggageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BaggageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaxWeight();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDimension();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 weight = 3;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max_weight = 4;
 * @return {number}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getMaxWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setMaxWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string unit = 5;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dimension = 6;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getDimension = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setDimension = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sub_code = 8;
 * @return {string}
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.getSubCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BaggageInfo} returns this
 */
proto.airplane.web_partner_admin.BaggageInfo.prototype.setSubCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.BookingPassenger.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.BookingPassenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.BookingPassenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BookingPassenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 7, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    airplane_seat_pb.SeatSelectionInfo.toObject, includeInstance),
    baggagesList: jspb.Message.toObjectList(msg.getBaggagesList(),
    proto.airplane.web_partner_admin.BaggageSelectionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger}
 */
proto.airplane.web_partner_admin.BookingPassenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.BookingPassenger;
  return proto.airplane.web_partner_admin.BookingPassenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.BookingPassenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger}
 */
proto.airplane.web_partner_admin.BookingPassenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    case 11:
      var value = new airplane_seat_pb.SeatSelectionInfo;
      reader.readMessage(value,airplane_seat_pb.SeatSelectionInfo.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 12:
      var value = new proto.airplane.web_partner_admin.BaggageSelectionInfo;
      reader.readMessage(value,proto.airplane.web_partner_admin.BaggageSelectionInfo.deserializeBinaryFromReader);
      msg.addBaggages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.BookingPassenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.BookingPassenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.BookingPassenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDob();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      airplane_seat_pb.SeatSelectionInfo.serializeBinaryToWriter
    );
  }
  f = message.getBaggagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.airplane.web_partner_admin.BaggageSelectionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional airplane.PaxType type = 4;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional base.GENDER gender = 5;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 dob = 6;
 * @return {number}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setDob = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string nationality = 7;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string passport_number = 8;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string id_issue_country = 10;
 * @return {string}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated airplane.SeatSelectionInfo seats = 11;
 * @return {!Array<!proto.airplane.SeatSelectionInfo>}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.airplane.SeatSelectionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_seat_pb.SeatSelectionInfo, 11));
};


/**
 * @param {!Array<!proto.airplane.SeatSelectionInfo>} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
*/
proto.airplane.web_partner_admin.BookingPassenger.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.SeatSelectionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatSelectionInfo}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.SeatSelectionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * repeated BaggageSelectionInfo baggages = 12;
 * @return {!Array<!proto.airplane.web_partner_admin.BaggageSelectionInfo>}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.getBaggagesList = function() {
  return /** @type{!Array<!proto.airplane.web_partner_admin.BaggageSelectionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner_admin.BaggageSelectionInfo, 12));
};


/**
 * @param {!Array<!proto.airplane.web_partner_admin.BaggageSelectionInfo>} value
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
*/
proto.airplane.web_partner_admin.BookingPassenger.prototype.setBaggagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.airplane.web_partner_admin.BaggageSelectionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.BaggageSelectionInfo}
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.addBaggages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.airplane.web_partner_admin.BaggageSelectionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.BookingPassenger} returns this
 */
proto.airplane.web_partner_admin.BookingPassenger.prototype.clearBaggagesList = function() {
  return this.setBaggagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.RetrieveBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.RetrieveBookingFlightReq;
  return proto.airplane.web_partner_admin.RetrieveBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.RetrieveBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes;
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    originPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee;
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOriginPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double origin_price = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.prototype.getOriginPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.prototype.setOriginPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double makeup_price = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    tourcode: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    refundAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult;
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTourcode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRefundAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTourcode();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRefundAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double totalPrice = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tourcode = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.getTourcode = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.setTourcode = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double refund_amount = 3;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web_partner_admin.BookingPassenger.toObject, includeInstance),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_flight_pb.BookingItinerary.toObject, includeInstance),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web_partner_admin.BookingContactInfo.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 12, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    issuedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    id: jspb.Message.getFieldWithDefault(msg, 16, ""),
    makeupFee: (f = msg.getMakeupFee()) && proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.toObject(includeInstance, f),
    agencyCommissionFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    isTransferred: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    transferable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    transferBookingResult: (f = msg.getTransferBookingResult()) && proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.toObject(includeInstance, f),
    rebookExpiredAt: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data;
  return proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.airplane.web_partner_admin.BookingPassenger;
      reader.readMessage(value,proto.airplane.web_partner_admin.BookingPassenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 5:
      var value = new airplane_flight_pb.BookingItinerary;
      reader.readMessage(value,airplane_flight_pb.BookingItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = new proto.airplane.web_partner_admin.BookingContactInfo;
      reader.readMessage(value,proto.airplane.web_partner_admin.BookingContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 17:
      var value = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee;
      reader.readMessage(value,proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader);
      msg.setMakeupFee(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAgencyCommissionFee(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTransferred(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransferable(value);
      break;
    case 21:
      var value = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult;
      reader.readMessage(value,proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinaryFromReader);
      msg.setTransferBookingResult(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRebookExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.web_partner_admin.BookingPassenger.serializeBinaryToWriter
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      airplane_flight_pb.BookingItinerary.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web_partner_admin.BookingContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getIssuedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMakeupFee();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter
    );
  }
  f = message.getAgencyCommissionFee();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getIsTransferred();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getTransferable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getTransferBookingResult();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult.serializeBinaryToWriter
    );
  }
  f = message.getRebookExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated BookingPassenger passengers = 4;
 * @return {!Array<!proto.airplane.web_partner_admin.BookingPassenger>}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web_partner_admin.BookingPassenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner_admin.BookingPassenger, 4));
};


/**
 * @param {!Array<!proto.airplane.web_partner_admin.BookingPassenger>} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.web_partner_admin.BookingPassenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.BookingPassenger}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.web_partner_admin.BookingPassenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * repeated airplane.BookingItinerary itineraries = 5;
 * @return {!Array<!proto.airplane.BookingItinerary>}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.BookingItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BookingItinerary, 5));
};


/**
 * @param {!Array<!proto.airplane.BookingItinerary>} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.BookingItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.BookingItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BookingContactInfo contact_info = 7;
 * @return {?proto.airplane.web_partner_admin.BookingContactInfo}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web_partner_admin.BookingContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.BookingContactInfo, 7));
};


/**
 * @param {?proto.airplane.web_partner_admin.BookingContactInfo|undefined} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 11;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 11));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string currency = 12;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string order_id = 13;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 expired_at = 14;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 issued_at = 15;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getIssuedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setIssuedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string id = 16;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional MakeupFee makeup_fee = 17;
 * @return {?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getMakeupFee = function() {
  return /** @type{?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee, 17));
};


/**
 * @param {?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.MakeupFee|undefined} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setMakeupFee = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearMakeupFee = function() {
  return this.setMakeupFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.hasMakeupFee = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional double agency_commission_fee = 18;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getAgencyCommissionFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setAgencyCommissionFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional bool is_transferred = 19;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getIsTransferred = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setIsTransferred = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool transferable = 20;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getTransferable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setTransferable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional TransferBookingResult transfer_booking_result = 21;
 * @return {?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getTransferBookingResult = function() {
  return /** @type{?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult, 21));
};


/**
 * @param {?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.TransferBookingResult|undefined} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setTransferBookingResult = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.clearTransferBookingResult = function() {
  return this.setTransferBookingResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.hasTransferBookingResult = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional int64 rebook_expired_at = 22;
 * @return {number}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.getRebookExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.prototype.setRebookExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} returns this
*/
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.RetrieveBookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ListBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ListBookingFlightReq;
  return proto.airplane.web_partner_admin.ListBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner_admin.ListBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ListBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    partnershopId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ListBookingFlightReq.Filter;
  return proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershopId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookerId(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.clearBookingCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.hasBookingCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.clearFromDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.clearToDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string partnershop_id = 4;
 * @return {string}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.getPartnershopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.setPartnershopId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.clearPartnershopId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.hasPartnershopId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string booker_id = 5;
 * @return {string}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.getBookerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.setBookerId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.clearBookerId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.hasBookerId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated airplane.BookingStatus statuses = 6;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner_admin.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner_admin.ListBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.ListBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner_admin.ListBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq} returns this
*/
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq} returns this
*/
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightReq} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ListBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ListBookingFlightRes;
  return proto.airplane.web_partner_admin.ListBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ListBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RetrieveBookingFlightRes.Data items = 3;
 * @return {!Array<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes} returns this
*/
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes} returns this
*/
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ListBookingFlightRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.SortItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.SortItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.SortItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SortItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.SortItem}
 */
proto.airplane.web_partner_admin.SortItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.SortItem;
  return proto.airplane.web_partner_admin.SortItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.SortItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.SortItem}
 */
proto.airplane.web_partner_admin.SortItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.web_partner_admin.SortItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.SortItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.SortItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.SortItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.SortItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool desc = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.SortItem.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.SortItem} returns this
 */
proto.airplane.web_partner_admin.SortItem.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SortItemType type = 2;
 * @return {!proto.airplane.web_partner_admin.SortItemType}
 */
proto.airplane.web_partner_admin.SortItem.prototype.getType = function() {
  return /** @type {!proto.airplane.web_partner_admin.SortItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.web_partner_admin.SortItemType} value
 * @return {!proto.airplane.web_partner_admin.SortItem} returns this
 */
proto.airplane.web_partner_admin.SortItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ExportBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partner_admin.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ExportBookingFlightReq;
  return proto.airplane.web_partner_admin.ExportBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner_admin.SortItem;
      reader.readMessage(value,proto.airplane.web_partner_admin.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ExportBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner_admin.SortItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bookerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter;
  return proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookerId(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBookerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string booker_id = 4;
 * @return {string}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.getBookerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.setBookerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated airplane.BookingStatus statuses = 5;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner_admin.ExportBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq} returns this
*/
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partner_admin.SortItem>}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner_admin.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner_admin.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner_admin.SortItem>} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq} returns this
*/
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner_admin.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.SortItem}
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner_admin.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightReq} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ExportBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightRes}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ExportBookingFlightRes;
  return proto.airplane.web_partner_admin.ExportBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightRes}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ExportBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RetrieveBookingFlightRes.Data items = 3;
 * @return {!Array<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightRes} returns this
*/
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner_admin.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partner_admin.ExportBookingFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * @enum {number}
 */
proto.airplane.web_partner_admin.SortItemType = {
  SORT_ITEM_TYPE_NONE: 0,
  SORT_ITEM_TYPE_PRICE: 1,
  SORT_ITEM_TYPE_FASTEST: 2
};

goog.object.extend(exports, proto.airplane.web_partner_admin);
