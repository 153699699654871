import { LoadingRing } from '@common-ui';
import 'antd/dist/reset.css';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import { FEATURE_ACCESS_BY_ROLE } from '@sky-booking-config/utils';
import { useMemo } from 'react';
import PageNotFound from './pages/NotFound/NotFound';
import {
  fullRouteStructureDashBoard,
  genRoutStructure,
} from './routers/routes';

export function App() {
  const { userData } = useUserStore();

  const routes = useMemo(() => {
    const roleList = userData?.rolesList;

    if (!roleList || roleList.length === 0) {
      return genRoutStructure([]);
    }

    const filterRouteByRole = fullRouteStructureDashBoard.children.filter(
      (item) => {
        return roleList.some((role) =>
          FEATURE_ACCESS_BY_ROLE[role]?.includes(item.path || '')
        );
      }
    );

    const filteredDashboardRoute = {
      ...fullRouteStructureDashBoard,
      children: [
        {
          index: true,
          element: <Navigate to={`${filterRouteByRole[0].path}`} replace />,
        },
        ...filterRouteByRole,
        { path: '*', element: <PageNotFound /> },
      ],
    };

    return genRoutStructure([filteredDashboardRoute]);
  }, [userData]);

  return (
    <div>
      <RouterProvider
        fallbackElement={
          <div className='flex justify-center items-center h-[100vh] w-full'>
            <LoadingRing />
          </div>
        }
        router={createBrowserRouter(routes)}
      />
    </div>
  );
}

export default App;
