import { Button, Edit2FillIcon } from '@common-ui';
import { IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TPagination } from './useManageUser';
import { LabelUserStatus } from '@sky-booking-config/utils';
import { EUserStatus } from '@tixlabs/grpc-client';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

type Props = {
  data: IUserInfo[];
  isLoading?: boolean;
  pagination: TPagination;
  onChangePage: (pageNumber: number) => Promise<void>;
};

const StatusUser = ({ status }: { status: EUserStatus }) => {
  return (
    <span
      className={cn('font-bold', {
        'text-green-7': status === EUserStatus.ACTIVE,
        'text-orange-500': status === EUserStatus.INACTIVE,
      })}>
      {LabelUserStatus[status]}
    </span>
  );
};

const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const ListUser = ({ data, isLoading, onChangePage, pagination }: Props) => {
  const navigate = useNavigate();
  const columns: ColumnsType<IUserInfo> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: <TitleColumns title='Họ tên' />,
      render: (_, record) => {
        return <span>{record.contact?.name}</span>;
      },
    },
    {
      key: 'username',
      dataIndex: 'username',
      title: <TitleColumns title='Tên đăng nhập' />,
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: <TitleColumns title='Số điện thoại' />,
      render: (_, record) => {
        return <span>{record.contact?.phoneNumber}</span>;
      },
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: <TitleColumns title='Email' />,
      render: (_, record) => {
        return <span>{record.contact?.email}</span>;
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TitleColumns title='Trạng thái' />,
      render: (_, record) => {
        return <StatusUser status={record.status} />;
      },
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: <TitleColumns title='Thao tác' />,
      render: (_, record) => {
        return (
          <div className='flex items-center gap-x-1.5'>
            <Button
              variant='ghost'
              onClick={() => {
                navigate(`./update-user?userId=${record.id}`);
              }}
              prefixIcon={<Edit2FillIcon />}>
              Cập nhật
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      size='small'
      loading={isLoading}
      pagination={
        pagination.totalPage > 1 && {
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onChangePage,
        }
      }
    />
  );
};

export default ListUser;
