import { ReportServiceClient } from '@api/airplane/web_partner_admin/report_grpc_web_pb';
import {
  CurrentBookingStatsReq,
  CurrentBookingStatsRes,
  ReportAirlineBookingReq,
  ReportAirlineBookingRes,
  ReportBookingReq,
  ReportBookingRes,
} from '@api/airplane/web_partner_admin/report_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ReportApi extends BaseApi<ReportServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ReportServiceClient(this.url, null, this.optsDev);
  }

  getReportBooking = ({
    from,
    to,
    userId,
  }: ReportBookingReq.AsObject): Promise<ReportBookingRes.AsObject> => {
    const req = new ReportBookingReq();

    req.setFrom(from);
    req.setTo(to);
    req.setUserId(userId);

    return this.grpc<
      ReportBookingReq,
      ReportBookingRes,
      ReportBookingRes.AsObject
    >(this.serviceClient.reportBooking, req, {});
  };
  getReportAirlineBooking = ({
    from,
    to,
    userId,
  }: ReportAirlineBookingReq.AsObject): Promise<ReportAirlineBookingRes.AsObject> => {
    const req = new ReportAirlineBookingReq();

    req.setFrom(from);
    req.setTo(to);
    req.setUserId(userId);

    return this.grpc<
      ReportAirlineBookingReq,
      ReportAirlineBookingRes,
      ReportAirlineBookingRes.AsObject
    >(this.serviceClient.reportAirlineBooking, req, {});
  };

  currentBookingStats = ({
    userId,
  }: CurrentBookingStatsReq.AsObject): Promise<CurrentBookingStatsRes.AsObject> => {
    const req = new CurrentBookingStatsReq();
    req.setUserId(userId);

    return this.grpc<
      CurrentBookingStatsReq,
      CurrentBookingStatsRes,
      CurrentBookingStatsRes.AsObject
    >(this.serviceClient.currentBookingStats, req, {});
  };
}

export const reportService = new ReportApi();
export default reportService;
