import { useAppMutation } from '@sky-booking-config/hooks/internals';
import { ETransferBookingConfigStatus } from '@tixlabs/grpc-client';
import { IListTransferBookingConfig } from '@tixlabs/grpc-client/web-partnership';
import transferBookingConfigClient from '@tixlabs/grpc-client/web-partnership/transfer-booking-config-service-api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export type TPagination = {
  pageCurrent: number;
  totalPage: number;
  totalRecord: number;
  pageLimit: number;
};

type TListTransferConfigForm = {
  agentCodesList: string;
  fee: number;
  status: ETransferBookingConfigStatus;
};

const useManageOfficeId = () => {
  const manageOfficeIdMethod = useForm<TListTransferConfigForm>({
    defaultValues: {
      agentCodesList: '',
      fee: 0,
      status: ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSNONE,
    },
  });
  const navigate = useNavigate();

  const [officeIdList, setOfficeIdList] = useState<
    IListTransferBookingConfig[]
  >([]);
  const [pagination, setPagination] = useState<TPagination>({
    pageCurrent: 1,
    pageLimit: 10,
    totalPage: 1,
    totalRecord: 1,
  });

  const {
    mutateAsync: listTransferBookingConfig,
    isLoading: isLoadingGetUserList,
  } = useAppMutation({
    mutationKey: ['transferBookingConfigClient', 'listTransferBookingConfig'],
    mutationFn: transferBookingConfigClient.listTransferBookingConfig,
  });

  const handleSubmit = async (formData: TListTransferConfigForm) => {
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listTransferBookingConfig({
        filter: {
          agentCodesList:
            formData.agentCodesList !== '' ? [formData.agentCodesList] : [],
          status: formData.status,
          fee: formData.fee,
        },
        pagination: {
          pageNumber: 1,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setOfficeIdList(itemsList);

      paginationRes && setPagination(paginationRes);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  const changePage = async (pageNumber: number) => {
    const formData = manageOfficeIdMethod.getValues();
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listTransferBookingConfig({
        // filter: {
        //   ...formData,
        // },
        pagination: {
          pageNumber,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setOfficeIdList(itemsList);

      paginationRes && setPagination(paginationRes);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  return {
    officeIdList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    changePage,
    handleSubmit,
  };
};

export default useManageOfficeId;
