import { useAppMutation } from '@sky-booking-config/hooks/internals';
import { ROLE_USER } from '@sky-booking-config/utils';
import { EUserStatus } from '@tixlabs/grpc-client';
import {
  IUserInfo,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export type TPagination = {
  pageCurrent: number;
  totalPage: number;
  totalRecord: number;
  pageLimit: number;
};

type TUserListForm = {
  name: string;
  roleList: string[];
  status: EUserStatus;
  username: string;
};

const useManageAccount = () => {
  const manageAccountMethod = useForm<TUserListForm>({
    defaultValues: {
      name: '',
      username: '',
      roleList: [ROLE_USER.OFFICE_MANAGER],
      status: EUserStatus.USER_STATUS_INVALID,
    },
  });

  const [userList, setUserList] = useState<IUserInfo[]>([]);
  const [pagination, setPagination] = useState<TPagination>({
    pageCurrent: 1,
    pageLimit: 10,
    totalPage: 1,
    totalRecord: 1,
  });

  const { mutateAsync: getUsersV2, isLoading: isLoadingGetUserList } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'getUsersV2'],
      mutationFn: partnerUserServiceClient.getUsersV2,
    });

  const handleSubmit = async (formData: TUserListForm) => {
    try {
      const { isSuccess, errorCode, data } = await getUsersV2({
        ...formData,
        pagination: {
          pageNumber: 1,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      if (!data) {
        throw new Error('Not found');
      }

      setUserList(data.itemsList);

      data?.pagination && setPagination(data.pagination);
    } catch (error) {
      console.log('getUsersV2: ', error);
    }
  };

  const changePage = async (pageNumber: number) => {
    const formData = manageAccountMethod.getValues();
    try {
      const { isSuccess, errorCode, data } = await getUsersV2({
        ...formData,
        pagination: {
          pageNumber,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      if (!data) {
        throw new Error('Not found');
      }

      setUserList(data.itemsList);

      data?.pagination && setPagination(data.pagination);
    } catch (error) {
      console.log('getUsersV2: ', error);
    }
  };

  return {
    userList,
    pagination,
    isLoadingGetUserList,
    manageAccountMethod,
    changePage,
    handleSubmit,
  };
};

export default useManageAccount;
