import { Button, ButtonLoading, Form, FormInput, FormSelect } from '@common-ui';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListOfficeId } from './ListOfficeId';
import useManageOfficeId from './useManageOfficeId';
import { SelectOptionItem } from '@tixlabs/types';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import { ROLE_USER, checkRoleUser } from '@sky-booking-config/utils';

type Props = {
  //
};

export const ManageOfficeIdCtn = (props: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserStore();

  const {
    officeIdList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    allProviders,
    changePage,
    handleSubmit,
    getAllProviderOption,
  } = useManageOfficeId();

  useEffect(() => {
    manageOfficeIdMethod.handleSubmit(handleSubmit)();
    getAllProviderOption();
  }, []);

  const allProviderWithAllOption: SelectOptionItem[] = useMemo(() => {
    const options = checkRoleUser(ROLE_USER.SUPER_ADMIN, userData)
      ? allProviders
      : (allProviders.length > 0 &&
          userData &&
          userData.providerIdsList.length > 0 &&
          (userData.providerIdsList
            .map((providerId) => {
              return allProviders.find(
                (provider) => provider.value === providerId
              );
            })
            .filter((o) => o !== undefined) as SelectOptionItem[])) ||
        [];
    return [{ label: 'Tất cả', value: 0 }, ...options];
  }, [userData, allProviders]);
  // const allProviderWithAllOption: SelectOptionItem[] = useMemo(() => {
  //   return [{ label: 'Tất cả', value: 0 }, ...allProviders];
  // }, [allProviders]);

  return (
    <div className='max-w-[1100px]'>
      <Form
        methods={manageOfficeIdMethod}
        onSubmit={handleSubmit}
        className='w-full flex flex-col gap-y-2.5'>
        <div className='flex justify-between'>
          <div className='flex gap-x-3'>
            <div className='w-40'>
              <FormInput
                name='agentCode'
                label='Agent Code'
                placeholder='Tìm agent Code'
              />
            </div>
            <div className='w-40'>
              <FormSelect
                name='provider'
                selectOptions={allProviderWithAllOption}
                label={'Provider'}
                inputProps={{
                  isOnlyValue: true,
                }}
              />
            </div>
            <div className='flex flex-col justify-end'>
              <ButtonLoading
                type='submit'
                className='!px-10'
                loading={isLoadingGetUserList}>
                Tìm kiếm
              </ButtonLoading>
            </div>
          </div>
          <div className='flex flex-col justify-end'>
            {checkRoleUser(ROLE_USER.OFFICE_MANAGER, userData) ? (
              <Button
                theme='secondary'
                onClick={() => {
                  navigate('./create-agent');
                }}>
                Thêm tài khoản
              </Button>
            ) : null}
          </div>
        </div>
        <ListOfficeId
          isLoading={isLoadingGetUserList}
          data={officeIdList}
          pagination={pagination}
          onChangePage={changePage}
        />
      </Form>
    </div>
  );
};

export default ManageOfficeIdCtn;
