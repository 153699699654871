import { Button, Form, FormInput, FormSelect } from '@common-ui';
import { useForm } from 'react-hook-form';
import TableListFlight from './TableListFlight';
import { useEffect, useState } from 'react';
import { useAppMutation } from '@sky-booking-config/hooks/internals';
import bookingServiceApi from '@tixlabs/grpc-client/web-partnership/booking-service';
import { IBookingInfo, IPaginationRes } from '@tixlabs/grpc-client';
type Props = {
  //
};

type PropsSearchInfo = {
  bookingCode: string;
  status: {
    label: string;
    value: number;
  };
};

export const ListFlightByCode = (props: Props) => {
  const statusFilter = [
    {
      label: 'Tất cả',
      value: 0,
    },
    {
      label: 'Đã gửi',
      value: 1,
    },
    {
      label: 'Chưa gửi',
      value: 2,
    },
  ];

  const methodsBookCode = useForm<PropsSearchInfo>({
    defaultValues: {
      status: statusFilter[0],
    },
  });
  const bookingCode = methodsBookCode.watch('bookingCode');
  const status = methodsBookCode.watch('status');

  const [dataReal, setDataReal] = useState<Array<IBookingInfo>>([]);

  const pageLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { mutateAsync: getUpcomingBookings } = useAppMutation({
    mutationKey: ['bookingServiceApi', 'getUpcomingBookings'],
    mutationFn: bookingServiceApi.getUpcomingBookings,
  });

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: pageLimit,
    totalPage: 1,
    totalRecord: 1,
  });

  const handleFilter = async (data: PropsSearchInfo) => {
    currentPage === 1 ? handleChangePage() : setCurrentPage(1);
  };

  const handleChangePage = async () => {
    const param = {
      filter: {
        text: bookingCode,
        notificationStatus: status?.value,
        maximumDays: 7,
      },
      pagination: {
        pageNumber: currentPage,
        pageLimit: pageLimit,
      },
    };
    await getUpcomingBookings(param)
      .then((res) => {
        res?.pagination && setPaginationRes(res?.pagination);
        setDataReal(res?.itemsList);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    handleChangePage();
  }, [currentPage]);

  return (
    <div className='space-y-5'>
      <div className='w-full bg-white rounded p-5 flex flex-col gap-5'>
        <Form
          className='w-full border-2 border-solid p-4 flex gap-4 items-end'
          onSubmit={handleFilter}
          methods={methodsBookCode}>
          <FormInput
            label='Tìm theo mã booking/PNR'
            className='w-1/4'
            name='bookingCode'
            placeholder='Nhập mã booking/ PNR'
          />

          <FormSelect
            label='Trạng thái'
            className='w-1/4'
            name='status'
            placeholder='Chọn trạng thái'
            selectOptions={statusFilter}
          />
          <Button
            size='sm'
            theme='green'
            type='submit'
            className='!px-5 max-h-10 w-24'>
            <span className='whitespace-nowrap'>Lọc</span>
          </Button>
        </Form>

        <TableListFlight
          paginationRes={paginationRes}
          data={dataReal}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default ListFlightByCode;
