import { ETopUpRequestStatus } from '@tixlabs/grpc-client';
import { SelectOptionItem } from '@tixlabs/types';

export enum EPartnershipRole {
  SUPER_ADMIN = 'super_admin',
  ACCOUNTANT = 'accountant',
  APPRAISAL = 'appraisal',
  APPRAISAL_MANAGER = 'appraisal_manager',
  ALL = 'accountant,appraisal,appraisal_manager',
}

export const FILTER_TOPUP_LABELS = {
  [ETopUpRequestStatus.WAITINGAPPROVAL]: 'Chờ duyệt',
  [ETopUpRequestStatus.PROCESSING]: 'Đang xử lý',
  [ETopUpRequestStatus.APPROVED]: 'Đã duyệt',
  [ETopUpRequestStatus.REJECTED]: 'Đã từ chối',
  [ETopUpRequestStatus.COMPLETED]: 'Hoàn thành',
};

export const FILTER_STATUS_TOPUP_MANAGEMENT_BY_ROLE = {
  [EPartnershipRole.APPRAISAL]: [ETopUpRequestStatus.WAITINGAPPROVAL],
  [EPartnershipRole.APPRAISAL_MANAGER]: [ETopUpRequestStatus.PROCESSING],
  [EPartnershipRole.ACCOUNTANT]: [
    ETopUpRequestStatus.WAITINGAPPROVAL,
    ETopUpRequestStatus.PROCESSING,
    ETopUpRequestStatus.APPROVED,
    ETopUpRequestStatus.REJECTED,
    ETopUpRequestStatus.COMPLETED,
  ],
};

export const getFilterStatusTopUpByRole = (role: EPartnershipRole) => {
  if (role === EPartnershipRole.APPRAISAL) {
    return [ETopUpRequestStatus.WAITINGAPPROVAL];
  } else if (role === EPartnershipRole.APPRAISAL_MANAGER) {
    return [ETopUpRequestStatus.PROCESSING];
  } else if (role === EPartnershipRole.ACCOUNTANT) {
    return [
      ETopUpRequestStatus.WAITINGAPPROVAL,
      ETopUpRequestStatus.PROCESSING,
      ETopUpRequestStatus.APPROVED,
      ETopUpRequestStatus.REJECTED,
      ETopUpRequestStatus.COMPLETED,
    ];
  }
  return [];
};

export const generateTopUpFilterByRole = (
  role: EPartnershipRole
): SelectOptionItem[] => {
  const listOption: SelectOptionItem[] = getFilterStatusTopUpByRole(role).map(
    (filter) => {
      return {
        label: FILTER_TOPUP_LABELS[filter],
        value: filter,
      };
    }
  );
  return listOption;
};
