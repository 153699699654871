// source: airplane/web_partner_admin/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_report_pb = require('../../airplane/report_pb.js');
goog.object.extend(proto, airplane_report_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.AggregateReportBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.CurrentBookingStatsReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.CurrentBookingStatsRes', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ReportAirlineBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ReportAirlineBookingRes', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ReportBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partner_admin.ReportBookingRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ReportBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.ReportBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ReportBookingReq.displayName = 'proto.airplane.web_partner_admin.ReportBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ReportBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ReportBookingRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ReportBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ReportBookingRes.displayName = 'proto.airplane.web_partner_admin.ReportBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.ReportAirlineBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ReportAirlineBookingReq.displayName = 'proto.airplane.web_partner_admin.ReportAirlineBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner_admin.ReportAirlineBookingRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner_admin.ReportAirlineBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.ReportAirlineBookingRes.displayName = 'proto.airplane.web_partner_admin.ReportAirlineBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.AggregateReportBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.AggregateReportBookingReq.displayName = 'proto.airplane.web_partner_admin.AggregateReportBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.displayName = 'proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.CurrentBookingStatsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.CurrentBookingStatsReq.displayName = 'proto.airplane.web_partner_admin.CurrentBookingStatsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.CurrentBookingStatsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.CurrentBookingStatsRes.displayName = 'proto.airplane.web_partner_admin.CurrentBookingStatsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.displayName = 'proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ReportBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ReportBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ReportBookingReq}
 */
proto.airplane.web_partner_admin.ReportBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ReportBookingReq;
  return proto.airplane.web_partner_admin.ReportBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ReportBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ReportBookingReq}
 */
proto.airplane.web_partner_admin.ReportBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ReportBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ReportBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ReportBookingReq} returns this
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ReportBookingReq} returns this
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ReportBookingReq} returns this
 */
proto.airplane.web_partner_admin.ReportBookingReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ReportBookingRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ReportBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ReportBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_report_pb.ReportBookingItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ReportBookingRes}
 */
proto.airplane.web_partner_admin.ReportBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ReportBookingRes;
  return proto.airplane.web_partner_admin.ReportBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ReportBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ReportBookingRes}
 */
proto.airplane.web_partner_admin.ReportBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_report_pb.ReportBookingItem;
      reader.readMessage(value,airplane_report_pb.ReportBookingItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ReportBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ReportBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_report_pb.ReportBookingItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.ReportBookingRes} returns this
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ReportBookingRes} returns this
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.ReportBookingItem items = 3;
 * @return {!Array<!proto.airplane.ReportBookingItem>}
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.ReportBookingItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_report_pb.ReportBookingItem, 3));
};


/**
 * @param {!Array<!proto.airplane.ReportBookingItem>} value
 * @return {!proto.airplane.web_partner_admin.ReportBookingRes} returns this
*/
proto.airplane.web_partner_admin.ReportBookingRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.ReportBookingItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.ReportBookingItem}
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.ReportBookingItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ReportBookingRes} returns this
 */
proto.airplane.web_partner_admin.ReportBookingRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ReportAirlineBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ReportAirlineBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingReq}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ReportAirlineBookingReq;
  return proto.airplane.web_partner_admin.ReportAirlineBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ReportAirlineBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingReq}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ReportAirlineBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ReportAirlineBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingReq} returns this
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingReq} returns this
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingReq} returns this
 */
proto.airplane.web_partner_admin.ReportAirlineBookingReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.ReportAirlineBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_report_pb.ReportAirlineBookingItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingRes}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.ReportAirlineBookingRes;
  return proto.airplane.web_partner_admin.ReportAirlineBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingRes}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_report_pb.ReportAirlineBookingItem;
      reader.readMessage(value,airplane_report_pb.ReportAirlineBookingItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.ReportAirlineBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_report_pb.ReportAirlineBookingItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} returns this
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} returns this
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.ReportAirlineBookingItem items = 3;
 * @return {!Array<!proto.airplane.ReportAirlineBookingItem>}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.ReportAirlineBookingItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_report_pb.ReportAirlineBookingItem, 3));
};


/**
 * @param {!Array<!proto.airplane.ReportAirlineBookingItem>} value
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} returns this
*/
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.ReportAirlineBookingItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.ReportAirlineBookingItem}
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.ReportAirlineBookingItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner_admin.ReportAirlineBookingRes} returns this
 */
proto.airplane.web_partner_admin.ReportAirlineBookingRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.AggregateReportBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.AggregateReportBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.AggregateReportBookingReq}
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.AggregateReportBookingReq;
  return proto.airplane.web_partner_admin.AggregateReportBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.AggregateReportBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.AggregateReportBookingReq}
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.AggregateReportBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.AggregateReportBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.AggregateReportBookingReq} returns this
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.AggregateReportBookingReq} returns this
 */
proto.airplane.web_partner_admin.AggregateReportBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq}
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq;
  return proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq}
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq} returns this
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq} returns this
 */
proto.airplane.web_partner_admin.AggregateReportAirlineBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.CurrentBookingStatsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsReq}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.CurrentBookingStatsReq;
  return proto.airplane.web_partner_admin.CurrentBookingStatsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsReq}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.CurrentBookingStatsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsReq} returns this
 */
proto.airplane.web_partner_admin.CurrentBookingStatsReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.CurrentBookingStatsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.CurrentBookingStatsRes;
  return proto.airplane.web_partner_admin.CurrentBookingStatsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data;
      reader.readMessage(value,proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.CurrentBookingStatsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    countInprogress: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data;
  return proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountInprogress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountInprogress();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count_inprogress = 1;
 * @return {number}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.prototype.getCountInprogress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data} returns this
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data.prototype.setCountInprogress = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} returns this
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} returns this
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner_admin.CurrentBookingStatsRes.Data|undefined} value
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} returns this
*/
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner_admin.CurrentBookingStatsRes} returns this
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner_admin.CurrentBookingStatsRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.airplane.web_partner_admin);
