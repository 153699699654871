import cn from 'classnames';

import { ToastProps } from '@payment-portal/types';
import { InformationFillIcon } from '@icon';

function Info({ message, className }: ToastProps) {
  return (
    <div className={cn([className, 'bg-common-info/5 text-common-info'])}>
      <InformationFillIcon className='mr-2 h-6 w-6 shrink-0 ' />
      {message}
    </div>
  );
}

export default Info;
