'use client';
import { IUserBasicInfo } from '@tixlabs/index';

import { useState } from 'react';
import { useAppMutation } from '../internals';
import {
  IListPartnerUserBasicInfoReq,
  partnerShopUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';

const useListPartnerShopUser = () => {
  const [listPartnerShopUser, setListPartnerShopUser] = useState<
    IUserBasicInfo[]
  >([]);
  const { mutateAsync: getListPartnerUser } = useAppMutation({
    mutationKey: ['partnerShopUserServiceClient', 'listPartnerUserBasicInfo'],
    mutationFn: partnerShopUserServiceClient.listPartnerUserBasicInfo,
    onSuccess: (data) => {
      if (data.data?.itemsList) {
        setListPartnerShopUser([...data.data.itemsList]);
      }
    },
  });

  const handleGetListPartnerShopUser = async (
    req: IListPartnerUserBasicInfoReq
  ) => {
    await getListPartnerUser({
      pagination: req.pagination,
      filter: req.filter,
    });
  };

  return {
    listPartnerShopUser,
    handleGetListPartnerShopUser,
  };
};

export default useListPartnerShopUser;
