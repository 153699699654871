import { Toggle } from '@common-ui';
import { IUpdateOfficeProvidersReq } from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';
import { useMemo } from 'react';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';

type Props = {
  label: string;
  value: SelectOptionItem['value'];
  nameField: FieldPath<IUpdateOfficeProvidersReq>;
};

//! Should enhance to be a Form component in Common UI
const ModalToggleProvider = ({ label, value, nameField }: Props) => {
  const methods = useFormContext<IUpdateOfficeProvidersReq>();
  const providerListValue = useWatch({ name: nameField });

  const toggleValue = useMemo(() => {
    return providerListValue?.some((formValue) => formValue === value);
  }, [providerListValue, value]);
  function onChangeToggle(event: boolean) {
    if (event) {
      const updateProviderList = [...providerListValue, value];
      methods.setValue(nameField, updateProviderList);
    } else {
      const updateProviderList = providerListValue.filter(
        (formValue) => formValue !== value
      );
      methods.setValue(nameField, updateProviderList);
    }
  }

  return (
    <div className='flex gap-x-5 items-center'>
      <Toggle value={toggleValue} onChange={onChangeToggle} />
      <span>{label}</span>
    </div>
  );
};

export default ModalToggleProvider;
