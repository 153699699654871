import {
  AddCircleIcon,
  Button,
  FormSelect,
  FormSelectSearch,
  SearchLineIcon,
} from '@common-ui';

import { SetStateAction, useMemo } from 'react';
import { ESwitchModal, TModalHiddenFee } from './HiddenFeeModal';
import TableServiceFee from './TableHiddenFee';
import { useSearchAirlines } from '@sky-booking-config/hooks/apps';
import { OPTIONS_FLIGHT_ITINERARY } from '@sky-booking-config/utils';
import { EFlightItineraryType, IFlightHiddenFee } from '@tixlabs/grpc-client';

type Props = {
  isLoading: boolean;
  listHiddenFee: IFlightHiddenFee[];
  pagination: {
    pageCurrent: number;
    totalPage: number;
    totalRecord: number;
    pageLimit: number;
  };

  onChangePage: (pageNumber: number) => Promise<void>;
  handleUpdateModalInfo: (value: SetStateAction<TModalHiddenFee>) => void;
};

export const ListHiddenFee = ({
  listHiddenFee,
  isLoading,
  pagination,
  onChangePage,
  handleUpdateModalInfo,
}: Props) => {
  const { handleSearch: handleSearchAirline, airlinesList } =
    useSearchAirlines();

  const airlineOptions = useMemo(() => {
    return [
      { label: 'Tất cả', value: '' },
      ...airlinesList.map((item) => ({
        label: item.name,
        value: item.code,
      })),
    ];
  }, [airlinesList]);

  return (
    <div className='p-3 border rounded space-y-5 bg-white'>
      <div className='py-5 flex border-b justify-between'>
        <div className='flex space-x-2.5'>
          <FormSelect
            className='min-w-[200px]'
            name='type'
            selectOptions={[
              { value: EFlightItineraryType.INONE, label: 'Tất cả' },
              ...OPTIONS_FLIGHT_ITINERARY,
            ]}
            label='Loại chuyến bay'
            placeholder='Chọn loại chuyến bay'
            inputProps={{ isOnlyValue: true }}
          />
          <FormSelectSearch
            name='airlineCode'
            label='Hãng hàng không'
            placeholder='Chọn hãng hàng không'
            handleSearch={handleSearchAirline}
            selectOptions={airlineOptions}
            labelProps={{ isRequired: true }}
            inputProps={{
              isOnlyValue: true,
            }}
          />

          <div className='flex flex-col justify-end'>
            <Button
              type='submit'
              theme='secondary'
              prefixIcon={<SearchLineIcon />}
              className='font-semibold'>
              Tìm kiếm
            </Button>
          </div>
        </div>
        <div className='flex items-end'>
          <Button
            type='button'
            onClick={() =>
              handleUpdateModalInfo((pre) => ({
                ...pre,
                open: true,
                state: ESwitchModal.ADD_HIDDEN_FEE,
              }))
            }
            theme='green'
            prefixIcon={<AddCircleIcon className='w-5 h-5 shrink-0' />}>
            Thêm mới
          </Button>
        </div>
      </div>

      <TableServiceFee
        data={listHiddenFee}
        handleUpdateModalInfo={handleUpdateModalInfo}
        pagination={{
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onChangePage,
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ListHiddenFee;
