import { Outlet } from 'react-router-dom';

export const AuthLayout = () => {
  return (
    <div className='w-screen h-screen items-center flex justify-center bg-[#F8F8FB]'>
      <div className='space-y-7'>
        <div>
          <Outlet />
        </div>
        <div className='text-sm text-center text-neutral-8'>
          © {new Date().getFullYear()} Flight HUB Config
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
