import { FlightServiceClient } from '@api/airplane/web_partner_admin/flight_grpc_web_pb';
import {
  ListBookingFlightReq,
  ListBookingFlightRes,
  RetrieveBookingFlightReq,
  RetrieveBookingFlightRes,
  SearchAirlinesReq,
  SearchAirlinesRes,
} from './types/flight_pb';

import { PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import {
  ExportBookingFlightReq,
  ExportBookingFlightRes,
  SortItem,
} from '@api/airplane/web_partner_admin/flight_pb';

class FlightApi extends BaseApi<FlightServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new FlightServiceClient(this.url, null, this.optsDev);
  }

  searchAirlines = ({
    filter,
    pagination,
  }: SearchAirlinesReq.AsObject): Promise<SearchAirlinesRes.AsObject> => {
    const req = new SearchAirlinesReq();
    const filterReq = new SearchAirlinesReq.Filter();

    filterReq.setText(filter?.text || '');
    if (pagination) {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    } else {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(9999);
      paginationReq.setPageNumber(1);
      req.setPagination(paginationReq);
    }
    req.setFilter(filterReq);

    return this.grpc<
      SearchAirlinesReq,
      SearchAirlinesRes,
      SearchAirlinesRes.AsObject
    >(this.serviceClient?.searchAirlines, req, {});
  };

  listBookingFlight = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingFlightReq.AsObject): Promise<ListBookingFlightRes.AsObject> => {
    const req = new ListBookingFlightReq();
    const filterObj = new ListBookingFlightReq.Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);

      filter.fromDate && filterObj.setFromDate(filter.fromDate);

      filter.partnershopId &&
        filterObj.setPartnershopId(filter.partnershopId?.trim() || '');

      filter.bookerId && filterObj.setBookerId(filter.bookerId?.trim() || '');

      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');

      filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListBookingFlightReq,
      ListBookingFlightRes,
      ListBookingFlightRes.AsObject
    >(this.serviceClient?.listBookingFlight, req, {});
  };

  retrieveBookingFlight = ({
    bookingCode,
  }: RetrieveBookingFlightReq.AsObject): Promise<RetrieveBookingFlightRes.AsObject> => {
    const req = new RetrieveBookingFlightReq();

    req.setBookingCode(bookingCode);

    return this.grpc<
      RetrieveBookingFlightReq,
      RetrieveBookingFlightRes,
      RetrieveBookingFlightRes.AsObject
    >(this.serviceClient.retrieveBookingFlight, req, {});
  };

  exportBookingFlight = ({
    sortsList,
    filter,
  }: ExportBookingFlightReq.AsObject): Promise<ExportBookingFlightRes.AsObject> => {
    const req = new ExportBookingFlightReq();
    const filterObj = new ExportBookingFlightReq.Filter();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.bookerId && filterObj.setBookerId(filter.bookerId?.trim() || '');
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filter.statusesList && filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ExportBookingFlightReq,
      ExportBookingFlightRes,
      ExportBookingFlightRes.AsObject
    >(this.serviceClient?.exportBookingFlight, req, {});
  };
}

export const flightApiService = new FlightApi();

export default flightApiService;
