/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partner_admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partner_admin/service_fee.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var airplane_base_pb = require('../../airplane/base_pb.js')

var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partner_admin = require('./service_fee_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner_admin.ServiceFeeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner_admin.ServiceFeeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.AddFlightServiceFeeReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ServiceFeeService_AddFlightServiceFee = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.ServiceFeeService/AddFlightServiceFee',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.AddFlightServiceFeeReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner_admin.AddFlightServiceFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.AddFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.ServiceFeeServiceClient.prototype.addFlightServiceFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/AddFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_AddFlightServiceFee,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.AddFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.ServiceFeeServicePromiseClient.prototype.addFlightServiceFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/AddFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_AddFlightServiceFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.UpdateFlightServiceFeeReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ServiceFeeService_UpdateFlightServiceFee = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.ServiceFeeService/UpdateFlightServiceFee',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.UpdateFlightServiceFeeReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner_admin.UpdateFlightServiceFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.UpdateFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.ServiceFeeServiceClient.prototype.updateFlightServiceFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/UpdateFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_UpdateFlightServiceFee,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.UpdateFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.ServiceFeeServicePromiseClient.prototype.updateFlightServiceFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/UpdateFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_UpdateFlightServiceFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.ListFlightServiceFeeReq,
 *   !proto.airplane.web_partner_admin.ListFlightServiceFeeRes>}
 */
const methodDescriptor_ServiceFeeService_ListFlightServiceFee = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.ServiceFeeService/ListFlightServiceFee',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.ListFlightServiceFeeReq,
  proto.airplane.web_partner_admin.ListFlightServiceFeeRes,
  /**
   * @param {!proto.airplane.web_partner_admin.ListFlightServiceFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.ListFlightServiceFeeRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.ListFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.ListFlightServiceFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.ListFlightServiceFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.ServiceFeeServiceClient.prototype.listFlightServiceFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/ListFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_ListFlightServiceFee,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.ListFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.ListFlightServiceFeeRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.ServiceFeeServicePromiseClient.prototype.listFlightServiceFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/ListFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_ListFlightServiceFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.DeleteFlightServiceFeeReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ServiceFeeService_DeleteFlightServiceFee = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.ServiceFeeService/DeleteFlightServiceFee',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.DeleteFlightServiceFeeReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner_admin.DeleteFlightServiceFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.DeleteFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.ServiceFeeServiceClient.prototype.deleteFlightServiceFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/DeleteFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_DeleteFlightServiceFee,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.DeleteFlightServiceFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.ServiceFeeServicePromiseClient.prototype.deleteFlightServiceFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.ServiceFeeService/DeleteFlightServiceFee',
      request,
      metadata || {},
      methodDescriptor_ServiceFeeService_DeleteFlightServiceFee);
};


module.exports = proto.airplane.web_partner_admin;

