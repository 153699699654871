import { useAppMutation } from '@web-booker/hooks/internals';
import bookingServiceApi from '@tixlabs/grpc-client/web-partnership/booking-service';
import {
  skyHubNotificationServiceApi,
  notificationServiceApi,
} from '@tixlabs/grpc-client/web-partnership';

const NotificationMethods = {
  1: 'Email',
  2: 'Zalo',
  3: 'SMS',
};

const NotificationStatus = {
  1: 'Sent',
  2: 'Fail',
};

const useNotification = () => {
  const { mutateAsync: getBookingInfo } = useAppMutation({
    mutationKey: ['bookingServiceApi', 'getBookingInfo'],
    mutationFn: bookingServiceApi.getBookingInfo,
  });

  const { mutateAsync: getHistoryBookingNotifications } = useAppMutation({
    // check
    mutationKey: ['notificationServiceApi', 'getHistoryBookingNotifications'],
    mutationFn: skyHubNotificationServiceApi.getHistoryBookingNotifications,
  });

  const { mutateAsync: sendRawBookingNotification } = useAppMutation({
    mutationKey: ['notificationServiceApi', 'sendRawBookingNotification'],
    mutationFn: skyHubNotificationServiceApi.sendRawBookingNotification,
  });

  const { mutateAsync: getSenders } = useAppMutation({
    mutationKey: ['notificationServiceApi', 'getSenders'],
    mutationFn: notificationServiceApi.getSenders,
  });

  return {
    getBookingInfo,
    getHistoryBookingNotifications,
    sendRawBookingNotification,
    getSenders,
    NotificationMethods,
    NotificationStatus,
  };
};

export default useNotification;
