import {
  Button,
  ButtonLoading,
  Edit2FillIcon,
  Form,
  FormInput,
  FormSelect,
  User3FillIcon,
} from '@common-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListIssuer } from './ListIssuer';
import useManageOfficeId from './useManageOfficeId';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import {
  ROLE_USER,
  UserStatusListFilterOptions,
  checkRoleUser,
} from '@sky-booking-config/utils';

type Props = {
  //
};

export const ManageIssuerCtn = (props: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserStore();

  const {
    issuerList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    changePage,
    handleSubmit,
  } = useManageOfficeId();

  useEffect(() => {
    manageOfficeIdMethod.setValue('creatorId', userData?.id || '');
    manageOfficeIdMethod.handleSubmit(handleSubmit)();
  }, []);

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <User3FillIcon className='w-5 h-5 shrink-0' />
        <div className='font-semibold text-lg'>Quản lý người xuất hộ</div>
        <Button
          theme='green'
          onClick={() => {
            navigate('./create-issuer');
          }}>
          Thêm tài khoản
        </Button>
      </div>
      <div className='max-w-[1100px]'>
        <Form
          methods={manageOfficeIdMethod}
          onSubmit={handleSubmit}
          className='w-full flex flex-col gap-y-2.5'>
          <div className='flex justify-between'>
            <div className='flex gap-x-3'>
              <div className='w-40'>
                <FormInput
                  name='userName'
                  label='Tên đăng nhập'
                  placeholder='Tìm tên đăng nhập'
                />
              </div>
              <div className='w-40'>
                <FormInput
                  name='name'
                  label='Tên thành viên'
                  placeholder='Tìm tên thành viên'
                />
              </div>
              <div className='w-40'>
                <FormSelect
                  name='status'
                  selectOptions={UserStatusListFilterOptions}
                  label={'Trạng thái'}
                  inputProps={{
                    isOnlyValue: true,
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col justify-end'>
              {checkRoleUser(ROLE_USER.OFFICE_MANAGER, userData) ? (
                <div className='flex flex-col justify-end'>
                  <ButtonLoading
                    type='submit'
                    theme='secondary'
                    className='!px-10'
                    prefixIcon={<Edit2FillIcon />}
                    loading={isLoadingGetUserList}>
                    Tìm kiếm
                  </ButtonLoading>
                </div>
              ) : null}
            </div>
          </div>
          <ListIssuer
            isLoading={isLoadingGetUserList}
            data={issuerList}
            pagination={pagination}
            onChangePage={changePage}
          />
        </Form>
      </div>
    </div>
  );
};

export default ManageIssuerCtn;
