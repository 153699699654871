import {
  Button,
  Form,
  FormGroupRadio,
  FormInput,
  FormSelect,
} from '@common-ui';
import { PartnerUserStatusListCreateOptions } from '@sky-booking-config/utils';
import { SelectOptionItem } from '@tixlabs/types';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToggleProvider from '../CreateAndUpdateOfficeId/ToggleProvider';
import useCreateAndUpdateOfficeId from '../CreateAndUpdateOfficeId/useCreateAndUpdateOfficeId';

type Props = {
  //
};

export const DetailOfficeIdCtn = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const {
    officeIdInfo,
    updateOfficeIdMethod,
    domainListOption,
    allProviders,
    updateProviderMethod,
    handleUpdateOfficeProviders,
    handleSubmitUpdateOfficeId,
    getOfficeDetailInfo,
    getDomainListOption,
    getAllProviderOption,
    // getProviderListOption,
  } = useCreateAndUpdateOfficeId();
  const providerOptions: SelectOptionItem[] = useMemo(() => {
    return (
      (allProviders.length > 0 &&
        officeIdInfo &&
        officeIdInfo.providerConfigsList.length > 0 &&
        officeIdInfo.providerConfigsList.map((providerConfig) => {
          const provider = allProviders.find(
            (provider) => provider.value === providerConfig.value
          ) as SelectOptionItem;
          return providerConfig.enabled
            ? {
                ...provider,
                enabled: true,
              }
            : provider;
        })) ||
      []
    );
  }, [officeIdInfo, allProviders]);

  useEffect(() => {
    if (id) {
      getOfficeDetailInfo(id);
    }
  }, [id]);

  useEffect(() => {
    if (officeIdInfo !== undefined && officeIdInfo !== null) {
      const { domain, email, id, name, phone, shopName, status, userName } =
        officeIdInfo;
      updateOfficeIdMethod.setValue('email', email);
      updateOfficeIdMethod.setValue('id', id);
      updateOfficeIdMethod.setValue('name', name);
      updateOfficeIdMethod.setValue('phone', phone);
      updateOfficeIdMethod.setValue('shopName', shopName);
      updateOfficeIdMethod.setValue('status', status);
      updateOfficeIdMethod.setValue('userName', userName);
      domain && updateOfficeIdMethod.setValue('domain', domain.value);
      updateProviderMethod.setValue(
        'enabledProvidersList',
        officeIdInfo.providerConfigsList
          .map((i) => {
            return i.enabled === true ? i.value : undefined;
          })
          .filter((i) => i !== undefined) as number[]
      );
    }
  }, [officeIdInfo]);

  useEffect(() => {
    getDomainListOption();
    getAllProviderOption();
  }, []);

  return (
    <Form
      methods={updateOfficeIdMethod}
      onSubmit={handleSubmitUpdateOfficeId}
      className='max-w-[1100px] flex flex-col gap-y-5'>
      <div className='flex flex-col gap-y-5 p-4 border rounded'>
        <div className='flex gap-x-5'>
          <div className='w-1/3'>
            <FormInput
              name='userName'
              label='Tên đăng nhập'
              placeholder='Nhập tên đăng nhập'
              inputProps={{
                disabled: true,
              }}
            />
          </div>
          <div className='w-1/3'>
            <FormGroupRadio
              name='status'
              label='Trạng thái'
              className='flex pointer-events-none'
              radioOptions={PartnerUserStatusListCreateOptions}
              inputProps={{
                disabled: true,
              }}
            />
          </div>
        </div>
        <div className='w-2/3'>
          <FormSelect
            name='domain'
            label='Domain'
            placeholder='Chọn domain'
            selectOptions={domainListOption}
            inputProps={{ isOnlyValue: true, disabled: true }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Thông tin liên hệ</span>
        <div className='flex flex-col gap-y-5'>
          <div className='flex gap-x-5'>
            <div className='w-1/3'>
              <FormInput
                name='name'
                label='Họ tên'
                placeholder='Nhập họ tên'
                inputProps={{
                  disabled: true,
                }}
              />
            </div>
            <div className='w-1/3'>
              <FormInput
                name='phone'
                label='Điện thoại'
                placeholder='Nhập điện thoại'
                inputProps={{
                  disabled: true,
                }}
              />
            </div>
            <div className='w-1/3'>
              <FormInput
                name='email'
                label='Email'
                placeholder='Nhập email'
                inputProps={{
                  disabled: true,
                }}
              />
            </div>
          </div>
          <div className='w-2/3'>
            <FormInput
              name='shopName'
              label='Công ty/Đại lý'
              placeholder='Tên công ty/đại lý'
              inputProps={{
                disabled: true,
              }}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Danh sách provider</span>

        <div className='w-full flex flex-col gap-y-3'>
          <Form
            methods={updateProviderMethod}
            onSubmit={handleUpdateOfficeProviders}>
            {providerOptions && providerOptions.length > 0 ? (
              <div className='w-full flex flex-col gap-y-3'>
                {providerOptions.map((item, index) => {
                  return (
                    <ToggleProvider
                      key={index}
                      label={item.label}
                      value={item.value}
                      disabled={true}
                      nameField='enabledProvidersList'
                    />
                  );
                })}
              </div>
            ) : (
              <span>Không có Provider nào được cài đặt.</span>
            )}
          </Form>
        </div>
      </div>

      <div className='flex justify-end gap-x-5'>
        <Button
          variant='outline'
          onClick={() => {
            // Go back router
            navigate(-1);
          }}>
          Quay lại
        </Button>
      </div>
    </Form>
  );
};

export default DetailOfficeIdCtn;
