import { SelectOptionItem } from '@tixlabs/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppMutation } from '../internals';
import {
  ICommissionRate,
  ISaleServiceFee,
  IWalletConfig,
  partnerWebServiceClient,
  serviceConfigApi,
} from '@tixlabs/grpc-client/web-partnership';
import toast from 'react-hot-toast';
import { getApiErrorMessages } from '@core/utils';

type FormConfigWallet = {
  officeId: string;
  commission: ICommissionRate;
  saleServiceFee: ISaleServiceFee;
  platformFee: number;
  walletConfig: IWalletConfig;
  officeBalance: number;
};

const defaultFormValue: FormConfigWallet = {
  officeId: '',
  commission: {
    airTicket: 0,
    sim: 0,
    visa: 0,
  },
  platformFee: 0,
  saleServiceFee: {
    rate: 0,
    supportRate: 0,
  },
  walletConfig: {
    advanceBalance: 0,
    depositBalance: 0,
    guaranteeAmount: 0,
  },
  officeBalance: 0,
};

export const useConfigWalletAndFee = () => {
  const methods = useForm<FormConfigWallet>();

  const [officeIdOptionList, setOfficeIdOptionList] = useState<
    SelectOptionItem<string>[]
  >([]);

  const { mutateAsync: getListOffice } = useAppMutation({
    mutationKey: ['partnerWebServiceClient', 'getOffices'],
    mutationFn: serviceConfigApi.getListOffice,
  });

  const { mutateAsync: getConfigByOfficeID, isLoading: isLoadingGetConfig } =
    useAppMutation({
      mutationKey: ['serviceConfigApi', 'getConfigByOfficeID'],
      mutationFn: serviceConfigApi.getConfigByOfficeID,
    });

  const { mutateAsync: updateConfigByOfficeID, isLoading: isLoadingUpdate } =
    useAppMutation({
      mutationKey: ['serviceConfigApi', 'updateConfigByOfficeID'],
      mutationFn: serviceConfigApi.updateConfigByOfficeID,
    });

  const getOfficeIdList = async () => {
    try {
      const { dataList } = await getListOffice({});
      if (dataList.length > 0) {
        const mappedList = dataList.map<SelectOptionItem<string>>((item) => {
          return {
            label: item.code + ' - ' + item.officeName,
            value: item.officeId,
          };
        });
        setOfficeIdOptionList(mappedList);
      }
    } catch (error) {
      console.log('getOfficeIdList: ', error);
    }
  };

  const handleChangeOfficeId = async (officeId: string) => {
    try {
      const { isSuccess, errorCode, data } = await getConfigByOfficeID({
        officeId,
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      methods.reset({
        officeId: officeId,
        commission: data?.commission,
        platformFee: data?.platformFee,
        saleServiceFee: data?.saleServiceFee,
        walletConfig: {
          ...data?.walletConfig,
        },
        officeBalance: data?.officeBalance || 0,
      });
    } catch (error) {
      methods.reset({
        ...defaultFormValue,
        officeId: officeId,
      });
      methods.setError('root', { type: 'disabled' });

      const message = getApiErrorMessages(error)[0];
      toast.error(message);
    }
  };

  const handleSubmit = async (data: FormConfigWallet) => {
    try {
      const { isSuccess, errorCode } = await updateConfigByOfficeID({
        ...data,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật thành công!');
      handleChangeOfficeId(data.officeId);
    } catch (error) {
      const message = getApiErrorMessages(error)[0];
      toast.error(message);
    }
  };

  return {
    methods,
    handleSubmit,
    getOfficeIdList,
    isLoadingUpdate,
    officeIdOptionList,
    isLoadingGetConfig,
    handleChangeOfficeId,
  };
};

export default useConfigWalletAndFee;
