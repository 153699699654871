import { EPaymentMethodBase, ETransactionTypeBase } from '@tixlabs/grpc-client';
import { ETransactionType } from '@tixlabs/grpc-client';
import { SelectOptionItem } from '@tixlabs/types';

export const transactionTypeLabel = {
  [ETransactionType.TRANSACTIONTYPENONE]: 'Tất cả',
  [ETransactionType.TRANSACTIONTYPEPAY]: 'Thanh toán',
  [ETransactionType.TRANSACTIONTYPEREFUND]: 'Hoàn tiền',
  [ETransactionType.TRANSACTIONTYPETOPUP]: 'Nạp tiền tự động',
  [ETransactionType.TRANSACTIONTYPETOPUPMANUAL]: 'Nạp tiền thủ công',
  [ETransactionType.TRANSACTIONTYPEWITHDRAW]: 'Rút tiền',
};
export const transactionTypeBaseLabel = {
  [ETransactionTypeBase.REFUND]: 'Hoàn tiền',
  [ETransactionTypeBase.TOPUP]: 'Nạp tiền tự động',
  [ETransactionTypeBase.TOPUPMANUAL]: 'Nạp tiền thủ công',
  [ETransactionTypeBase.PAY]: 'Thanh toán',
  [ETransactionTypeBase.WITHDRAW]: 'Rút tiền',
  [ETransactionTypeBase.TRANSACTIONTYPEINVALID]: 'Không hợp lệ',
};

export const SELECT_TRANSACTION_TYPE_OPTIONS: SelectOptionItem[] = [
  {
    value: ETransactionType.TRANSACTIONTYPENONE,
    label: 'Tất cả',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEPAY,
    label: 'Thanh toán',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEREFUND,
    label: 'Hoàn tiền',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEPAY,
    label: 'Nạp tiền tự động',
  },
  {
    value: ETransactionType.TRANSACTIONTYPETOPUP,
    label: 'Nạp tiền thủ công',
  },
];

export const paymentMethodLabel = {
  [EPaymentMethodBase.METHODNONE]: 'Quỹ xuất vé',
  [EPaymentMethodBase.PAYPAL]: EPaymentMethodBase.PAYPAL,
  [EPaymentMethodBase.VNPAYEWALLET]: EPaymentMethodBase.VNPAYEWALLET,
  [EPaymentMethodBase.VNPAYCREDITCARD]: EPaymentMethodBase.VNPAYCREDITCARD,
  [EPaymentMethodBase.VNPAYBANKTRANSFER]: EPaymentMethodBase.VNPAYBANKTRANSFER,
  [EPaymentMethodBase.VNPAYQRCODE]: EPaymentMethodBase.VNPAYQRCODE,
  [EPaymentMethodBase.MANUALBANKTRANSFER]: 'Chuyển khoản',
  [EPaymentMethodBase.MOMO]: EPaymentMethodBase.MOMO,
  [EPaymentMethodBase.TWOCHECKOUT]: EPaymentMethodBase.TWOCHECKOUT,
  [EPaymentMethodBase.PAYOOCREDITCARD]: EPaymentMethodBase.PAYOOCREDITCARD,
  [EPaymentMethodBase.PAYOODOMESTICCARD]: EPaymentMethodBase.PAYOODOMESTICCARD,
  [EPaymentMethodBase.PAYOOQRCODE]: EPaymentMethodBase.PAYOOQRCODE,
  [EPaymentMethodBase.ONEPAYCREDITCARD]:
    'Thẻ Visa/Master/JCB phát hành ở nước ngoài',
  [EPaymentMethodBase.ONEPAYCREDITCARDDOMESTICISSUE]:
    'Thẻ Visa/Master/JCB phát hành tại Việt Nam',
  [EPaymentMethodBase.ONEPAYDOMESTICCARD]: 'Thẻ ATM nội địa',
  [EPaymentMethodBase.ONEPAYQRCODE]: EPaymentMethodBase.ONEPAYQRCODE,
  [EPaymentMethodBase.APPLEPAY]: EPaymentMethodBase.APPLEPAY,
  [EPaymentMethodBase.STRIPE]: EPaymentMethodBase.STRIPE,
  [EPaymentMethodBase.BIDVQR]: EPaymentMethodBase.BIDVQR,
  [EPaymentMethodBase.EGIFTCARD]: EPaymentMethodBase.EGIFTCARD,
  [EPaymentMethodBase.WALLET]: 'Quỹ xuất vé',
  [EPaymentMethodBase.ONEFIN]: 'OneFin',
};
