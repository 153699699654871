import {
  EModalMode,
  ILanguageData,
  IModal,
} from '@sky-booking-config/types/common';
import { LANGUAGES_CODE } from '@tixlabs/types';
import { MenuProps } from 'antd';
import { ItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';
export interface IMenuProps extends MenuProps {
  items: ItemType<SubMenuType>[];
}
export type MenuItem = Required<IMenuProps>['items'][number];

export const LANGUAGE_SUPPORT: ILanguageData[] = [
  {
    code: LANGUAGES_CODE.VIETNAM,
    text: 'Việt Nam',
  },
  {
    code: LANGUAGES_CODE.ENGLISH,
    text: 'English',
  },
];

export const IP_COUNTRY_KEY = 'cf-ipcountry';
export const IP_COUNTRY_DEFAULT = 'VN';
export const ONE_YEAR_TIMESTAMP = 365 * 24 * 60 * 60 * 1000;
export const ONE_DAY_TIMESTAMP = 24 * 60 * 60 * 1000;

export const SUPPORT_FILES = [
  'image/jpeg',
  'image/png',
  'image/avif',
  'image/tiff',
  'image/heic',
  'image/heif',
  'image/webp',
  'application/pdf',
];

export const SUPPORT_IMAGES_TYPE = [
  'image/png',
  'image/bmp',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
];

export const DEFAULT_PAGE_LIMIT = 20;

export const DEFAULT_MODAL_CONTENT: IModal = {
  open: false,
  title: '',
  cancelText: 'Hủy',
  okText: 'Ok',
  centered: true,
  mode: EModalMode.CONFIRM,
  width: 'auto',
};

export enum ROLE_USER {
  SUPER_ADMIN = 'super_admin',
  AGENCY_BOOKER = 'agency_booker',
  AGENCY_ISSUER = 'agency_issue_partner',
  SKY_ADMIN = 'sky_admin',
  ACCOUNTANT = 'accountant',
  APPRAISAL = 'appraisal',
  APPRAISAL_MANAGER = 'appraisal_manager',
  OFFICE_MANAGER = 'office_manager',
  ALL_PAYMENT_PORTAL = 'accountant,appraisal,appraisal_manager',
  CUSTOMER_SUPPORT = 'customer_support',
}

export const ROLE_LIST_ALLOWED = [
  ROLE_USER.SUPER_ADMIN,
  ROLE_USER.SKY_ADMIN,
  ROLE_USER.OFFICE_MANAGER,
  ROLE_USER.ACCOUNTANT,
  ROLE_USER.APPRAISAL,
  ROLE_USER.APPRAISAL_MANAGER,
  ROLE_USER.CUSTOMER_SUPPORT,
];

export enum GROUP_FEATURE {
  PORTAL_AGENTS_MANAGER = 'portal-agents-manager',
  PORTAL_ORDERS_MANAGER = 'portal-orders-manager',
  SKYHUB_CONFIG_MANAGER = 'skyhub-config-manager',
  PORTAL_PAYMENT_MANAGER = 'portal-payment-manager',
  PORTAL_ISSUER_MANAGER = 'portal-issuer-manager',
  PORTAL_REPORT_MANAGER = 'portal-report-manager',
  PORTAL_CUSTOMER_SUPPORT = 'portal-customter-support',
}

export const GROUP_FEATURE_BY_ROLE: Record<string, string[]> = {
  [ROLE_USER.SUPER_ADMIN]: [
    GROUP_FEATURE.PORTAL_AGENTS_MANAGER,
    GROUP_FEATURE.PORTAL_PAYMENT_MANAGER,
  ],
  [ROLE_USER.SKY_ADMIN]: [GROUP_FEATURE.SKYHUB_CONFIG_MANAGER],
  [ROLE_USER.OFFICE_MANAGER]: [
    GROUP_FEATURE.PORTAL_AGENTS_MANAGER,
    GROUP_FEATURE.PORTAL_ORDERS_MANAGER,
    GROUP_FEATURE.PORTAL_ISSUER_MANAGER,
  ],
  [ROLE_USER.APPRAISAL]: [GROUP_FEATURE.PORTAL_PAYMENT_MANAGER],
  [ROLE_USER.APPRAISAL_MANAGER]: [GROUP_FEATURE.PORTAL_PAYMENT_MANAGER],
  [ROLE_USER.ACCOUNTANT]: [
    GROUP_FEATURE.PORTAL_PAYMENT_MANAGER,
    GROUP_FEATURE.PORTAL_REPORT_MANAGER,
  ],
  [ROLE_USER.CUSTOMER_SUPPORT]: [GROUP_FEATURE.PORTAL_CUSTOMER_SUPPORT],
};

export const FEATURE_BY_GROUP: Record<string, string[]> = {
  [GROUP_FEATURE.PORTAL_AGENTS_MANAGER]: [
    'manage-agent-users',
    'providers',
    'agents',
  ],
  [GROUP_FEATURE.SKYHUB_CONFIG_MANAGER]: [
    'config-wallet-and-fee',
    'hidden-fee',
  ],
  [GROUP_FEATURE.PORTAL_PAYMENT_MANAGER]: [
    'top-up-request-management',
    'transaction-management',
    'create-top-up-request',
    'user-management',
    'add-user',
  ],
  [GROUP_FEATURE.PORTAL_ISSUER_MANAGER]: [
    'issuer-management',
    'list-transfer-booking-request',
    'transfer-booking-fee-management',
  ],
  [GROUP_FEATURE.PORTAL_REPORT_MANAGER]: ['report-booking-management'],
  [GROUP_FEATURE.PORTAL_CUSTOMER_SUPPORT]: [
    'send-notification',
    'send-notification/:bookingCode',
  ],
};

export const FEATURE_ACCESS_BY_ROLE: Record<string, string[]> = {
  [ROLE_USER.SUPER_ADMIN]: [
    'manage-agent-users',
    // 'providers',
    'agents',
    'user-management',
    'add-user',
  ],
  [ROLE_USER.OFFICE_MANAGER]: [
    'agents',
    'list-booking',
    'list-transaction',
    'issuer-management',
    'list-transfer-booking-request',
    'transfer-booking-fee-management',
  ],
  [ROLE_USER.SKY_ADMIN]: ['config-wallet-and-fee', 'hidden-fee'],
  [ROLE_USER.APPRAISAL]: [
    'top-up-request-management',
    'transaction-management',
  ],
  [ROLE_USER.APPRAISAL_MANAGER]: [
    'top-up-request-management',
    'transaction-management',
  ],
  [ROLE_USER.ACCOUNTANT]: [
    'top-up-request-management',
    'transaction-management',
    'create-top-up-request',
    'report-booking-management',
  ],
  [ROLE_USER.CUSTOMER_SUPPORT]: [
    'send-notification',
    'send-notification/:bookingCode',
  ],
};
