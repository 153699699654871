import { EFlightItineraryType } from '@tixlabs/grpc-client';

export const ITINERARY_TYPE_LABEL = {
  [EFlightItineraryType.IDOMESTIC]: 'Bay trong nước',
  [EFlightItineraryType.IINTERNATIONAL]: 'Bay quốc tế',
};

export const OPTIONS_FLIGHT_ITINERARY = [
  {
    label: ITINERARY_TYPE_LABEL[EFlightItineraryType.IDOMESTIC],
    value: EFlightItineraryType.IDOMESTIC,
  },
  {
    label: ITINERARY_TYPE_LABEL[EFlightItineraryType.IINTERNATIONAL],
    value: EFlightItineraryType.IINTERNATIONAL,
  },
];
