import { useEffect } from 'react';
import { useAppLanguage } from '../internals';

import { getCities } from '@tixlabs/axios-client';
import { ICity, LANGUAGES_CODE } from '@tixlabs/types';
import { useAppDispatch, useAppSelector } from '@sky-booking-config/stores';
import { updateCities } from '@sky-booking-config/stores/reducers/common';

export function useCities() {
  const { language } = useAppLanguage();
  const { cities = [], citiesObjectByCode = {} } = useAppSelector(
    (state) => state.common
  );
  const dispatch = useAppDispatch();

  async function fetchCities() {
    try {
      const { data } = await getCities();

      if (data?.cities) {
        dispatch(updateCities(data.cities));
      }
    } catch (error) {}
  }

  function getCityByCode(code: string): ICity | undefined {
    return citiesObjectByCode?.[code];
  }

  function getCityNameByCode(code: string): string {
    const findCity = getCityByCode(code);

    if (!findCity) {
      return code;
    }

    let nameByLanguage = findCity.name[language];

    if (nameByLanguage) {
      return nameByLanguage;
    }

    // Switch if don't have value
    if (language === LANGUAGES_CODE.ENGLISH) {
      nameByLanguage = findCity.name[LANGUAGES_CODE.VIETNAM];
    }

    if (language === LANGUAGES_CODE.VIETNAM) {
      nameByLanguage = findCity.name[LANGUAGES_CODE.ENGLISH];
    }

    return nameByLanguage || code;
  }

  useEffect(() => {
    if (!cities?.length) {
      fetchCities();
    }
  }, []);

  return {
    cities,
    citiesObjectByCode,
    getCityByCode,
    getCityNameByCode,
  };
}

export default useCities;
