/**
 * @fileoverview gRPC-Web generated client stub for partner.web_partner_admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: partner/web_partner_admin/auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.partner = {};
proto.partner.web_partner_admin = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partner_admin.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partner_admin.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner_admin.LoginReq,
 *   !proto.partner.web_partner_admin.LoginRes>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/partner.web_partner_admin.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner_admin.LoginReq,
  proto.partner.web_partner_admin.LoginRes,
  /**
   * @param {!proto.partner.web_partner_admin.LoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner_admin.LoginRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner_admin.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner_admin.LoginRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner_admin.LoginRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner_admin.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner_admin.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.partner.web_partner_admin.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner_admin.LoginRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner_admin.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner_admin.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_AuthService_Logout = new grpc.web.MethodDescriptor(
  '/partner.web_partner_admin.AuthService/Logout',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner_admin.AuthServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner_admin.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner_admin.AuthServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner_admin.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout);
};


module.exports = proto.partner.web_partner_admin;

