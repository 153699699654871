'use client';
import { IOfficeInfo, IUserInfo } from '@tixlabs/index';

import { useState } from 'react';
import { useAppMutation } from '../internals';
import {
  IListMyOfficesResReq,
  partnerService,
} from '@tixlabs/grpc-client/web-partnership';

const useListMyOffices = () => {
  const [listOffices, setListOffices] = useState<IOfficeInfo[]>([]);
  const { mutateAsync: getListPartnerUser } = useAppMutation({
    mutationKey: ['partnerService', 'listMyOffices'],
    mutationFn: partnerService.listMyOffices,
    onSuccess: (data) => {
      if (data?.itemsList) {
        setListOffices([...data.itemsList]);
      }
    },
  });

  const handleGetListOffices = async (req: IListMyOfficesResReq) => {
    await getListPartnerUser({
      pagination: req.pagination,
      filter: req.filter,
    });
  };

  return {
    listOffices,
    handleGetListOffices,
  };
};

export default useListMyOffices;
