import { IAirlineHub } from '@tixlabs/grpc-client';
import { flightApiService } from '@tixlabs/grpc-client/web-partner-admin';
import { useState } from 'react';
import { useAppMutation } from '../internals';

export function useSearchAirlines() {
  const [airlinesList, setAirlinesList] = useState<IAirlineHub[]>([]);
  
  const {
    mutate: getAirlines,
    isLoading,
    isError,
    isSuccess,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'searchAirlines'],
    mutationFn: flightApiService.searchAirlines,
    onSuccess: (data) => {
      if (data.isSuccess && data.data) {
        setAirlinesList(data.data.itemsList);
      }
    },
  });

  function handleSearch(throttleQuery: string) {
    getAirlines({
      filter: { text: throttleQuery },
      pagination: {
        pageLimit: 100,
        pageNumber: 1,
      },
    });
  }

  return {
    airlinesList,
    isLoading,
    isError,
    isSuccess,
    handleSearch,
  };
}

export default useSearchAirlines;
