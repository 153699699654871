import { Card, GroupFillIcon } from '@common-ui';
import { IBookingPassenger } from '@tixlabs/grpc-client/web-partner';
import { useCountry } from '@web-admin/hooks/apps';
import { useTime } from '@web-admin/hooks/internals';
import { GENDER_TYPE_LABEL, PAX_TYPE_LABEL } from '@web-admin/utils';

type Props = {
  //
  data: IBookingPassenger[];
};

const ColItem = ({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) => {
  return (
    <div className='flex flex-col flex-auto'>
      <span className='text-neutral-8'>{label}</span>
      <span className='font-semibold text-neutral-10'>{value || 'N/A'}</span>
    </div>
  );
};
const PassengerInfo = ({
  data,
  orderNumber,
}: {
  data: IBookingPassenger;
  orderNumber: number;
}) => {
  const { formatDate } = useTime();
  const { countriesObjectByCode } = useCountry();
  return (
    <div className='bg-white space-y-1.5 px-3 py-1.5 rounded'>
      <div className='flex space-x-2.5 items-center'>
        <div className='flex justify-center items-center rounded-full bg-primary-6 w-6 h-6 text-white'>
          {orderNumber}
        </div>
        <div className='font-semibold text-neutral-10'>
          {data.lastName} {data.firstName}
        </div>
      </div>
      <div className='flex pl-[34px]'>
        <ColItem label='Loại hành khách' value={PAX_TYPE_LABEL[data.type]} />
        <ColItem label='Giới tính' value={GENDER_TYPE_LABEL[data.gender]} />
        <ColItem label='Ngày sinh' value={data.dob && formatDate(data.dob)} />
        <ColItem label='Số hộ chiếu' value={data.passportNumber} />
        <ColItem
          label='Ngày hết hạn'
          value={data.expirationDate && formatDate(data.expirationDate)}
        />
        <ColItem
          label='Quốc tịch'
          value={
            data.nationality && countriesObjectByCode[data.nationality]?.country
          }
        />
        <ColItem
          label='Nơi cấp'
          value={
            data.idIssueCountry &&
            countriesObjectByCode[data.idIssueCountry]?.country
          }
        />
      </div>
    </div>
  );
};

export const Passengers = ({ data }: Props) => {
  return (
    <Card
      title='Hành khách'
      icon={<GroupFillIcon className='w-4 h-4 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='bg-neutral-4 p-3 mt-1.5 rounded space-y-3'>
      {data.map((item, index) => (
        <PassengerInfo data={item} key={index} orderNumber={index + 1} />
      ))}
    </Card>
  );
};

export default Passengers;
