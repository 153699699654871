import { getVICountries } from '@tixlabs/axios-client';
import { ICountry, SelectOptionItem } from '@tixlabs/types';
import { useAppDispatch, useAppSelector } from '@sky-booking-config/stores';
import { updateCountriesVI } from '@sky-booking-config/stores/reducers/common';
import { useEffect, useMemo } from 'react';

function getPhoneCodeValue(value: string): {
  phoneCode?: string;
  countryCode?: string;
} {
  const [phoneCode, countryCode] = value.split('.');
  return { phoneCode, countryCode };
}

export function useVICountry() {
  const { countriesVI = [], countriesObjectByCode = {} } = useAppSelector(
    (state) => state.common
  );
  const dispatch = useAppDispatch();

  async function fetchCountries() {
    try {
      const { data } = await getVICountries();
      const baseURL = process.env.NX_STORAGE_ASSETS;
      if (data && data.data.list) {
        console.log('data.data.list');
        dispatch(
          updateCountriesVI(
            data.data.list.map((item) => ({
              ...item,
              flagUrl: `${baseURL}/country-flags/${item.countryCode}.svg`,
            }))
          )
        );
      }
    } catch (error) {}
  }

  function formatPhoneCodeValue(value: string) {
    const searchCountry = countriesVI.find((c) => {
      if (value.startsWith('+')) {
        return c.dialingCode === `${value}`;
      }
      return c.dialingCode === `+${value}` || c.countryCode === value;
    });

    return searchCountry
      ? `${searchCountry?.dialingCode}.${searchCountry.countryCode}`
      : '';
  }

  const countryOptions = useMemo<SelectOptionItem[]>(
    () =>
      countriesVI?.map((c: ICountry) => ({
        value: c.dialingCode,
        label: c.dialingCode,
        code: c.countryCode,
      })) || [],
    [countriesVI]
  );

  const countryNameOptions = useMemo<SelectOptionItem[]>(
    () =>
      countriesVI?.map((c) => ({
        value: c.country,
        label: c.country,
        code: c.countryCode,
      })) || [],
    [countriesVI]
  );

  const countrySelectOptions = useMemo<SelectOptionItem[]>(
    () =>
      countriesVI?.map((c) => ({
        value: c.countryCode,
        label: c.country,
      })) || [],
    [countriesVI]
  );

  const phoneCodeOptions = useMemo<SelectOptionItem[]>(
    () =>
      countriesVI?.map((c) => ({
        value: `${c.dialingCode}.${c.countryCode}`,
        label: c.country,
        displayValue: c.dialingCode,
        countryCode: c.countryCode,
      })) || [],
    [countriesVI]
  );

  useEffect(() => {
    if (!countriesVI?.length) {
      fetchCountries();
    }
  }, []);

  return {
    countriesVI,
    countryOptions,
    countryNameOptions,
    countrySelectOptions,
    phoneCodeOptions,
    countriesObjectByCode,
    formatPhoneCodeValue,
    getPhoneCodeValue,
  };
}

export default useVICountry;
