import { FlightItineraryType } from '@api/airplane/base_pb';
import { getApiErrorMessages } from '@core/utils';
import { EFlightItineraryType, IFlightHiddenFee } from '@tixlabs/grpc-client';
import {
  IAddFlightHiddenServiceFeeReq,
  IUpdateFlightHiddenServiceFeeReq,
  hiddenFeeService,
  serviceConfigApi,
} from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppMutation, useToast } from '../internals';

type TFormFilter = {
  officeId: string;
  airlineCode: string;
  type: EFlightItineraryType;
  route: string;
  percent: number;
  bookingClassList: string[];
};

type TPagination = {
  pageCurrent: number;
  totalPage: number;
  totalRecord: number;
  pageLimit: number;
};

export const useConfigHiddenFee = () => {
  const toast = useToast();
  const methods = useForm<TFormFilter>({
    defaultValues: {
      officeId: '',
      airlineCode: '',
      type: EFlightItineraryType.INONE,
    },
  });

  const [officeIdOptionList, setOfficeIdOptionList] = useState<
    SelectOptionItem<string>[]
  >([]);

  const [listHiddenFee, setListHiddenFee] = useState<IFlightHiddenFee[]>([]);

  const [pagination, setPagination] = useState<TPagination>({
    pageCurrent: 1,
    totalPage: 0,
    totalRecord: 0,
    pageLimit: 20,
  });

  const { mutateAsync: getListOffice } = useAppMutation({
    mutationKey: ['partnerWebServiceClient', 'getOffices'],
    mutationFn: serviceConfigApi.getListOffice,
  });

  const {
    mutateAsync: listFlightHiddenServiceFee,
    isLoading: isLoadingHiddenFee,
  } = useAppMutation({
    mutationKey: ['hiddenFeeService', 'listFlightHiddenServiceFee'],
    mutationFn: hiddenFeeService?.listFlightHiddenServiceFee,
  });

  const {
    mutateAsync: updateFlightHiddenServiceFee,
    isLoading: isLoadingUpdateHiddenFee,
  } = useAppMutation({
    mutationKey: ['hiddenFeeService', 'updateFlightHiddenServiceFee'],
    mutationFn: hiddenFeeService?.updateFlightHiddenServiceFee,
  });

  const {
    mutateAsync: addFlightHiddenServiceFee,
    isLoading: isLoadingAddHiddenFee,
  } = useAppMutation({
    mutationKey: ['hiddenFeeService', 'addFlightHiddenServiceFee'],
    mutationFn: hiddenFeeService?.addFlightHiddenServiceFee,
  });

  const {
    mutateAsync: deleteFlightHiddenServiceFee,
    isLoading: isLoadingDeleteHiddenFee,
  } = useAppMutation({
    mutationKey: ['hiddenFeeService', 'deleteFlightHiddenServiceFee'],
    mutationFn: hiddenFeeService?.deleteFlightHiddenServiceFee,
  });

  const getOfficeIdList = async () => {
    try {
      const { dataList } = await getListOffice({});
      if (dataList.length > 0) {
        const mappedList = dataList.map<SelectOptionItem<string>>((item) => {
          return {
            label: item.code + ' - ' + item.officeName,
            value: item.officeId,
          };
        });
        mappedList.unshift({ label: 'Mặc định', value: '' });
        setOfficeIdOptionList(mappedList);
        methods.setValue('officeId', mappedList[0].value);
      }
    } catch (error) {
      console.log('getOfficeIdList: ', error);
    }
  };

  async function onChangeOfficeId(officeId: string) {
    methods.setValue('airlineCode', '');
    methods.setValue('type', EFlightItineraryType.INONE);
    methods.handleSubmit((data) =>
      getListFlightHiddenFee(data, {
        pageCurrent: 1,
        totalPage: 0,
        totalRecord: 0,
        pageLimit: 20,
      })
    )();
  }

  const getListFlightHiddenFee = async (
    data: TFormFilter,
    paginationOption?: TPagination
  ) => {
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listFlightHiddenServiceFee({
        filter: data,
        pagination: {
          pageNumber: paginationOption
            ? paginationOption.pageCurrent
            : pagination.pageCurrent,
          pageLimit: paginationOption
            ? paginationOption.pageLimit
            : pagination.pageLimit,
        },
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      setListHiddenFee(itemsList);
      setPagination({
        pageCurrent: paginationRes?.pageCurrent || 1,
        totalPage: paginationRes?.totalPage || 1,
        totalRecord: paginationRes?.totalRecord || 1,
        pageLimit: paginationRes?.pageLimit || 1,
      });
    } catch (error) {
      toast.error('Lỗi tìm kiếm');
    }
  };

  const reFetchList = async () => {
    try {
      await getListFlightHiddenFee({
        airlineCode: methods.getValues('airlineCode'),
        type: methods.getValues('type'),
        officeId: methods.getValues('officeId'),
        route: methods.getValues('route'),
        bookingClassList: methods.getValues('bookingClassList'),
        percent: methods.getValues('percent'),
      });
    } catch (error) {}
  };

  const onChangePage = async (pageNumber: number) => {
    try {
      setPagination((prev) => ({
        ...prev,
        pageCurrent: pageNumber,
      }));

      await getListFlightHiddenFee(
        {
          airlineCode: methods.getValues('airlineCode'),
          type: methods.getValues('type'),
          officeId: methods.getValues('officeId'),
          route: methods.getValues('route'),
          bookingClassList: methods.getValues('bookingClassList'),
          percent: methods.getValues('percent'),
        },
        {
          ...pagination,
          pageCurrent: pageNumber,
        }
      );
    } catch (error) {
      console.log('Error changing page:', error);
    }
  };

  const handleUpdateFlightHiddenFee = async (
    {
      id,
      type,
      amount,
      airlineCode,
      route,
      bookingClass,
      percent,
    }: Omit<IUpdateFlightHiddenServiceFeeReq, 'officeId' | 'vat'>,
    handleSuccess: () => void,
    handleError: (errorCode: string) => void
  ) => {
    try {
      const { errorCode, isSuccess } = await updateFlightHiddenServiceFee({
        amount,
        id,
        type,
        vat: 0,
        airlineCode,
        route,
        bookingClass,
        percent,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      await reFetchList();
      handleSuccess();
    } catch (error) {
      const errMessage = getApiErrorMessages(error)[0];
      handleError(errMessage);
    }
  };

  const handleDeleteFlightHiddenFee = async (
    { id }: { id: string },
    handleSuccess: () => void,
    handleError: (errorCode: string) => void
  ) => {
    try {
      const { errorCode, isSuccess } = await deleteFlightHiddenServiceFee({
        id,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      handleSuccess();
      await reFetchList();
    } catch (error) {
      const errMessage = getApiErrorMessages(error)[0];
      handleError(errMessage);
    }
  };

  const handleAddFlightHiddenFee = async (
    {
      type,
      amount,
      airlineCode,
      route,
      bookingClassList,
      percent,
    }: Omit<IAddFlightHiddenServiceFeeReq, 'officeId' | 'vat'>,
    handleSuccess: () => void,
    handleError: (errorCode: string) => void
  ) => {
    try {
      const { errorCode, isSuccess } = await addFlightHiddenServiceFee({
        amount,
        type,
        vat: 0,
        airlineCode,
        officeId: methods.getValues('officeId'),
        route,
        bookingClassList,
        percent,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      await reFetchList();
      handleSuccess();
    } catch (error) {
      const errMessage = getApiErrorMessages(error)[0];
      handleError(errMessage);
    }
  };

  return {
    pagination,
    officeIdOptionList,
    isLoadingHiddenFee,
    isLoadingAddHiddenFee,
    isLoadingDeleteHiddenFee,
    isLoadingUpdateHiddenFee,
    filterFormMethods: methods,
    listHiddenFee: listHiddenFee || [],

    onChangePage,
    getOfficeIdList,
    onChangeOfficeId,
    getListFlightHiddenFee,
    handleUpdateFlightHiddenFee,
    handleAddFlightHiddenFee,
    handleDeleteFlightHiddenFee,
  };
};

export default useConfigHiddenFee;
