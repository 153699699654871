import { getApiErrorMessages } from '@core/utils';
import { useAppMutation, useToast } from '@sky-booking-config/hooks/internals';
import { EUserStatusPartner, IOfficeInfo } from '@tixlabs/grpc-client';
import {
  ICreateOfficeReq,
  IUpdateOfficeProvidersReq,
  IUpdateOfficeReq,
  IUpdateUserV2Req,
  IUserInfo,
  partnerService,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export type TCreateOfficeIdForm = ICreateOfficeReq & {
  domain: number;
};
export type TUpdateOfficeIdForm = IUpdateOfficeReq & {
  userName: string;
  domain: number;
};

export const useCreateAndUpdateOfficeId = () => {
  const toast = useToast();
  const navigate = useNavigate();

  // null is for idle, if not found => undefined
  const [officeIdInfo, setOfficeIdInfo] = useState<
    IOfficeInfo | undefined | null
  >(null);

  const createOfficeIdMethod = useForm<TCreateOfficeIdForm>({
    defaultValues: {
      email: '',
      enabledProvidersList: [],
      name: '',
      password: '',
      phone: '',
      shopName: '',
      status: EUserStatusPartner.ACTIVE,
      userName: '',
      domain: 0,
    },
    mode: 'onBlur',
  });

  const updateOfficeIdMethod = useForm<TUpdateOfficeIdForm>({
    defaultValues: {
      email: '',
      id: '',
      name: '',
      phone: '',
      shopName: '',
      status: EUserStatusPartner.ACTIVE,
      userName: '',
      domain: 0,
    },
    mode: 'onBlur',
  });
  const updateProviderMethod = useForm<IUpdateOfficeProvidersReq>({
    defaultValues: {
      id: '',
      enabledProvidersList: [],
    },
  });

  const [domainListOption, setDomainListOption] = useState<SelectOptionItem[]>(
    []
  );

  // const [providerListOption, setProviderListOption] = useState<
  //   SelectOptionItem[]
  // >([]);

  const [providerOfficeCanUpdate, setProvidersOfficeCanUpdate] = useState<
    SelectOptionItem[]
  >([]);
  const [allProviders, setAllProviderOption] = useState<SelectOptionItem[]>([]);

  const [officeIdListOption, setOfficeIdListOption] = useState<
    SelectOptionItem[]
  >([]);

  const { mutateAsync: createOffice, isLoading: isLoadingCreateOffice } =
    useAppMutation({
      mutationKey: ['partnerService', 'createOffice'],
      mutationFn: partnerService.createOffice,
    });

  const { mutateAsync: getOfficeDetail, isLoading: isLoadingGetOfficeDetail } =
    useAppMutation({
      mutationKey: ['partnerService', 'getOfficeDetail'],
      mutationFn: partnerService.getOfficeDetail,
    });

  const { mutateAsync: updateOffice, isLoading: isLoadingUpdateOffice } =
    useAppMutation({
      mutationKey: ['partnerService', 'updateOffice'],
      mutationFn: partnerService.updateOffice,
    });

  const { mutateAsync: getDomains, isLoading: isLoadingDomains } =
    useAppMutation({
      mutationKey: ['partnerService', 'getDomains'],
      mutationFn: partnerService.getDomains,
    });

  const { mutateAsync: getProviders, isLoading: isLoadingProviders } =
    useAppMutation({
      mutationKey: ['partnerService', 'getProviders'],
      mutationFn: partnerService.getProviders,
    });

  const { mutateAsync: getUnassignedOffices, isLoading: isLoadingOfficeIds } =
    useAppMutation({
      mutationKey: ['partnerService', 'getUnassignedOffices'],
      mutationFn: partnerService.getUnassignedOffices,
    });

  const {
    mutateAsync: updateOfficeProviders,
    isLoading: isLoadingUpdateOfficeProviders,
  } = useAppMutation({
    mutationKey: ['partnerService', 'updateOfficeProviders'],
    mutationFn: partnerService.updateOfficeProviders,
  });

  const {
    mutateAsync: changeOfficePassword,
    isLoading: isLoadingChangeOfficePassword,
  } = useAppMutation({
    mutationKey: ['partnerService', 'changeOfficePassword'],
    mutationFn: partnerService.changeOfficePassword,
  });

  const getOfficeDetailInfo = async (id: string) => {
    try {
      const { isSuccess, errorCode, data } = await getOfficeDetail({ id });
      if (!isSuccess && errorCode) {
      }
      setOfficeIdInfo(data);

      // const listOption = data?.providerConfigsList
      //   .map<SelectOptionItem>((item) => {
      //     return { label: item.name, value: item.value, enabled: item.enabled };
      //   });
      //   console.log('listOption',listOption)
      // setProviderListOption(listOption || []);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get office info:', msg);
    }
  };

  const getDomainListOption = async () => {
    try {
      const { itemsList } = await getDomains({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setDomainListOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get domain:', msg);
    }
  };

  const getAllProviderOption = async () => {
    try {
      const { itemsList } = await getProviders({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setAllProviderOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get provider:', msg);
    }
  };

  const getOfficeIdListOption = async () => {
    try {
      const { itemsList } = await getUnassignedOffices({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setOfficeIdListOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get office:', msg);
    }
  };

  const handleSubmitCreateOfficeId = async (formData: TCreateOfficeIdForm) => {
    try {
      const { errorCode, isSuccess } = await createOffice(formData);
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Tạo user thành công!');
      createOfficeIdMethod.reset();
      navigate('/dashboard/agents');
    } catch (error) {
      const errCode = getApiErrorMessages(error)[0];
      switch (errCode) {
        case 'PARTNER_USER_NAME_IS_EXISTS':
          createOfficeIdMethod.setError('userName', {
            message: 'Tên tài khoản đã tồn tại!',
          });
          break;
        case 'INVALID_INPUT':
          toast.error('Thông tin user không hợp lệ!');
          break;
        default:
          toast.error('Lỗi tạo officeId!');
          break;
      }
    }
  };

  const handleSubmitUpdateOfficeId = async (formData: TUpdateOfficeIdForm) => {
    try {
      const { isSuccess, errorCode } = await updateOffice({ ...formData });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật thông tin thành công!');
      // navigate('/dashboard/agents');
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      switch (msg) {
        case 'INVALID_INPUT':
          toast.error('Thông tin Agent không hợp lệ!');
          break;
        default:
          toast.error('Lỗi cập nhật Agent!');
          break;
      }
    }
  };

  const handleChangePassword = async (
    newPassword: string,
    id: string,
    shopId: string
  ) => {
    return await changeOfficePassword({
      //! Owner id
      userId: id || officeIdInfo?.id || '',
      newPassword,
      shopId: shopId,
    });
  };

  const handleUpdateOfficeProviders = async ({
    enabledProvidersList,
    id,
  }: IUpdateOfficeProvidersReq) => {
    return await updateOfficeProviders({
      enabledProvidersList,
      id,
    });
  };

  return {
    officeIdInfo,
    createOfficeIdMethod,
    updateOfficeIdMethod,
    isLoadingGetOfficeDetail,
    isLoadingChangeOfficePassword,
    isLoadingCreateOffice,
    isLoadingUpdateOffice,
    isLoadingUpdateOfficeProviders,
    isLoadingOption:
      isLoadingDomains || isLoadingProviders || isLoadingOfficeIds,
    domainListOption,
    // providerListOption,
    officeIdListOption,
    allProviders,
    updateProviderMethod,
    handleChangePassword,
    handleSubmitUpdateOfficeId,
    handleSubmitCreateOfficeId,
    handleUpdateOfficeProviders,
    getOfficeDetailInfo,
    getDomainListOption,
    getAllProviderOption,
    getOfficeIdListOption,
  };
};

export default useCreateAndUpdateOfficeId;
