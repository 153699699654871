import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  User3FillIcon,
} from '@common-ui';
import {
  PartnerUserStatusListCreateOptions,
  patternValidateEmail,
  patternValidatePhoneNumber,
} from '@sky-booking-config/utils';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCreateAndUpdateIssuer from '../useCreateAndUpdateIssuer';
import ModalChangePasswordIssuer, {
  TModalInfo,
} from './ModalChangePasswordIssuer';
import { UserStatus } from '@api/partner/base_pb';
import { EUserStatusPartner } from '@tixlabs/grpc-client';

type Props = {
  //
};

export const UpdateIssuerCtn = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState<TModalInfo>({
    id: '',
    status: UserStatus.NONE,
  });
  const {
    issuerInfo,
    isLoadingUpdateIssuer,
    updateIssuerMethod,
    getPartnerUserInfo,
    handleSubmitUpdateIssuer,
  } = useCreateAndUpdateIssuer();

  const handleOpenModalChangePassword = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setModalInfo({
      id: issuerInfo?.id || '',
      status: issuerInfo?.status || UserStatus.INACTIVE,
    });
    updateIssuerMethod.setValue('name', issuerInfo?.name || '');
    updateIssuerMethod.setValue('email', issuerInfo?.email || '');
    updateIssuerMethod.setValue('phone', issuerInfo?.phone || '');
    updateIssuerMethod.setValue(
      'status',
      issuerInfo?.status as EUserStatusPartner
    );
  }, [issuerInfo]);

  useEffect(() => {
    updateIssuerMethod.setValue('id', id || '');
    updateIssuerMethod.setValue('setIsModalOpen', setIsModalOpen);
    getPartnerUserInfo(id || '');
  }, []);

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <User3FillIcon className='w-5 h-5 shrink-0' />
        <div className='font-semibold text-lg'>Chỉnh sửa người dùng</div>
      </div>
      <div className='max-w-[1100px]'></div>
      <Form
        methods={updateIssuerMethod}
        onSubmit={handleSubmitUpdateIssuer}
        className='max-w-[1100px] flex flex-col gap-y-5'>
        <div className='flex flex-col gap-y-5 p-4 border rounded'>
          <div className='font-semibold text-lg'>
            Thông tin tài khoản
            <hr />
          </div>
          <div className='flex gap-x-5'>
            <div className='w-52'>
              <FormInput
                name='username'
                label='Tên đăng nhập'
                value={issuerInfo?.userName}
                inputProps={{
                  disabled: true,
                }}
              />
            </div>
            <div className='w-52 flex flex-col justify-end'>
              <Button
                variant='solid'
                theme='green'
                className='bg-slate-500'
                onClick={() => {
                  handleOpenModalChangePassword();
                }}>
                Đổi mật khẩu
              </Button>
            </div>
          </div>
          <div className='w-1/3'>
            <FormGroupRadio
              name='status'
              label='Trạng thái'
              className='flex'
              radioOptions={PartnerUserStatusListCreateOptions}
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-2 p-4 border rounded'>
          <span className='text-lg font-semibold'>
            Thông tin liên hệ
            <hr />
          </span>
          <div className='flex flex-col gap-y-5'>
            <div className='flex gap-x-5'>
              <div className='w-1/3'>
                <FormInput
                  name='name'
                  label='Tên liên hệ'
                  placeholder='Nhập liên hệ'
                  rules={{
                    onChange(event) {
                      updateIssuerMethod.clearErrors('name');
                    },
                  }}
                  inputProps={{
                    defaultValue: issuerInfo?.name,
                  }}
                />
              </div>
              <div className='w-1/3'>
                <FormInput
                  name='email'
                  label='Email'
                  placeholder='Nhập email'
                  rules={{
                    validate: {
                      emailPattern: (value: string) => {
                        if (value.length > 0) {
                          return (
                            value.match(patternValidateEmail) ||
                            'Email không hợp lệ'
                          );
                        } else {
                          return true;
                        }
                      },
                    },
                    onChange(event) {
                      updateIssuerMethod.clearErrors('email');
                    },
                  }}
                  inputProps={{
                    defaultValue: issuerInfo?.email,
                  }}
                />
              </div>
              <div className='w-1/3'>
                <FormInput
                  name='phone'
                  label='Số điện thoại'
                  placeholder='Nhập điện thoại'
                  rules={{
                    // required: 'Nhập diện thoại',
                    validate: {
                      phonePattern: (value: string) => {
                        if (value.length > 0) {
                          return (
                            value.match(patternValidatePhoneNumber) ||
                            'Số điện thoại không hợp lệ'
                          );
                        } else {
                          return true;
                        }
                      },
                    },
                    onChange(event) {
                      updateIssuerMethod.clearErrors('phone');
                    },
                  }}
                  inputProps={{
                    defaultValue: issuerInfo?.phone,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-end gap-x-5'>
          <Button
            variant='solid'
            theme='red'
            className='w-[150px]'
            onClick={() => {
              // Go back router
              navigate(-1);
            }}>
            Hủy
          </Button>
          <ButtonLoading
            loading={isLoadingUpdateIssuer}
            isShowChildWhenLoading
            className='w-[150px]'
            theme='green'
            type='submit'>
            Cập nhật
          </ButtonLoading>
        </div>
      </Form>
      <ModalChangePasswordIssuer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalInfo={modalInfo}
      />
    </div>
  );
};

export default UpdateIssuerCtn;
