/**
 * @fileoverview gRPC-Web generated client stub for skyhub.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: skyhub/web_partnership/booking.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var base_enum_pb = require('../../base/enum_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var skyhub_flight_pb = require('../../skyhub/flight_pb.js')

var skyhub_base_pb = require('../../skyhub/base_pb.js')
const proto = {};
proto.skyhub = {};
proto.skyhub.web_partnership = require('./booking_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.BookingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.BookingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.GetUpcomingBookingsReq,
 *   !proto.skyhub.web_partnership.GetUpcomingBookingsRes>}
 */
const methodDescriptor_BookingService_GetUpcomingBookings = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.BookingService/GetUpcomingBookings',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.GetUpcomingBookingsReq,
  proto.skyhub.web_partnership.GetUpcomingBookingsRes,
  /**
   * @param {!proto.skyhub.web_partnership.GetUpcomingBookingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.GetUpcomingBookingsRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.GetUpcomingBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.GetUpcomingBookingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.GetUpcomingBookingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.BookingServiceClient.prototype.getUpcomingBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.BookingService/GetUpcomingBookings',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetUpcomingBookings,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.GetUpcomingBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.GetUpcomingBookingsRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.BookingServicePromiseClient.prototype.getUpcomingBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.BookingService/GetUpcomingBookings',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetUpcomingBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.GetBookingInfoReq,
 *   !proto.skyhub.web_partnership.GetBookingInfoRes>}
 */
const methodDescriptor_BookingService_GetBookingInfo = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.BookingService/GetBookingInfo',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.GetBookingInfoReq,
  proto.skyhub.web_partnership.GetBookingInfoRes,
  /**
   * @param {!proto.skyhub.web_partnership.GetBookingInfoReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.GetBookingInfoRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.GetBookingInfoReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.GetBookingInfoRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.GetBookingInfoRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.BookingServiceClient.prototype.getBookingInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.BookingService/GetBookingInfo',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingInfo,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.GetBookingInfoReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.GetBookingInfoRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.BookingServicePromiseClient.prototype.getBookingInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.BookingService/GetBookingInfo',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingInfo);
};


module.exports = proto.skyhub.web_partnership;

