import React from 'react';
import { useTime } from '@sky-booking-config/hooks/internals';
import useNotification from './useNotification';
import { Attachment } from '@icon';
import { IBookingNotification } from '@tixlabs/grpc-client';

interface Props {
  infoEmail: IBookingNotification | undefined;
}

const ModalContentNotify = ({ infoEmail }: Props) => {
  const { formatDateTime } = useTime();
  const { NotificationMethods } = useNotification();
  return (
    <div className='mb-4'>
      <hr className='mb-4' />
      <div className='grid grid-cols-4 gap-4 text-md p-2'>
        <div>
          <p className='font-semibold'>Thời gian</p>
          <p>{formatDateTime(infoEmail?.createdAt)}</p>
        </div>
        <div>
          <p className='font-semibold'>Người gửi</p>
          <p>{infoEmail?.actorName}</p>
        </div>
        <div>
          <p className='font-semibold'>Gửi qua</p>
          <p>{infoEmail && NotificationMethods[infoEmail?.method]}</p>
        </div>

        <div>
          <p className='font-semibold'>Trạng thái</p>
          <p>
            {infoEmail?.status === 1
              ? `Thành công`
              : infoEmail?.status === 2
              ? 'Lỗi'
              : ``}
          </p>
        </div>

        <span></span>
      </div>

      <hr />
      <div className='font-semibold leading-[38px] text-lg/4 p-2'>
        {infoEmail?.title}
      </div>
      <hr />
      <div>
        {infoEmail?.attachmentsList.map((item, i) => (
          <div key={i} className='p-2 flex gap-2 mt-2 items-center'>
            <Attachment className='w-4 h-4 text-[#999999]' />
            <a
              rel='noreferrer'
              target='_blank'
              href={item?.url}
              className='text-blue-600'>
              {item?.name}
            </a>
          </div>
        ))}
      </div>
      <div
        className='text-base p-2 min-h-40 h-auto prose'
        dangerouslySetInnerHTML={{
          __html: infoEmail?.content ? infoEmail?.content : '',
        }}></div>
    </div>
  );
};

export default ModalContentNotify;
