import { useEffect } from 'react';

import { STORAGE_ASSETS } from '@core/constants';
import { IAirline, LANGUAGES_CODE } from '@tixlabs/types';
import { useAppDispatch, useAppSelector } from '@sky-booking-config/stores';
import { fetchAirlines } from '@sky-booking-config/stores/reducers/common';
import { useAppLanguage } from '../internals/useAppLanguage';

export function useAirlines() {
  const { language } = useAppLanguage();

  const airlines = useAppSelector((state) => state.common || []);
  const airlinesObjectByCode = useAppSelector(
    (state) => state.common.airlinesObjectByCode
  );
  const loadingAirlines = useAppSelector(
    (state) => state.common.loadingAirlines
  );
  const dispatch = useAppDispatch();

  function getAirlineByCode(code: string): Partial<IAirline> | undefined {
    const result = { ...airlinesObjectByCode?.[code] };

    if (result) {
      result.logoUrl = `${STORAGE_ASSETS}/airlines/logo/${result.code}.gif`;
    }

    return result;
  }

  function getAirlineNameByCode(code: string): string {
    const findAirline = getAirlineByCode(code);

    if (!findAirline || !findAirline.name) {
      return code;
    }

    let nameByLanguage = findAirline.name[language];

    if (nameByLanguage) {
      return nameByLanguage;
    }

    // Switch if don't have value
    if (language === LANGUAGES_CODE.ENGLISH) {
      nameByLanguage = findAirline.name[LANGUAGES_CODE.VIETNAM];
    }

    if (language === LANGUAGES_CODE.VIETNAM) {
      nameByLanguage = findAirline.name[LANGUAGES_CODE.ENGLISH];
    }

    return nameByLanguage || code;
  }

  useEffect(() => {
    if (loadingAirlines === 'idle') {
      dispatch(fetchAirlines());
    }
  }, [loadingAirlines]);

  return {
    airlines,
    airlinesObjectByCode,
    getAirlineByCode,
    getAirlineNameByCode,
  };
}

export default useAirlines;
