import {
  Button,
  ButtonLoading,
  Form,
  FormInput,
  FormSelect,
  SearchLineIcon,
  User3FillIcon,
  UserAddFillIcon,
} from '@common-ui';
import { useAppMutation } from '@payment-portal/hooks/internals';
import {
  DEFAULT_PAGE_LIMIT,
  SELECT_PARTNERSHIP_STATUS_OPTIONS,
  SELECT_ROLELIST_OPTIONS_FILTER,
} from '@payment-portal/utils';
import { EUserStatus, IPaginationRes } from '@tixlabs/grpc-client';
import {
  IGetUsersReq,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';

import { EPartnershipRole } from '@payment-portal/utils/constants/permission';
import { IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TableContent } from './components/TableContent';

type Props = {
  //
};

const DEFAULT_USER_FILTER: Omit<IGetUsersReq, 'roleList'> & {
  roleList: string;
} = {
  status: EUserStatus.USER_STATUS_INVALID,
  name: '',
  roleList: EPartnershipRole.ALL,
  username: '',
  pagination: {
    pageLimit: DEFAULT_PAGE_LIMIT,
    pageNumber: 1,
  },
};

export const ListUserCtn = (props: Props) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: DEFAULT_USER_FILTER,
  });
  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });
  const [filterReq, setFilterReq] = useState<
    Omit<IGetUsersReq, 'roleList'> & {
      roleList: string;
    }
  >(DEFAULT_USER_FILTER);
  const [listPartner, setListPartner] = useState<IUserInfo[]>([]);
  const { mutateAsync: getUsersV2, isLoading: isloadingGetUsersV2 } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'getUsersV2'],
      mutationFn: partnerUserServiceClient.getUsersV2,
      onSuccess: ({ isSuccess, data, errorCode }) => {
        if (isSuccess && data) {
          data.pagination && setPaginationRes(data.pagination);
          setListPartner(data.itemsList);
        }
      },
    });

  const handleSubmit = async (
    data: Omit<IGetUsersReq, 'roleList'> & {
      roleList: string;
    }
  ) => {
    setFilterReq({
      ...data,
      roleList: data.roleList,
    });
    await getUsersV2({
      ...data,
      roleList: data.roleList.split(','),
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  };

  const handlePagination = async (page: number) => {
    await getUsersV2({
      ...filterReq,
      roleList: filterReq.roleList.split(','),
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
    });
  };

  const getListUser = useCallback(async () => {
    await getUsersV2({
      ...DEFAULT_USER_FILTER,
      roleList: DEFAULT_USER_FILTER.roleList.split(','),
    });
  }, []);

  useEffect(() => {
    getListUser();
  }, []);

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <User3FillIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Quản lý người dùng</span>
      </div>
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4'>
          <div className='flex space-x-2.5'>
            <FormInput
              name='username'
              placeholder='Tìm tên đăng nhập'
              label='Tên đăng nhập'
            />
            <FormInput
              name='name'
              label='Tên thành viên'
              placeholder='Tìm tên thành viên'
            />
            <FormSelect
              name='status'
              selectOptions={SELECT_PARTNERSHIP_STATUS_OPTIONS}
              label={'Trạng thái'}
              inputProps={{
                isOnlyValue: true,
              }}
            />
            <FormSelect
              name='roleList'
              selectOptions={SELECT_ROLELIST_OPTIONS_FILTER}
              label={'Vai trò'}
              inputProps={{
                isOnlyValue: true,
              }}
            />
            <div className='flex flex-col justify-end'>
              <ButtonLoading
                type='submit'
                theme='secondary'
                isShowChildWhenLoading
                loading={isloadingGetUsersV2}
                prefixIcon={<SearchLineIcon />}
                className='font-semibold'>
                Tìm kiếm
              </ButtonLoading>
            </div>
          </div>
          <div className='flex items-end'>
            <Button
              theme='green'
              prefixIcon={<UserAddFillIcon className='w-4 h-4' />}
              onClick={() => {
                navigate(`/dashboard/add-user`);
              }}>
              Thêm người dùng
            </Button>
          </div>
        </Form>

        <TableContent
          isLoading={isloadingGetUsersV2}
          pagination={paginationRes}
          listPartnerUser={listPartner}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default ListUserCtn;
