import { AppLogicProvider } from '@sky-booking-config/containers';
import { Outlet } from 'react-router-dom';

type Props = {
  //
};

export function RootLayout({}: Props) {
  return (
    <AppLogicProvider isCheckAuth>
      <Outlet />
    </AppLogicProvider>
  );
}

export default RootLayout;
