import {
  Button,
  ButtonLoading,
  Form,
  FormInput,
  FormRadio,
  FormSelect,
  User3FillIcon,
} from '@common-ui';
import { validatePasswordByCase } from '@core/utils';
import ModalContent from '@payment-portal/components/Modal/ModalContent';
import { useAppMutation } from '@payment-portal/hooks/internals';
import { useErrorMessageData } from '@payment-portal/hooks/stores';
import { EModalMode } from '@payment-portal/types';
import { EModalCreateUser } from '@payment-portal/types/ui/modal';
import {
  SELECT_ROLELIST_OPTIONS,
  USER_PARTNER_STATUS_OPTIONS,
  patternValidateEmail,
  patternValidateNameBooker,
  patternValidatePhoneNumber,
  patternValidateUsername,
} from '@payment-portal/utils';
import { EPartnershipRole } from '@payment-portal/utils/constants/permission';
import { EUserStatus, EUserStatusPartner } from '@tixlabs/grpc-client/';
import {
  ICreateUserV2Req,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { Modal } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Props = {
  //
};

export const AddUser = (props: Props) => {
  const navigate = useNavigate();
  const methods = useForm<
    Omit<ICreateUserV2Req, 'roleList'> & { roleList: EPartnershipRole }
  >({
    defaultValues: {
      contactInfo: {
        email: '',
        name: '',
        phoneNumber: '',
      },
      password: '',
      roleList: EPartnershipRole.ACCOUNTANT,
      status: EUserStatus.ACTIVE,
      username: '',
    },
    mode: 'onBlur',
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { getErrorMessage } = useErrorMessageData();

  const [stateModal, setStateModal] = useState<EModalCreateUser>(
    EModalCreateUser.CONFIRM
  );

  const { mutateAsync: createUserV2, isLoading: isLoadingCreateUserV2 } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'createUserV2'],
      mutationFn: partnerUserServiceClient.createUserV2,
      onSuccess: ({ isSuccess, errorCode }) => {
        if (isSuccess) {
          setIsOpenModal(true);
          return;
        } else {
          // handle error/ call api get message error
          setErrorMsg(getErrorMessage(errorCode));
        }
      },
      onError: (error) => {
        // handle error when network/system error
        console.log('error', error);
      },
    });

  const handleSubmit = async (
    data: Omit<ICreateUserV2Req, 'roleList'> & { roleList: EPartnershipRole }
  ) => {
    data.status = +data.status;
    await createUserV2({
      ...data,
      roleList: [data.roleList],
    });
  };

  const handleOk = () => {
    setIsOpenModal(false);
    navigate('/dashboard/user-management/');
  };

  const genarateModalFooter = (
    state: EModalCreateUser,
    onClick: () => void
  ) => {
    const content = {
      [EModalCreateUser.CONFIRM]: (
        <div className='flex flex-col'>
          <Button onClick={onClick} className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
      [EModalCreateUser.CANCEL]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={() => {
              navigate('/dashboard/user-management/');
            }}>
            Huỷ bỏ
          </Button>
          <Button
            className='flex-1'
            onClick={() => {
              setIsOpenModal(false);
            }}>
            Tiếp tục thêm
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  const genarateModalContent = (state: EModalCreateUser) => {
    const content = {
      [EModalCreateUser.CONFIRM]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.SUCCESS}
          content='Thêm người dùng thành công'
        />
      ),
      [EModalCreateUser.CANCEL]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.CONFIRM}
          title='Huỷ bỏ thêm người dùng'
          content='Bạn đang hủy bỏ tác vụ thêm người dùng, bạn thực sự muốn hủy bỏ?'
        />
      ),
    };
    return content[state];
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        closeIcon={false}
        footer={genarateModalFooter(stateModal, handleOk)}
        centered={true}>
        {genarateModalContent(stateModal)}
      </Modal>
      <Form
        autoComplete='off'
        methods={methods}
        onSubmit={handleSubmit}
        className='flex flex-col space-y-3 max-w-[900px]'>
        <div className='flex flex-col space-y-5'>
          <div className='flex space-x-2.5 items-center'>
            <User3FillIcon className='w-5 h-5 shrink-0' />
            <span className='font-semibold text-lg'>Thêm người dùng</span>
          </div>
          <div className='flex flex-col space-y-5 bg-white border rounded p-4'>
            <div className='flex flex-col space-y-1.5'>
              <div className='flex flex-col space-y-2 5'>
                <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                  Thông tin tài khoản
                </h3>
                <div className='border-b border-neutral-5'></div>
              </div>
              <div className='grid grid-cols-3 items-baseline gap-2.5'>
                <FormInput
                  name='username'
                  placeholder='Nhập tên đăng nhập'
                  label='Tên đăng nhập'
                  rules={{
                    minLength: {
                      value: 8,
                      message: 'Tên đăng nhập ít nhất 8 kí tự',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Tên đăng nhập tối đa 20 kí tự',
                    },
                    pattern: {
                      value: patternValidateUsername,
                      message: 'Tên đăng nhập không hợp lệ',
                    },
                    required: 'Tên đăng nhập là bắt buộc.',
                    onChange(event) {
                      methods.clearErrors('username');
                    },
                  }}
                  inputProps={{
                    autoComplete: 'new-username',
                  }}
                />
                <FormInput
                  rules={{
                    required: 'Nhập mật khẩu mới',
                    minLength: {
                      value: 8,
                      message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
                    },
                    validate: {
                      checkValid: (v) => {
                        const { hasNumeric, hasCharacter } =
                          validatePasswordByCase(v);
                        return (
                          (hasNumeric && hasCharacter) ||
                          'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
                        );
                      },
                    },
                    onChange(event) {
                      methods.clearErrors('password');
                    },
                  }}
                  isShowError={true}
                  name='password'
                  label='Mật khẩu'
                  inputProps={{
                    type: 'password',
                    autoComplete: 'new-password',
                  }}
                  placeholder='Nhập mật khẩu'
                />
                <FormSelect
                  name='roleList'
                  label='Vai trò'
                  inputProps={{
                    isOnlyValue: true,
                  }}
                  selectOptions={SELECT_ROLELIST_OPTIONS}
                />
                <div className='self-stretch flex flex-col space-y-3 align-baseline'>
                  <label>Trạng thái</label>
                  <div className='flex items-center gap-5 pt-1.5'>
                    {USER_PARTNER_STATUS_OPTIONS.map((option) => {
                      return (
                        <FormRadio
                          key={option.value}
                          name='status'
                          value={option.value}
                          inputProps={{
                            defaultChecked:
                              option.value === EUserStatusPartner.ACTIVE,
                          }}
                          label={option.label}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-1.5'>
              <div className='flex flex-col space-y-2 5'>
                <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                  Thông tin liên hệ
                </h3>
                <div className='border-b border-neutral-5'></div>
              </div>
              <div className='flex items-baseline space-x-2.5'>
                <FormInput
                  rules={{
                    minLength: {
                      value: 1,
                      message: 'Tên thành viên ít nhất 1 kí tự',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Tên thành viên tối đa 20 kí tự',
                    },
                    pattern: {
                      value: patternValidateNameBooker,
                      message: 'Tên thành viên không hợp lệ',
                    },
                    required: 'Tên thành viên là bắt buộc.',
                    onChange(event) {
                      methods.clearErrors('contactInfo.name');
                    },
                  }}
                  name='contactInfo.name'
                  placeholder='Nhập tên thành viên'
                  label='Tên thành viên'
                />
                <FormInput
                  rules={{
                    // validate: {
                    //   emailPattern: (value: string) => {
                    //     if (value.length > 0) {
                    //       return (
                    //         value.match(patternValidateEmail) ||
                    //         'Email không hợp lệ'
                    //       );
                    //     } else {
                    //       return true;
                    //     }
                    //   },
                    // },
                    pattern: {
                      value: patternValidateEmail,
                      message: 'Email không hợp lệ',
                    },
                    onChange(event) {
                      methods.clearErrors('contactInfo.email');
                    },
                  }}
                  name='contactInfo.email'
                  label='Email'
                  placeholder='Nhập email'
                />
                <FormInput
                  rules={{
                    validate: {
                      // phonePattern: (value: string) => {
                      //   if (value.length > 0) {
                      //     return (
                      //       value.match(patternValidatePhoneNumber) ||
                      //       'Số điện thoại không hợp lệ'
                      //     );
                      //   } else {
                      //     return true;
                      //   }
                      // },
                    },
                    pattern: {
                      value: patternValidatePhoneNumber,
                      message: 'Số điện thoại không hợp lệ',
                    },
                    onChange(event) {
                      methods.clearErrors('contactInfo.phoneNumber');
                    },
                    maxLength: {
                      value: 13,
                      message: 'Số điện thoại tối đa 13 kí tự.',
                    },
                  }}
                  name='contactInfo.phoneNumber'
                  label='Số điện thoại'
                  placeholder='Nhập số điện thoại'
                />
              </div>
            </div>
            {errorMsg && <span className='text-red-5'>{errorMsg}</span>}
          </div>
        </div>
        <div className='flex flex-row items-center justify-end gap-4'>
          <Button
            theme='primary'
            variant='outline'
            onClick={() => {
              setStateModal(EModalCreateUser.CANCEL);
              setIsOpenModal(true);
            }}>
            Huỷ bỏ
          </Button>
          <ButtonLoading
            loading={isLoadingCreateUserV2}
            isShowChildWhenLoading
            theme='primary'
            type='submit'>
            Thêm người dùng
          </ButtonLoading>
        </div>
      </Form>
    </>
  );
};
