import React, { Dispatch, SetStateAction } from 'react';
import { Space, Table } from 'antd';
import { Button, CheckIconBlue, CheckIconGrey } from '@common-ui';
import { useNavigate } from 'react-router-dom';
import { BookingInfo } from '@api/skyhub/flight_pb';
import { useTime } from '@sky-booking-config/hooks/internals';
import { IPaginationRes } from '@tixlabs/grpc-client';

interface Props {
  data: BookingInfo.AsObject[];
  paginationRes: IPaginationRes;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const TableListFlight = ({
  data,
  paginationRes,
  setCurrentPage,
}: Props) => {
  const navigate = useNavigate();
  const { formatDateTimeGMT7, formatDateUTC, formatDateAAA } = useTime();

  const handleViewDetail = (item: BookingInfo.AsObject) => {
    navigate(`/dashboard/send-notification/${item?.bookingCode}`);
  };

  const handleChangePage = (pagination) => {
    setCurrentPage(pagination);
  };

  const columns = [
    {
      title: 'Giờ bay',
      key: 'filghtTime',
      render: (text) => {
        return (
          <span>{formatDateAAA(text?.itinerariesList[0]?.departDate)}</span>
        );
      },
    },
    {
      title: 'Giờ bay (giờ VN)',
      key: 'filghtTimeVN',
      render: (text) => {
        return (
          <span>
            {formatDateTimeGMT7(text?.itinerariesList[0]?.departDateUtc)}
          </span>
        );
      },
    },
    {
      title: 'Chuyến bay',
      key: 'flight',
      render: (text) => {
        const flight = `${text?.itinerariesList[0]?.carrierMarketing}${text?.itinerariesList[0]?.flightNumber} - ${text?.itinerariesList[0]?.departPlace}${text?.itinerariesList[0]?.arrivalPlace}`;
        return <span>{flight}</span>;
      },
    },
    {
      title: 'Mã đặt chỗ',
      key: 'pnrCode',
      render: (text) => {
        const uniqueReservationCodes = Array.from(
          new Set(
            text?.itinerariesList.map((itinerary) => itinerary?.reservationCode)
          )
        );
        const pnrCode = uniqueReservationCodes.join(', ');
        return <span>{pnrCode}</span>;
      },
    },
    {
      title: 'Mã Booking',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
    },
    {
      title: 'Đã gửi',
      key: 'notified',
      width: '20%',
      render: (text: BookingInfo.AsObject) => {
        return (
          <Space size='middle'>
            {text?.notified ? (
              <CheckIconBlue className='shrink-0 w-6 h-6' />
            ) : (
              <CheckIconGrey className='shrink-0 w-6 h-6' />
            )}
            <Button
              theme='primary'
              className='w-20 ml-10'
              onClick={() => {
                handleViewDetail(text);
              }}>
              Chi tiết
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: paginationRes?.pageLimit,
          current: paginationRes?.pageCurrent,
          total: paginationRes?.totalRecord,
          onChange: handleChangePage,
        }}
      />
    </div>
  );
};

export default TableListFlight;
