import { useEffect } from 'react';
import { useUserStore } from '../stores';
import { useToast } from '../internals';
import { useAppDispatch } from '@sky-booking-config/stores';
import {
  checkAuthenticate,
  fetchUserData,
  logout,
} from '@sky-booking-config/stores/reducers/user';
import { ROLE_LIST_ALLOWED, ROLE_USER } from '@sky-booking-config/utils';
import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
  useEffect(() => {
    if (isLogin) {
      dispatch(checkAuthenticate());
    }
  }, []);

  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLogin, userData } = useUserStore();

  useEffect(() => {
    if (!isLogin) {
      // console.log("ssss")
      navigate('/auth/login');
      return;
    }

    if (isLogin && !userData) {
      dispatch(fetchUserData());
      return;
    }

    const roleListUser = userData?.rolesList;

    if (!roleListUser) {
      dispatch(logout());
      navigate('/auth/login');
      toast.error('Không có quyền truy cập!');
      return;
    }

    const isAllowed = roleListUser.some((role) =>
      ROLE_LIST_ALLOWED.includes(role as ROLE_USER)
    );

    if (!isAllowed) {
      dispatch(logout());
      navigate('/auth/login');
      toast.error('Không có quyền truy cập!');
    }

    if (!window.location.href.includes('/dashboard')) {
      navigate('/dashboard');
    }
  }, [isLogin, userData]);
};

export default useAuth;
