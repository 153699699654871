import useAirport from '@web-admin/hooks/stores/useAirport';
import cn from 'classnames';
type Props = {
  code: string;
  className?: string;
};

export function AirportNameByCode({ code, className }: Props) {
  const { airportsObjectByCode } = useAirport();

  return (
    <div className={cn('text-primary-6 text-[13px]', className)}>
      {airportsObjectByCode?.[code]?.name}
    </div>
  );
}

export default AirportNameByCode;
