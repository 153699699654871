import {
  AccountCircleLineIcon,
  ArrowDownSLineIcon,
  Collapse,
  LogoutBoxRLineIcon,
} from '@common-ui';
import { useOnClickOutside } from '@core/hooks';
import { useCurrency } from '@sky-booking-config/hooks/internals';
import { useAppDispatch } from '@sky-booking-config/stores';
import { logout } from '@sky-booking-config/stores/reducers/user';
import {
  DEFAULT_MODAL_CONTENT,
  getLabelRoleUser,
} from '@sky-booking-config/utils';
import cn from 'classnames';
import { useRef, useState } from 'react';
import MenuHeaderModal, { ESwitchModal, TModalHeader } from './MenuModal';
import { useUserStore } from '@sky-booking-config/hooks/stores';

type Props = {
  name: string;
};

const DEFAULT_MODAL_INFO: TModalHeader = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
};

export const MenuHeader = ({ name }: Props) => {
  const dispatch = useAppDispatch();
  const { userData } = useUserStore();
  const { formatCurrency } = useCurrency();
  const collapseRef = useRef<HTMLDivElement>(null);
  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [modalInfo, setModalInfo] = useState<TModalHeader>(DEFAULT_MODAL_INFO);

  const handleLogout = () => {
    dispatch(logout());
    setIsShowCollapse(false);
    setModalInfo((pre) => ({ ...pre, open: false }));
  };

  useOnClickOutside(collapseRef, () => setIsShowCollapse(false));

  return (
    <div className='flex space-x-5 items-center h-full z-40'>
      <Collapse
        ref={collapseRef}
        panel={
          <div className='bg-white text-black p-4 shadow-xl border rounded z-sticky-control min-w-[300px] flex flex-col gap-4'>
            <span>{getLabelRoleUser(userData)}</span>
            <div
              onClick={() =>
                setModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.CHANGE_PASSWORD,
                }))
              }
              className='flex items-center gap-2.5 py-1 cursor-pointer hover:bg-primary-2 rounded duration-150 '>
              <AccountCircleLineIcon />
              <span>Đổi mật khẩu</span>
            </div>
            <div className='bg-neutral-5 h-px w-full'></div>
            <div
              onClick={() =>
                setModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.CONFIRM_LOGOUT,
                }))
              }
              className='flex items-center gap-2.5 py-1 cursor-pointer hover:bg-primary-2 rounded duration-150'>
              <LogoutBoxRLineIcon />
              <span>Đăng xuất</span>
            </div>
          </div>
        }
        isShow={isShowCollapse}
        panelClassName='absolute top-full right-0'
        className='relative h-full flex items-center'>
        <div
          className='flex space-x-2 items-center cursor-pointer'
          onClick={() => setIsShowCollapse((pre) => !pre)}>
          <div className='text-[13px]'>{name}</div>
          <ArrowDownSLineIcon
            className={cn('duration-300', {
              'rotate-180': isShowCollapse,
            })}
          />
        </div>
      </Collapse>
      <MenuHeaderModal
        modalInfo={modalInfo}
        handleUpdateModalInfo={setModalInfo}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default MenuHeader;
