import { Card, FlightTakeOffIcon, RightArrowIcon } from '@common-ui';

import { useCities } from '@web-admin/hooks/apps';
import { useTime } from '@web-admin/hooks/internals';

import BookingFlightInfo from './BookingFlightInfo';
import { IFlightItinerary } from '@tixlabs/grpc-client/types/skyhub';
import { IBookingItinerary } from '@tixlabs/grpc-client';

type Props = {
  data: IFlightItinerary | IBookingItinerary;
};

export const BookingFlightItem = ({ data }: Props) => {
  const { getCityNameByCode } = useCities();
  const { formatDateUTC } = useTime();
  return (
    <Card
      title={
        <div className='flex-1 font-semibold text-neutral-10 inline-flex items-center space-x-1.5 text-md'>
          <div className='inline-flex items-center gap-x-1'>
            Chuyến bay {getCityNameByCode(data.departPlace)}
            <RightArrowIcon className='w-4 h-4 shrink-0 mx-1 mt-1' />
            {getCityNameByCode(data.arrivalPlace)}
          </div>
          <span>|</span>
          <span>{formatDateUTC(data.departDate)}</span>
        </div>
      }
      rightIcon={
        data.reservationCode && (
          <span className='text-base'>
            Mã đặt chỗ:{' '}
            <span className='text-md text-secondary font-bold'>
              {data.reservationCode}
            </span>
          </span>
        )
      }
      icon={<FlightTakeOffIcon className='w-5 h-5 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='bg-neutral-4 p-3 mt-1.5 rounded space-y-3'>
      <BookingFlightInfo data={data} />
    </Card>
  );
};

export default BookingFlightItem;
