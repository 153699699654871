import { Button, ButtonLoading, Form } from '@common-ui';
import { EFlightItineraryType, IFlightHiddenFee } from '@tixlabs/grpc-client';
import {
  IAddFlightHiddenServiceFeeReq,
  IUpdateFlightHiddenServiceFeeReq,
} from '@tixlabs/grpc-client/web-partnership';
import { IModal } from '@web-admin/types';
import { Modal } from 'antd';
import { SetStateAction, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import HiddenFeeFormAction from './HiddenFeeFormAction';
import { useCurrency, useToast } from '@sky-booking-config/hooks/internals';
import { ITINERARY_TYPE_LABEL } from '@sky-booking-config/utils';
import { useAirlines } from '@sky-booking-config/hooks/stores';
import ModalContent from '@sky-booking-config/components/Modal/ModalContent';
import { EModalMode } from '@sky-booking-config/types';
import { useSearchAirlines } from '@sky-booking-config/hooks/apps';
import { useVICountry } from '@sky-booking-config/hooks/apps';

export enum ESwitchModal {
  NONE = 'NONE',
  ADD_HIDDEN_FEE = 'ADD_HIDDEN_FEE',
  UPDATE_HIDDEN_FEE = 'UPDATE_HIDDEN_FEE',
  DELETE_HIDDEN_FEE = 'DELETE_HIDDEN_FEE',
}

export type TModalHiddenFee = IModal & {
  state: ESwitchModal;
  hiddenFee?: IFlightHiddenFee;
};

type FormModalHiddenFee = {
  id: string;
  vat: number;
  type: EFlightItineraryType;
  airlineCode: string;
  bookingClassList: string[];
  bookingClass: string;
  routeFrom: string;
  routeTo: string;
  feeType: string;
  amount: number;
  percent: number;
};

function buildIAddFlightHiddenServiceFeeReq(
  data: FormModalHiddenFee
): Omit<IAddFlightHiddenServiceFeeReq, 'officeId' | 'vat'> {
  const result = {
    vat: data.vat,
    type: data.type,
    airlineCode: data.airlineCode,
    bookingClassList: data.bookingClassList,
    route: '',
    percent: 0,
    amount: 0,
  } as Omit<IAddFlightHiddenServiceFeeReq, 'officeId' | 'vat'>;
  if (data.routeFrom != '' && data.routeTo != '') {
    result.route = `${data.routeFrom}-${data.routeTo}`;
  }

  if (data.feeType === 'percent') {
    result.percent = data.amount / 100;
  } else {
    result.amount = data.amount;
  }

  return result;
}

function buildIUpdateFlightHiddenServiceFeeReq(
  data: FormModalHiddenFee
): Omit<IUpdateFlightHiddenServiceFeeReq, 'officeId' | 'vat'> {
  const result = {
    id: data.id,
    vat: data.vat,
    type: data.type,
    airlineCode: data.airlineCode,
    bookingClass: data.bookingClass,
    route: '',
    percent: 0,
    amount: 0,
  } as Omit<IUpdateFlightHiddenServiceFeeReq, 'officeId' | 'vat'>;

  if (data.routeFrom != '' && data.routeTo != '') {
    result.route = `${data.routeFrom}-${data.routeTo}`;
  }

  if (data.feeType === 'percent') {
    result.percent = data.amount / 100;
  } else {
    result.amount = data.amount;
  }

  return result;
}

type Props = {
  isLoadingDeleteHiddenFee: boolean;
  isLoadingUpdateHiddenFee: boolean;
  isLoadingAddHiddenFee: boolean;
  modalInfo: TModalHiddenFee;
  handleUpdateModalInfo: (value: SetStateAction<TModalHiddenFee>) => void;

  handleAddFlightHiddenFee: (
    data: Omit<IAddFlightHiddenServiceFeeReq, 'officeId' | 'vat'>,
    handleSuccess: () => void,
    handleError: (errorCode: string) => void
  ) => Promise<void>;

  handleUpdateFlightHiddenFee: (
    data: Omit<IUpdateFlightHiddenServiceFeeReq, 'officeId' | 'vat'>,
    handleSuccess: () => void,
    handleError: (errorCode: string) => void
  ) => Promise<void>;

  handleDeleteFlightHiddenFee: (
    {
      id,
    }: {
      id: string;
    },
    handleSuccess: () => void,
    handleError: (errorCode: string) => void
  ) => Promise<void>;
};

export const HiddenFeeModal = ({
  modalInfo,
  isLoadingAddHiddenFee,
  isLoadingDeleteHiddenFee,
  isLoadingUpdateHiddenFee,
  handleUpdateModalInfo,
  handleAddFlightHiddenFee,
  handleUpdateFlightHiddenFee,
  handleDeleteFlightHiddenFee,
}: Props) => {
  const toast = useToast();

  const { formatPrice } = useCurrency();

  const { airlinesObjectByCode } = useAirlines();
  const { countriesObjectByCode } = useVICountry();

  const methods = useForm<FormModalHiddenFee>({
    defaultValues: {
      id: '',
      vat: 0,
      amount: 0,
      airlineCode: '',
      type: EFlightItineraryType.IDOMESTIC,
      routeFrom: '',
      routeTo: '',
      feeType: '',
      percent: 0,
      bookingClassList: [],
      bookingClass: '',
    },
  });

  const { handleSearch: handleSearchAirline, airlinesList } =
    useSearchAirlines();

  const airlineOptions = useMemo(() => {
    return [
      { label: 'Tất cả', value: '', bookingClass: [] },
      ...airlinesList.map((item) => ({
        label: item.name,
        value: item.code,
        bookingClass: item.bookingClassesList,
      })),
    ];
  }, [airlinesList]);

  function handleSuccessAdd() {
    methods.reset();
    toast.success('Thêm hidden fee thành công');
    handleUpdateModalInfo({ ...modalInfo, open: false });
  }

  function handleSuccessUpdate() {
    methods.reset();
    toast.success('Cập nhật hidden fee thành công');
    handleUpdateModalInfo({ ...modalInfo, open: false });
  }

  function handleSuccessDelete() {
    methods.reset();
    toast.success('Xóa hidden fee thành công');
    handleUpdateModalInfo({ ...modalInfo, open: false });
  }

  function handleErrorAdd(errorCode: string) {
    methods.reset();
    switch (errorCode) {
      case 'SERVICE_FEE_ALREADY_EXISTS':
        toast.error('Hidden fee đã tồn tại');
        break;
      default:
        toast.error('Lỗi khi thêm hidden fee');
        break;
    }

    handleUpdateModalInfo({ ...modalInfo, open: false });
  }

  function handleErrorUpdate(errorCode: string) {
    methods.reset();

    switch (errorCode) {
      case 'SERVICE_FEE_ALREADY_EXISTS':
        toast.error('Hidden fee đã tồn tại');
        break;
      default:
        toast.error('Lỗi khi cập nhật hidden fee');
        break;
    }

    handleUpdateModalInfo({ ...modalInfo, open: false });
  }

  function handleErrorDelete(errorCode: string) {
    methods.reset();

    switch (errorCode) {
      default:
        toast.error('Lỗi khi xóa hidden fee');
        break;
    }

    handleUpdateModalInfo({ ...modalInfo, open: false });
  }

  const handleCancel = (state: ESwitchModal) => {
    switch (state) {
      case ESwitchModal.ADD_HIDDEN_FEE:
      case ESwitchModal.UPDATE_HIDDEN_FEE:
      case ESwitchModal.DELETE_HIDDEN_FEE:
        methods.reset();
        handleUpdateModalInfo({
          ...modalInfo,
          open: false,
        });
        break;

      default:
        break;
    }
  };

  const genModalFooter = (state: ESwitchModal) => {
    switch (state) {
      case ESwitchModal.ADD_HIDDEN_FEE:
        return (
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button
              type='button'
              theme='primary'
              variant='ghost'
              className='flex-1'
              onClick={() => handleCancel(state)}
              disabled={isLoadingAddHiddenFee}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              loading={isLoadingAddHiddenFee}
              className='flex-1'
              onClick={() =>
                methods.handleSubmit((data) =>
                  handleAddFlightHiddenFee(
                    buildIAddFlightHiddenServiceFeeReq(data),
                    handleSuccessAdd,
                    handleErrorAdd
                  )
                )()
              }>
              Lưu thông tin
            </ButtonLoading>
          </div>
        );
      case ESwitchModal.UPDATE_HIDDEN_FEE:
        return (
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button
              type='button'
              theme='primary'
              variant='ghost'
              className='flex-1'
              onClick={() => handleCancel(state)}
              disabled={isLoadingUpdateHiddenFee}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              loading={isLoadingUpdateHiddenFee}
              className='flex-1'
              onClick={() =>
                methods.handleSubmit((data) =>
                  handleUpdateFlightHiddenFee(
                    buildIUpdateFlightHiddenServiceFeeReq(data),
                    handleSuccessUpdate,
                    handleErrorUpdate
                  )
                )()
              }>
              Lưu thông tin
            </ButtonLoading>
          </div>
        );
      case ESwitchModal.DELETE_HIDDEN_FEE:
        return (
          <div className='flex items-center w-full space-x-2.5'>
            <Button
              type='button'
              theme='primary'
              className='flex-1'
              variant='ghost'
              onClick={() => handleCancel(state)}
              disabled={isLoadingDeleteHiddenFee}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              className='flex-1'
              type='button'
              theme='red'
              variant='solid'
              onClick={() =>
                modalInfo.hiddenFee?.id &&
                handleDeleteFlightHiddenFee(
                  { id: modalInfo.hiddenFee?.id },
                  handleSuccessDelete,
                  handleErrorDelete
                )
              }
              loading={isLoadingDeleteHiddenFee}>
              Xóa
            </ButtonLoading>
          </div>
        );
      default:
        return null;
    }
  };

  const genModalInfoContent = (state: ESwitchModal) => {
    switch (state) {
      case ESwitchModal.ADD_HIDDEN_FEE:
        return (
          <Form
            methods={methods}
            onSubmit={(data) =>
              handleAddFlightHiddenFee(
                buildIAddFlightHiddenServiceFeeReq(data),
                handleSuccessAdd,
                handleErrorAdd
              )
            }>
            <HiddenFeeFormAction
              title='Thêm hidden fee'
              onClose={() => handleCancel(state)}
              airlineOptions={airlineOptions}
              handleSearch={handleSearchAirline}
            />
          </Form>
        );
      case ESwitchModal.UPDATE_HIDDEN_FEE:
        return (
          <Form
            methods={methods}
            onSubmit={(data) =>
              handleUpdateFlightHiddenFee(
                buildIUpdateFlightHiddenServiceFeeReq(data),
                handleSuccessUpdate,
                handleErrorUpdate
              )
            }>
            <HiddenFeeFormAction
              title='Cập nhật hidden fee'
              onClose={() => handleCancel(state)}
              airlineOptions={airlineOptions}
              handleSearch={handleSearchAirline}
              isUpdate
            />
          </Form>
        );

      case ESwitchModal.DELETE_HIDDEN_FEE:
        return (
          <ModalContent
            title='Vui lòng kiểm tra & xác nhận thông tin hidden fee sẽ được xóa.'
            mode={EModalMode.WARNING}
            content={
              <div className='flex flex-col items-start gap-3 mt-3 w-full'>
                <div className='flex items-center w-full'>
                  <span className='text-neutral-7 font-semibold'>
                    Loại chuyến bay
                  </span>
                  <span className='flex-1 text-end'>
                    {modalInfo.hiddenFee?.type
                      ? ITINERARY_TYPE_LABEL[modalInfo.hiddenFee.type]
                      : ''}
                  </span>
                </div>
                <div className='flex items-center w-full'>
                  <span className='text-neutral-7 font-semibold'>
                    Hành trình
                  </span>
                  <span className='flex-1 text-end'>
                    {modalInfo.hiddenFee?.route
                      ?.split?.('-')
                      ?.map?.((item) => countriesObjectByCode[item]?.country)
                      .join('-') || 'Tất cả'}
                  </span>
                </div>
                <div className='flex items-center w-full'>
                  <span className='text-neutral-7 font-semibold'>
                    Hãng hàng không
                  </span>
                  <span className='flex-1 text-end'>
                    {modalInfo.hiddenFee?.airlineCode
                      ? airlinesObjectByCode?.[modalInfo.hiddenFee?.airlineCode]
                          ?.name?.vi
                      : 'Tất cả'}
                  </span>
                </div>
                <div className='flex items-center w-full'>
                  <span className='text-neutral-7 font-semibold'>
                    Hạng đặt chỗ
                  </span>
                  <span className='flex-1 text-end'>
                    {modalInfo.hiddenFee?.bookingClass || 'Tất cả'}
                  </span>
                </div>
                <div className='flex items-center w-full'>
                  <span className='text-neutral-7 font-semibold'>
                    Phí cộng thêm
                  </span>
                  {!!modalInfo.hiddenFee?.amount && (
                    <span className='flex-1 text-end'>
                      {formatPrice(modalInfo.hiddenFee?.amount || 0)} VND
                    </span>
                  )}
                  {!!modalInfo.hiddenFee?.percent && (
                    <span className='flex-1 text-end'>
                      {modalInfo.hiddenFee?.percent * 100} %
                    </span>
                  )}
                </div>
              </div>
            }
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (!modalInfo.hiddenFee) {
      return;
    }

    const { airlineCode, amount, id, type, route, bookingClass, percent } =
      modalInfo.hiddenFee;
    methods.setValue('id', id);
    methods.setValue('airlineCode', airlineCode);
    methods.setValue('type', type);
    methods.setValue('feeType', 'amount');
    if (amount > 0) {
      setTimeout(() => {
        methods.setValue('amount', amount);
      });
    } else if (percent > 0) {
      methods.setValue('feeType', 'percent');
      setTimeout(() => {
        methods.setValue('amount', percent * 100);
      });
    }
    setTimeout(() => {
      methods.setValue('bookingClass', bookingClass);
    });

    if (route != '') {
      const [from, to] = route.split('-');
      methods.setValue('routeFrom', from);
      methods.setValue('routeTo', to);
    }

    if (modalInfo.state === ESwitchModal.UPDATE_HIDDEN_FEE) {
      handleSearchAirline(airlineCode);
    }
  }, [modalInfo.hiddenFee]);

  return (
    <Modal
      {...modalInfo}
      closeIcon={false}
      footer={genModalFooter(modalInfo.state)}>
      {genModalInfoContent(modalInfo.state)}
    </Modal>
  );
};

export default HiddenFeeModal;
