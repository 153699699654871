import { convertArrayToObject } from '@core/utils';
import {
  IReportAirlineBookingItem,
  IReportBookingItem,
} from '@tixlabs/grpc-client';
import {
  partnerUserServiceClient,
  reportService as reportServicePartnerAdmin,
} from '@tixlabs/grpc-client/web-partner-admin';

import { reportService as reportServicePartner } from '@tixlabs/grpc-client/web-partner';

import { TFromToDate } from '@common-ui';
import { ONE_DAY_TIMESTAMP } from '@web-admin/utils';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppMutation, useTime } from '../internals';

type TFormFilter = {
  filterDate: TFromToDate;
  userId: string;
};

type TReportService =
  | typeof reportServicePartner
  | typeof reportServicePartnerAdmin;

export type TUseDashboardProps = {
  reportService: TReportService;
  isFilterBooker?: boolean;
};
export function useDashboard({
  reportService,
  isFilterBooker,
}: TUseDashboardProps) {
  const { formatDate } = useTime();
  const methods = useForm<TFormFilter>({
    defaultValues: {
      filterDate: {
        startDate: new Date(Date.now() - ONE_DAY_TIMESTAMP * 7),
        endDate: new Date(),
      },
      userId: '',
    },
  });

  const [saveFilter, setSaveFilter] = useState<{ from: number; to: number }>({
    from: Date.now() - ONE_DAY_TIMESTAMP * 7,
    to: Date.now(),
  });

  const [reportBookingData, setReportBookingData] = useState<
    IReportBookingItem[]
  >([]);
  const [reportAirlineBookingData, setReportAirlineBookingData] = useState<
    IReportAirlineBookingItem[]
  >([]);

  const { mutateAsync: getBookingStats, data: bookingStats } = useAppMutation({
    mutationKey: ['reportService', 'currentBookingStats'],
    mutationFn: reportService?.currentBookingStats,
  });

  const { mutateAsync: fetchReportBooking, isLoading: isLoadingReportBooking } =
    useAppMutation({
      mutationKey: ['reportService', 'getReportBooking'],
      mutationFn: reportService?.getReportBooking,
      onSuccess: (data) => {
        if (data.itemsList) {
          setReportBookingData(data.itemsList.sort((a, b) => a.date - b.date));
        }
      },
    });

  const {
    mutateAsync: fetchReportAirlineBooking,
    isLoading: isLoadingReportAirlineBooking,
  } = useAppMutation({
    mutationKey: ['reportService', 'getReportAirlineBooking'],
    mutationFn: reportService?.getReportAirlineBooking,
    onSuccess: (data) => {
      if (data.itemsList) {
        setReportAirlineBookingData(data.itemsList);
      }
    },
  });

  const { mutateAsync: fetchListPartnerUser, data: resListPartnerUser } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'listPartnerUser'],
      mutationFn: partnerUserServiceClient.listPartnerUser,
    });

  async function onSubmit(data: TFormFilter) {
    try {
      const filter = {
        from: data.filterDate.startDate?.getTime() || Date.now(),
        to: data.filterDate.endDate?.getTime() || Date.now(),
        userId: data.userId,
      };
      await fetchReportBooking(filter);
      await fetchReportAirlineBooking(filter);
      await getBookingStats({
        userId: data.userId,
      });
      setSaveFilter(filter);
    } catch (error) {}
  }

  useEffect(() => {
    methods.handleSubmit(onSubmit)();

    isFilterBooker && fetchListPartnerUser({});
  }, []);

  const reportBookingDataByDate = useMemo(() => {
    if (reportBookingData.length) {
      return convertArrayToObject(
        reportBookingData.map((item) => ({
          ...item,
          formatDate: formatDate(item.date),
        })),
        'formatDate'
      );
    }
  }, [reportBookingData]);

  return {
    filterMethods: methods,
    partnerUserOptions:
      resListPartnerUser?.data?.itemsList?.map((item) => ({
        label: `${item.name} (${item.userName || '-'})`,
        value: item.id,
      })) || [],
    isLoading: isLoadingReportAirlineBooking || isLoadingReportBooking,
    reportBookingData,
    reportAirlineBookingData,
    reportBookingDataByDate,
    onSubmit,
    saveFilter,
    bookingStats,
  };
}

export default useDashboard;
