import {
  Button,
  ButtonLoading,
  CloseIcon,
  CrossIcon,
  CrossLineIcon,
  Form,
  FormInput,
  FormInputUpload,
  LoadingRing,
  ONE_KB_TO_B,
  TInputUpload,
  Textarea,
  User3FillIcon,
  checkValidImagePng,
  convertUrlToFile,
} from '@common-ui';
import { STORAGE_URL } from '@core/constants';
import { generateRandomName } from '@core/utils';
import ModalContent from '@payment-portal/components/Modal/ModalContent';
import { useAppMutation, useCurrency } from '@payment-portal/hooks/internals';
import {
  useErrorMessageData,
  useUserStore,
} from '@payment-portal/hooks/stores';
import { EModalMode } from '@payment-portal/types';
import { EModalCreateTopUp, TModalImage } from '@payment-portal/types/ui/modal';
import {
  EPartnershipRole,
  SUPPORT_IMAGES_TYPE,
  checkRoleUser,
  patternValidatePhoneNumber,
  patternValidatePrice,
} from '@payment-portal/utils';
import { uploadMedia } from '@tixlabs/axios-client';
import { ETopUpRequestStatus, ITopUpRequestInfo } from '@tixlabs/grpc-client';
import {
  IUpdateTopupRequestReq,
  topUpServiceApi,
} from '@tixlabs/grpc-client/web';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  //
};

export const EditTopUp = (props: Props) => {
  const navigate = useNavigate();
  const { formatPrice } = useCurrency();
  const { userData } = useUserStore();
  const [note, setNote] = useState('');
  const { topUpId } = useParams();
  const [isShowImg, setIsShowImg] = useState<TModalImage>({
    imgUrl: '',
    open: false,
  });
  const methods = useForm<
    Omit<IUpdateTopupRequestReq, 'topupAmount'> & {
      uploadFile: TInputUpload[];
      topupAmount: string | number;
    }
  >({
    defaultValues: {
      updatedAt: 0,
      attachmentsList: [],
      note: '',
      topupAmount: 0,
      uploadFile: [],
    },
    mode: 'all',
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [topUpDetail, setTopUpDetail] = useState<ITopUpRequestInfo>();
  const { getErrorMessage } = useErrorMessageData();

  const [stateModal, setStateModal] = useState<EModalCreateTopUp>(
    EModalCreateTopUp.CONFIRM
  );
  const [prevUploadFiles, setPrevUploadFiles] = useState<TInputUpload[]>([]);
  const uploadFiles = methods.watch('uploadFile');

  const { mutateAsync: getTopupDetail, isLoading } = useAppMutation({
    mutationKey: ['topUpServiceApi', 'getTopupDetail'],
    mutationFn: topUpServiceApi.getTopupDetail,
    onSuccess: async ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        if (
          checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) &&
          data.status !== ETopUpRequestStatus.WAITINGAPPROVAL &&
          data.status !== ETopUpRequestStatus.REJECTED
        ) {
          toast.error('Bạn không được phép chỉnh sửa thông tin đã được xử lý');
          navigate('/dashboard/top-up-request-management/');
        }
        setTopUpDetail(data);
        setNote(data.note);
        const uploadFileSync: TInputUpload[] = [];
        for (let i = 0; i < data.attachmentsList.length; i++) {
          const file = await convertUrlToFile(data.attachmentsList[i]);
          if (file) {
            uploadFileSync.push({
              fileId: generateRandomName(),
              url: data.attachmentsList[i],
            });
          }
        }
        methods.reset({
          topupAmount: formatPrice(data.topupAmount),
          updatedAt: data.updatedAt,
          note: data.note,
          id: data.id,
          uploadFile: uploadFileSync,
        });
        setPrevUploadFiles(uploadFileSync);
      } else {
        // handle error/ call api get message error
        toast.error(getErrorMessage(errorCode));
        setErrorMsg(getErrorMessage(errorCode));
        navigate('/dashboard/top-up-request-management/');
      }
    },
    onError: (error) => {
      // handle error when network/system error
      console.log('error', error);
      toast.error('Something error');
      navigate('/dashboard/top-up-request-management/');
    },
  });

  const { mutateAsync: updateTopupRequest } = useAppMutation({
    mutationKey: ['topUpServiceApi', 'updateTopupRequest'],
    mutationFn: topUpServiceApi.updateTopupRequest,
    onSuccess: ({ isSuccess, errorCode }) => {
      if (isSuccess) {
        setIsOpenModal(true);
        return;
      } else {
        // handle error/ call api get message error
        setErrorMsg(getErrorMessage(errorCode));
      }
    },
    onError: (error) => {
      // handle error when network/system error
      toast.error('Something error');
      console.log('error', error);
    },
  });

  const handleSubmit = async (
    data: Omit<IUpdateTopupRequestReq, 'topupAmount'> & {
      uploadFile: TInputUpload[];
      topupAmount: string | number;
    }
  ) => {
    try {
      if (data.uploadFile.length === 0) {
        methods.setError('uploadFile', {
          message: 'Tải lên tối thiểu 1 file.',
        });
        return;
      }

      if (data.uploadFile.length > 5) {
        methods.setError('uploadFile', {
          message: 'Tải lên tối đa 5 file.',
        });
        return;
      }

      let isValidImgSize = true;
      data.uploadFile.forEach((file) => {
        if (file.fileInfo && file.fileInfo.size > ONE_KB_TO_B * 1024) {
          isValidImgSize = false;
        }
      });

      if (!isValidImgSize) {
        methods.setError('uploadFile', {
          message: 'Mỗi file không được vượt quá 1MB.',
        });
      } else {
        const formData = new FormData();
        const existedUploadFile = data.uploadFile
          .filter((f) => f.fileInfo === undefined)
          .map((f) => f.url) as string[];
        data.uploadFile.forEach((f) => {
          f.fileInfo && formData.append('files', f.fileInfo);
        });
        // const upLoadImg = await uploadMedia(formData);
        const { data: uploadDataRes, error } = await uploadMedia(formData);
        if (!error) {
          const attachmentsList: string[] = uploadDataRes.result.map((r) => {
            return `${STORAGE_URL}/${r.filename}`;
          });
          const updateAttachmentsList = [
            ...existedUploadFile,
            ...attachmentsList,
          ];
          await updateTopupRequest({
            topupAmount:
              typeof data.topupAmount === 'string'
                ? +(data.topupAmount as string).replace(/,/g, '')
                : data.topupAmount,
            note: data.note,
            attachmentsList: updateAttachmentsList,
            id: data.id,
            updatedAt: data.updatedAt,
          });
        }
      }
      // const totalSize = data.uploadFile.reduce((total, file) => {
      //   return total + (file.fileInfo ? file.fileInfo.size : 0);
      // }, 0);
      // if (totalSize > 5 * ONE_KB_TO_B * 1024) {
      //   methods.setError('uploadFile', {
      //     message: 'Tổng 5 file không được vượt quá 5MB.',
      //   });
      // } else {
      //   const formData = new FormData();
      //   data.uploadFile.forEach((f) => {
      //     f.fileInfo && formData.append('files', f.fileInfo);
      //   });
      //   // const upLoadImg = await uploadMedia(formData);
      //   const { data: uploadDataRes, error } = await uploadMedia(formData);
      //   if (!error) {
      //     const attachmentsList: string[] = uploadDataRes.result.map((r) => {
      //       return `${STORAGE_URL}/${r.filename}`;
      //     });
      //     methods.setValue('attachmentsList', attachmentsList);
      //     await updateTopupRequest({
      //       topupAmount: data.topupAmount,
      //       note: data.note,
      //       attachmentsList: attachmentsList,
      //       id: data.id,
      //       updatedAt: data.updatedAt,
      //     });
      //   }
      // }
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleOk = () => {
    setIsOpenModal(false);
    navigate('/dashboard/top-up-request-management/');
  };

  const genarateModalFooter = (
    state: EModalCreateTopUp,
    onClick: () => void
  ) => {
    const content = {
      [EModalCreateTopUp.CONFIRM]: (
        <div className='flex flex-col'>
          <Button onClick={onClick} className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
      [EModalCreateTopUp.CANCEL]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={() => {
              navigate('/dashboard/top-up-request-management/');
            }}>
            Huỷ bỏ
          </Button>
          <Button
            className='flex-1'
            onClick={() => {
              setIsOpenModal(false);
            }}>
            Tiếp tục chỉnh sửa
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  const genarateModalContent = (state: EModalCreateTopUp) => {
    const content = {
      [EModalCreateTopUp.CONFIRM]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.SUCCESS}
          content='Chỉnh sửa yêu cầu nạp tiền thành công'
        />
      ),
      [EModalCreateTopUp.CANCEL]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.CONFIRM}
          title='Huỷ bỏ chỉnh sửa yêu cầu nạp tiền'
          content='Bạn đang huỷ bỏ chỉnh sửa yêu cầu nạp tiền, bạn có chắc chắn muốn huỷ?'
        />
      ),
    };
    return content[state];
  };

  const getInfoTopUp = async () => {
    topUpId &&
      (await getTopupDetail({
        id: topUpId,
      }));
  };

  useEffect(() => {
    getInfoTopUp();
  }, []);

  return isLoading ? (
    <div className='flex justify-center items-center h-[300px] w-full'>
      <LoadingRing />
    </div>
  ) : (
    <>
      <Modal
        open={isOpenModal}
        closeIcon={false}
        footer={genarateModalFooter(stateModal, handleOk)}
        centered={true}>
        {genarateModalContent(stateModal)}
      </Modal>
      <Modal
        open={isShowImg.open}
        closeIcon={
          <CloseIcon
            className='w-5 h-5 text-[#090909] bg-white'
            onClick={() => {
              setIsShowImg({
                open: false,
                imgUrl: '',
              });
            }}
          />
        }
        centered={true}
        footer={null}>
        <div className='flex items-center justify-center max-w-[900px] '>
          <img className='' alt={isShowImg.imgUrl} src={isShowImg.imgUrl} />
        </div>
      </Modal>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className='flex flex-col space-y-3 max-w-[900px]'>
        <div className='flex flex-col space-y-5'>
          <div className='flex space-x-2.5 items-center'>
            <User3FillIcon className='w-5 h-5 shrink-0' />
            <span className='font-semibold text-lg'>Yêu cầu nạp tiền</span>
          </div>
          <div className='flex flex-col space-y-5 bg-white border rounded p-4'>
            <div className='flex flex-col space-y-1.5'>
              <div className='flex flex-col space-y-2 5'>
                <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                  Thông tin nạp tiền
                </h3>
                <div className='border-b border-neutral-5'></div>
              </div>
              {topUpDetail ? (
                <div className='grid grid-cols-3 items-baseline gap-2.5'>
                  <FormInput
                    name='agentCode'
                    placeholder='Nhập agent Id'
                    label='Mã đại lý'
                    inputProps={{
                      disabled: true,
                    }}
                    value={topUpDetail.agentInfo?.agentCode}
                  />
                  <FormInput
                    inputProps={{
                      disabled: true,
                    }}
                    isShowError={true}
                    name='agentName'
                    label='Tên đại lý'
                    value={topUpDetail.agentInfo?.agentName}
                  />
                  <FormInput
                    isShowError={true}
                    name='topupAmount'
                    label='Số tiền nạp'
                    placeholder='Nhập số tiền nạp'
                    rules={{
                      required: 'Số tiền nạp là bắt buộc.',
                      pattern: {
                        value: patternValidatePrice,
                        message: 'Số tiền nạp không hợp lệ.',
                      },
                      min: {
                        value: 1,
                        message: 'Số tiền nạp phải lớn hơn 0',
                      },
                    }}
                    inputProps={{
                      customFormat(value) {
                        const formatValue = value.replace(/,/g, '');
                        return formatPrice(+formatValue);
                      },
                    }}
                  />
                  <div className='col-span-2 flex flex-col space-y-2 align-baseline'>
                    <span>Ghi chú</span>
                    <Textarea
                      rows={4}
                      style={{
                        resize: 'none',
                      }}
                      placeholder='Viết ghi chú...'
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                        methods.setValue('note', e.target.value);
                        methods.clearErrors('note');
                      }}
                    />
                  </div>
                  <div className='col-span-1'></div>
                  <div className='col-span-3 grid grid-cols-3 gap-2'>
                    <span className='col-span-3'>Tệp đính kèm</span>
                    <div className='col-span-3 grid grid-cols-5 gap-2.5 p-2 border border-secondary-3'>
                      {uploadFiles.length > 0 &&
                        uploadFiles.map((file, index) => {
                          return (
                            <div
                              key={index}
                              className='relative w-full aspect-video rounded-lg overflow-hidden shadow-md'>
                              <img
                                className='block mx-auto max-h-full'
                                src={file.url}
                                alt={file.fileInfo?.name || '' + index}
                                onClick={() => {
                                  window.open(file.url, '_blank');
                                }}
                              />
                              <div
                                className='absolute top-0 right-0 text-red-5 cursor-pointer'
                                onClick={async () => {
                                  let invalidImgCount = 0;

                                  for (
                                    let index = 0;
                                    index < uploadFiles.length;
                                    index++
                                  ) {
                                    const result = uploadFiles[index].fileInfo
                                      ? await checkValidImagePng(
                                          uploadFiles[index].fileInfo
                                        )
                                      : true;
                                    if (!result) {
                                      invalidImgCount++;
                                    }
                                  }

                                  const isValidImageTarget =
                                    await checkValidImagePng(
                                      uploadFiles[index].fileInfo
                                    );
                                  const newUploadFiles = methods
                                    .getValues('uploadFile')
                                    .filter((f) => f.fileId !== file.fileId);
                                  methods.setValue(
                                    'uploadFile',
                                    newUploadFiles
                                  );
                                  setPrevUploadFiles(newUploadFiles);
                                  // clear error when remove invalid image
                                  if (!isValidImageTarget) {
                                    if (invalidImgCount === 1) {
                                      methods.clearErrors('uploadFile');
                                      if (newUploadFiles.length > 5) {
                                        methods.setError('uploadFile', {
                                          message:
                                            'Maximum upload is 5 files. Please remove unnecessary files!',
                                        });
                                      } else {
                                        methods.clearErrors('uploadFile');
                                      }
                                    }
                                  } else {
                                    if (invalidImgCount === 0) {
                                      methods.clearErrors('uploadFile');
                                      if (newUploadFiles.length > 5) {
                                        methods.setError('uploadFile', {
                                          message:
                                            'Maximum upload is 5 files. Please remove unnecessary files!',
                                        });
                                      } else {
                                        methods.clearErrors('uploadFile');
                                      }
                                    }
                                  }
                                }}>
                                <CrossLineIcon />
                              </div>
                            </div>
                          );
                        })}

                      {uploadFiles.length < 5 ? (
                        <FormInputUpload
                          isShowError={false}
                          name='uploadFile'
                          rules={{
                            onChange(event) {
                              if (event.target.value.length > 5) {
                                toast.error('Maximum upload 5 files.');
                                methods.setValue('uploadFile', prevUploadFiles);
                              } else if (event.target.value.length < 5) {
                                setPrevUploadFiles(
                                  methods.getValues('uploadFile')
                                );
                              }
                            },
                            validate: {
                              supportFile: async (value: TInputUpload[]) => {
                                if (value.length) {
                                  let isInValid: boolean | undefined =
                                    undefined;
                                  const listImgInvalid: TInputUpload[] = [];
                                  for (
                                    let index = 0;
                                    index < value.length;
                                    index++
                                  ) {
                                    const result = value[index].fileInfo
                                      ? await checkValidImagePng(
                                          value[index].fileInfo
                                        )
                                      : true;
                                    if (!result) {
                                      isInValid = true;
                                      listImgInvalid.push(value[index]);
                                    }
                                  }

                                  return (
                                    !isInValid ||
                                    `Invalid PNG image. Please upload another valid file. Files error: ${listImgInvalid.map(
                                      (listImg) => {
                                        return listImg.fileInfo?.name;
                                      }
                                    )}`
                                  );
                                } else {
                                  return undefined;
                                }
                              },
                              checkMaxLengthFile: async (value) => {
                                if (value.length > 5) {
                                  return 'Maximum upload is 5 files. Please remove unnecessary files.!';
                                } else {
                                  return undefined;
                                }
                              },
                            },
                          }}
                          inputProps={{
                            maxLength: 5 - uploadFiles.length,
                            multiple: true,
                            renderInput: ({ children }) => {
                              return (
                                <label className='block relative w-full aspect-video cursor-pointer rounded-lg border border-theme-black'>
                                  {children}
                                  <div className='absolute w-full h-full flex items-center justify-center'>
                                    <CrossIcon className='w-[50px] h-[50px]' />
                                  </div>
                                </label>
                              );
                            },
                            accept: SUPPORT_IMAGES_TYPE.join(', '),
                          }}
                        />
                      ) : null}
                    </div>
                    {methods.formState.errors.uploadFile ? (
                      <span className='col-span-3 text-common-error'>
                        {methods.formState.errors.uploadFile.message}
                      </span>
                    ) : (
                      <span className='col-span-3 text-common-error'>
                        *Tải lên tối đa 5 files.
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            {errorMsg && <span className='text-red-5'>{errorMsg}</span>}
          </div>
        </div>
        <div className='flex flex-row items-center justify-end gap-4'>
          <Button
            theme='primary'
            variant='outline'
            disabled={methods.formState.isSubmitting}
            onClick={() => {
              setStateModal(EModalCreateTopUp.CANCEL);
              setIsOpenModal(true);
            }}>
            Huỷ bỏ
          </Button>
          <ButtonLoading
            theme='primary'
            type='submit'
            loading={methods.formState.isSubmitting}
            isShowChildWhenLoading={true}>
            Cập nhật yêu cầu
          </ButtonLoading>
        </div>
      </Form>
    </>
  );
};
