import { TransferBookingRequestStatus } from '@api/airplane/base_pb';
import {
  ETransferBookingConfigStatus,
  ETransferBookingRequestStatus,
} from '@tixlabs/grpc-client';
import { EGender, EPassengerType, SelectOptionItem } from '@tixlabs/types';

export const LabelTransferBookingRequestStatus = {
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_NONE]: 'Tất cả',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED]:
    'Đã hủy yêu cầu',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED]:
    'Đã xuất vé',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING]:
    'Chờ xuất hộ',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING]:
    'Đang xuất hộ',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED]:
    'Đã hết hạn',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED]:
    'Đã hủy booking',
};

export const LabelTransferBookingConfigStatus = {
  [ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE]: 'Hoạt động',
  [ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE]: 'Tạm tắt',
};

export const TransferBookingConfigStatusOptions = [
  {
    label:
      LabelTransferBookingConfigStatus[
        ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE
      ],
    value: ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE,
  },
  {
    label:
      LabelTransferBookingConfigStatus[
        ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE
      ],
    value: ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE,
  },
];

export const SELECT_TRANSFER_BOOING_REQUEST_STATUS_OPTIONS: SelectOptionItem[] =
  [
    {
      label: 'Tất cả',
      value: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_NONE,
    },
    {
      label: 'Chờ xuất hộ',
      value: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING,
    },
    {
      label: 'Đã xuất vé',
      value: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED,
    },
    {
      label: 'Đã hủy yêu cầu',
      value: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED,
    },
    {
      label: 'Đang xuất hộ',
      value:
        TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING,
    },
    {
      label: 'Đã hủy booking',
      value: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED,
    },
    {
      label: 'Đã hết hạn',
      value: TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED,
    },
  ];

export const SKYHUB_BOOKING_STATUS = {
  OK: 'OK',
  Ticketed: 'Ticketed',
} as const;

export const PASSENGER_TYPE_LABEL = {
  [EPassengerType.ADT]: 'Người lớn',
  [EPassengerType.CHD]: 'Trẻ em',
  [EPassengerType.INF]: 'Trẻ sơ sinh',
};

export const GENDER_TYPE_LABEL = {
  [EGender.GENDER_TYPE_MALE]: 'Nam',
  [EGender.GENDER_TYPE_FEMALE]: 'Nữ',
};
