/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partner_admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partner_admin/flight.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var airplane_base_pb = require('../../airplane/base_pb.js')

var airplane_flight_pb = require('../../airplane/flight_pb.js')

var airplane_hub_pb = require('../../airplane/hub_pb.js')

var airplane_seat_pb = require('../../airplane/seat_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partner_admin = require('./flight_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner_admin.FlightServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner_admin.FlightServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.SearchAirlinesReq,
 *   !proto.airplane.web_partner_admin.SearchAirlinesRes>}
 */
const methodDescriptor_FlightService_SearchAirlines = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.FlightService/SearchAirlines',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.SearchAirlinesReq,
  proto.airplane.web_partner_admin.SearchAirlinesRes,
  /**
   * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.SearchAirlinesRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.SearchAirlinesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.SearchAirlinesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.FlightServiceClient.prototype.searchAirlines =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/SearchAirlines',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirlines,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.SearchAirlinesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.SearchAirlinesRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.FlightServicePromiseClient.prototype.searchAirlines =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/SearchAirlines',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirlines);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.RetrieveBookingFlightReq,
 *   !proto.airplane.web_partner_admin.RetrieveBookingFlightRes>}
 */
const methodDescriptor_FlightService_RetrieveBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.FlightService/RetrieveBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.RetrieveBookingFlightReq,
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.RetrieveBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.RetrieveBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.FlightServiceClient.prototype.retrieveBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.RetrieveBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.FlightServicePromiseClient.prototype.retrieveBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.ListBookingFlightReq,
 *   !proto.airplane.web_partner_admin.ListBookingFlightRes>}
 */
const methodDescriptor_FlightService_ListBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.FlightService/ListBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.ListBookingFlightReq,
  proto.airplane.web_partner_admin.ListBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.ListBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.ListBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.ListBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.FlightServiceClient.prototype.listBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.ListBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.FlightServicePromiseClient.prototype.listBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.ExportBookingFlightReq,
 *   !proto.airplane.web_partner_admin.ExportBookingFlightRes>}
 */
const methodDescriptor_FlightService_ExportBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.FlightService/ExportBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.ExportBookingFlightReq,
  proto.airplane.web_partner_admin.ExportBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.ExportBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.ExportBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.ExportBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.FlightServiceClient.prototype.exportBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/ExportBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ExportBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.ExportBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.ExportBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.FlightServicePromiseClient.prototype.exportBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.FlightService/ExportBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ExportBookingFlight);
};


module.exports = proto.airplane.web_partner_admin;

