import { Button, Edit2FillIcon, InfoIcon } from '@common-ui';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { TPagination } from './useManageOfficeId';
import {
  LabelPartnerUserRole,
  LabelUserStatusPartner,
  ROLE_USER,
  checkRoleUser,
} from '@sky-booking-config/utils';
import cn from 'classnames';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import {
  EUserStatusPartner,
  IOfficeInfo,
  IUserBasicInfo,
} from '@tixlabs/grpc-client';
import { RootState } from '@web-booker/stores';
import ModalChangePasswordIssuer, {
  TModalInfo,
} from '../CreateAndUpdateIssuer/UpdateIssuer/ModalChangePasswordIssuer';
import { useState } from 'react';
import { UserStatus } from '@api/partner/base_pb';

type Props = {
  data: IUserBasicInfo[] | undefined;
  isLoading?: boolean;
  pagination: TPagination;
  onChangePage: (pageNumber: number) => Promise<void>;
};
type TFormModal = { newPassword: string };
const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const StatusUser = ({ status }: { status: EUserStatusPartner }) => {
  return (
    <span
      className={cn('font-bold', {
        'text-green-7': status === EUserStatusPartner.ACTIVE,
        'text-orange-500': status === EUserStatusPartner.INACTIVE,
      })}>
      {LabelUserStatusPartner[status]}
    </span>
  );
};

export const ListIssuer = ({
  data,
  isLoading,
  onChangePage,
  pagination,
}: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState<TModalInfo>({
    id: '',
    status: UserStatus.NONE,
  });

  const handleOpenModalChangePassword = (user: IUserBasicInfo) => {
    setModalInfo({
      id: user.id || '',
      status: user.status || UserStatus.ACTIVE,
    });
    setIsModalOpen(true);
  };

  const columns: ColumnsType<IUserBasicInfo> = [
    {
      key: 'userName',
      dataIndex: 'userName',
      title: <TitleColumns title='Tên đăng nhập' />,
      render: (_, record) => {
        return <span>{record.userName}</span>;
      },
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: <TitleColumns title='Tên thành viên' />,
      render: (_, record) => {
        return <span>{record.name}</span>;
      },
    },
    // {
    //   key: 'providerConfigsList',
    //   dataIndex: 'providerConfigsList',
    //   title: <TitleColumns title='Provider' />,
    //   render: (_, record) => {
    //     return (
    //       <span>
    //         {record.providerConfigsList
    //           .filter((item) => item.enabled === true)
    //           .map((item) => item.name)
    //           .join(', ')}
    //       </span>
    //     );
    //   },
    // },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TitleColumns title='Trạng thái' />,
      render: (_, record) => {
        return <StatusUser status={record.status} />;
      },
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: <TitleColumns title='Vai trò' />,
      render: (_, record) => {
        return <span>{LabelPartnerUserRole[ROLE_USER.AGENCY_ISSUER]}</span>;
      },
    },
  ];

  checkRoleUser(ROLE_USER.OFFICE_MANAGER, userData) &&
    columns.push({
      key: 'action',
      dataIndex: 'action',
      title: <TitleColumns title='Thao tác' />,
      render: (_, record) => {
        return (
          <div className='flex items-center gap-x-1.5'>
            <Button
              variant='solid'
              theme='neutral'
              className='bg-slate-500'
              prefixIcon={<Edit2FillIcon />}
              onClick={() => {
                handleOpenModalChangePassword(record);
              }}>
              Đổi mật khẩu
            </Button>
            <Button
              variant='solid'
              onClick={() => {
                navigate(`./update-issuer?id=${record.id}`);
              }}
              prefixIcon={<Edit2FillIcon />}>
              Sửa thông tin
            </Button>
          </div>
        );
      },
    });

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        size='small'
        loading={isLoading}
        pagination={
          pagination.totalPage > 1 && {
            pageSize: pagination.pageLimit,
            current: pagination.pageCurrent,
            total: pagination.totalRecord,
            className: 'space-x-2.5',
            onChange: onChangePage,
          }
        }
      />
      <ModalChangePasswordIssuer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalInfo={modalInfo}
      />
    </>
  );
};

export default ListIssuer;
