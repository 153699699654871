import { IModal } from '../common';

export enum EModalCreateUser {
  CANCEL,
  CONFIRM,
}

export enum EModalEditUser {
  CANCEL,
  CONFIRM,
  CHANGE_PASSWORD,
}

export enum EModalCreateTopUp {
  CANCEL,
  CONFIRM,
}

export enum EModalActionTopUp {
  CANCEL,
  REJECT,
  REJECT_SUCCESS,
  APPROVAL,
  APPROVAL_SUCCESS,
  ERROR,
}

export enum EModalDeleteTopUp {
  CONFIRM,
  SUCCESS,
  ERROR,
}

export type TModalImage = IModal & {
  imgUrl: string;
};
