import {
  Button,
  ButtonLoading,
  CloseIcon,
  Form,
  FormInput,
  FormInputPassword,
} from '@common-ui';
import { IModal } from '@sky-booking-config/types';
import {
  IChangeOfficePasswordRes,
  IUpdateOfficeProvidersReq,
  IUpdateOfficeProvidersRes,
} from '@tixlabs/grpc-client/web-partnership';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import ToggleProvider from '../ToggleProvider';
import { SelectOptionItem } from '@tixlabs/types';
import ModalToggleProvider from './ModalToggleProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  modalInfo: TModalInfo;
  isLoadingChangePassword?: boolean;
  isLoadingUpdateOfficeProviders?: boolean;
  handleUpdateModalInfo: (modalInfo: TModalInfo) => void;
  handleChangePassword: (
    newPassword: string,
    id: string,
    shopId: string
  ) => Promise<IChangeOfficePasswordRes>;
  handleUpdateOfficeProviders: ({}: IUpdateOfficeProvidersReq) => Promise<IUpdateOfficeProvidersRes>;
};

export enum ESwitchModal {
  NONE = 'NONE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  UPDATE_PROVIDER = 'UPDATE_PROVIDER',
}

export type TModalInfo = IModal & {
  state: ESwitchModal;
  ownerId: string;
  id: string;
  providerListOption: SelectOptionItem[];
};

type TFormModal = { newPassword: string };

const ModalUpdateOfficeId = ({
  modalInfo,
  handleChangePassword,
  handleUpdateModalInfo,
  handleUpdateOfficeProviders,
  isLoadingChangePassword,
}: Props) => {
  const navigate = useNavigate();
  const changePasswordMethod = useForm<TFormModal>({
    defaultValues: {
      newPassword: '',
    },
  });
  const updateProviderMethod = useForm<IUpdateOfficeProvidersReq>({
    defaultValues: {
      id: '',
      enabledProvidersList: [],
    },
  });

  const handleSubmitChangePassword = async (data: TFormModal) => {
    try {
      const { isSuccess, errorCode } = await handleChangePassword(
        data.newPassword,
        modalInfo.ownerId,
        modalInfo.id
      );

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật mật khẩu thành công!');
      handleUpdateModalInfo({ ...modalInfo, open: false });
      changePasswordMethod.reset();
      navigate('/dashboard/agents');
    } catch (error) {
      //   const errorMessage = getApiErrorMessages(error)[0];
      toast.error('Lỗi cập nhật mật khẩu!');
      handleUpdateModalInfo({ ...modalInfo, open: false });
    }
  };
  const handleSubmitUpdateOfficeProviders = async (
    data: IUpdateOfficeProvidersReq
  ) => {
    try {
      const { isSuccess, errorCode } = await handleUpdateOfficeProviders({
        enabledProvidersList: data.enabledProvidersList,
        id: modalInfo.ownerId,
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật Provider thành công!');
      handleUpdateModalInfo({ ...modalInfo, open: false });
      // updateProviderMethod.reset();
      // navigate('/dashboard/agents');
    } catch (error) {
      //   const errorMessage = getApiErrorMessages(error)[0];
      toast.error('Lỗi cập nhật Provider!');
      handleUpdateModalInfo({ ...modalInfo, open: false });
    }
  };

  const onOk = async (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        await changePasswordMethod.handleSubmit(handleSubmitChangePassword)();
        break;
      case ESwitchModal.UPDATE_PROVIDER:
        await updateProviderMethod.handleSubmit(
          handleSubmitUpdateOfficeProviders
        )();
        break;
      default:
        break;
    }
  };
  const onCancel = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        handleUpdateModalInfo({ ...modalInfo, open: false, ownerId: '' });
        break;
      case ESwitchModal.UPDATE_PROVIDER:
        updateProviderMethod.setValue(
          'enabledProvidersList',
          (modalInfo.providerListOption
            .map((provider) => {
              return provider.enabled ? +provider.value : undefined;
            })
            .filter((a) => a !== undefined) as number[]) || []
        );
        handleUpdateModalInfo({ ...modalInfo, open: false, ownerId: '' });
        break;

      default:
        break;
    }
  };
  const genModalTitle = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        return 'Đổi mật khẩu';
      default:
        return;
    }
  };
  const genModalContent = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        return (
          <Form
            methods={changePasswordMethod}
            onSubmit={handleSubmitChangePassword}
            className='flex flex-col gap-y-5'>
            <FormInputPassword
              name='newPassword'
              label='Mật khẩu'
              placeholder='Nhập mật khẩu'
              rules={{
                required: 'Nhập mật khẩu',
                validate: {
                  minLength: (value: string) =>
                    value.length >= 8 || 'Tối thiểu 8 kí tự',
                },
                onChange(event) {
                  changePasswordMethod.clearErrors('newPassword');
                },
              }}
            />
          </Form>
        );
      case ESwitchModal.UPDATE_PROVIDER:
        return (
          <div className='flex flex-col space-y-3'>
            <span className='font-semibold text-md'>Cập nhật Provider</span>
            <Form
              methods={updateProviderMethod}
              onSubmit={handleSubmitUpdateOfficeProviders}
              className='flex flex-col gap-y-5'>
              <div className='w-full flex flex-col gap-y-3'>
                {modalInfo.providerListOption.length ? (
                  modalInfo.providerListOption.map((item, index) => {
                    return (
                      <ModalToggleProvider
                        key={index}
                        label={item.label}
                        value={item.value}
                        nameField='enabledProvidersList'
                      />
                    );
                  })
                ) : (
                  <span className='font-medium text-sm'>
                    Không có Provider có thể cập nhật.
                  </span>
                )}
              </div>
            </Form>
          </div>
        );
      default:
        return <></>;
    }
  };
  const genModalFooter = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        return (
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button
              disabled={isLoadingChangePassword}
              theme='neutral'
              className='flex-1'
              onClick={() => onCancel(modalInfo)}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              loading={isLoadingChangePassword}
              isShowChildWhenLoading
              className='flex-1'
              onClick={() => onOk(modalInfo)}>
              Cập nhật
            </ButtonLoading>
          </div>
        );
      case ESwitchModal.UPDATE_PROVIDER:
        return modalInfo.providerListOption.length ? (
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button
              disabled={isLoadingChangePassword}
              theme='neutral'
              className='flex-1'
              onClick={() => onCancel(modalInfo)}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              loading={isLoadingChangePassword}
              isShowChildWhenLoading
              className='flex-1'
              onClick={() => onOk(modalInfo)}>
              Cập nhật
            </ButtonLoading>
          </div>
        ) : null;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    updateProviderMethod.setValue(
      'enabledProvidersList',
      (modalInfo.providerListOption
        .map((provider) => {
          return provider.enabled ? +provider.value : undefined;
        })
        .filter((a) => a !== undefined) as number[]) || []
    );
  }, [modalInfo.providerListOption]);

  return (
    <Modal
      centered
      open={modalInfo.open}
      title={genModalTitle(modalInfo)}
      footer={genModalFooter(modalInfo)}
      closeIcon={
        modalInfo.closable ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999]'
            onClick={() => onCancel(modalInfo)}
          />
        ) : null
      }>
      {genModalContent(modalInfo)}
    </Modal>
  );
};

export default ModalUpdateOfficeId;
