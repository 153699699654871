import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  FormInputPassword,
  FormSelect,
} from '@common-ui';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import {
  PartnerUserStatusListCreateOptions,
  ROLE_USER,
  patternValidateEmail,
  patternValidatePhoneNumber,
  patternValidateUsername,
} from '@sky-booking-config/utils';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ToggleProvider from '../ToggleProvider';
import useCreateAndUpdateOfficeId from '../useCreateAndUpdateOfficeId';
import { SelectOptionItem } from '@tixlabs/types';

type Props = {
  //
};

export const CreateOfficeIdCtn = (props: Props) => {
  const navigate = useNavigate();

  const { userData } = useUserStore();

  const {
    createOfficeIdMethod,
    domainListOption,
    allProviders,
    isLoadingCreateOffice,
    handleSubmitCreateOfficeId,
    getDomainListOption,
    getOfficeIdListOption,
    getAllProviderOption,
  } = useCreateAndUpdateOfficeId();
  const providerOptions: SelectOptionItem[] = useMemo(() => {
    return (
      (allProviders.length > 0 &&
        userData &&
        userData.providerIdsList.length > 0 &&
        (userData.providerIdsList.map((providerId) =>
          allProviders.find((provider) => provider.value === providerId)
        ) as SelectOptionItem[])) ||
      []
    );
  }, [userData, allProviders]);

  useEffect(() => {
    getDomainListOption();
    if (userData?.rolesList[0] === ROLE_USER.SUPER_ADMIN) {
      getOfficeIdListOption();
    }
    getAllProviderOption();
  }, []);

  useEffect(() => {
    const domainId = userData?.domainId;
    if (!domainId) {
      return;
    }
    createOfficeIdMethod.setValue('domain', domainId);
  }, [userData?.domainId]);

  return (
    <Form
      autoComplete='off'
      methods={createOfficeIdMethod}
      onSubmit={handleSubmitCreateOfficeId}
      className='max-w-[1100px] flex flex-col gap-y-5'>
      <div className='flex flex-col gap-y-5 p-4 border rounded'>
        <div className='flex gap-x-5'>
          <div className='w-1/3'>
            <FormInput
              name='userName'
              label='Tên đăng nhập'
              placeholder='Nhập tên đăng nhập'
              rules={{
                required: 'Nhập tên đăng nhập',
                validate: {
                  maxLength: (value: string) =>
                    value.length <= 20 || 'Tối đa 20 kí tự',
                  minLength: (value: string) =>
                    value.length >= 8 || 'Tối thiểu 8 kí tự',
                },
                pattern: {
                  value: patternValidateUsername,
                  message: 'Tên đăng nhập không hợp lệ',
                },
                onChange(event) {
                  createOfficeIdMethod.clearErrors('userName');
                },
              }}
              inputProps={{
                autoComplete: 'new-userName',
              }}
            />
          </div>
          <div className='w-1/3'>
            <FormInputPassword
              name='password'
              label='Mật khẩu'
              placeholder='Nhập mật khẩu'
              rules={{
                required: 'Nhập mật khẩu',
                validate: {
                  minLength: (value: string) =>
                    value.length >= 8 || 'Tối thiểu 8 kí tự',
                },
                onChange(event) {
                  createOfficeIdMethod.clearErrors('password');
                },
              }}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </div>
          <div className='w-1/3'>
            <FormGroupRadio
              name='status'
              label='Trạng thái'
              className='flex'
              radioOptions={PartnerUserStatusListCreateOptions}
            />
          </div>
        </div>
        <div className='w-2/3'>
          <FormSelect
            name='domain'
            label='Domain'
            placeholder='Chọn domain'
            selectOptions={domainListOption}
            inputProps={{ isOnlyValue: true, disabled: true }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Thông tin liên hệ</span>
        <div className='flex flex-col gap-y-5'>
          <div className='flex gap-x-5'>
            <div className='w-1/3'>
              <FormInput
                name='name'
                label='Họ tên'
                placeholder='Nhập họ tên'
                rules={{
                  required: 'Nhập họ tên',
                  onChange(event) {
                    createOfficeIdMethod.clearErrors('name');
                  },
                }}
              />
            </div>
            <div className='w-1/3'>
              <FormInput
                name='phone'
                label='Điện thoại'
                placeholder='Nhập điện thoại'
                rules={{
                  // required: 'Nhập diện thoại',
                  validate: {
                    phonePattern: (value: string) => {
                      if (value.length > 0) {
                        return (
                          value.match(patternValidatePhoneNumber) ||
                          'Số điện thoại không hợp lệ'
                        );
                      } else {
                        return true;
                      }
                    },
                  },
                  onChange(event) {
                    createOfficeIdMethod.clearErrors('phone');
                  },
                }}
              />
            </div>
            <div className='w-1/3'>
              <FormInput
                name='email'
                label='Email'
                placeholder='Nhập email'
                rules={{
                  // required: 'Nhập email',
                  validate: {
                    emailPattern: (value: string) => {
                      if (value.length > 0) {
                        return (
                          value.match(patternValidateEmail) ||
                          'Email không hợp lệ'
                        );
                      } else {
                        return true;
                      }
                    },
                  },
                  onChange(event) {
                    createOfficeIdMethod.clearErrors('email');
                  },
                }}
              />
            </div>
          </div>
          <div className='w-2/3'>
            <FormInput
              name='shopName'
              label='Công ty/Đại lý'
              placeholder='Tên công ty/đại lý'
              rules={{
                required: 'Nhập công ty/đại lý',
                onChange(event) {
                  createOfficeIdMethod.clearErrors('shopName');
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Chọn provider</span>

        <div className='w-full flex flex-col gap-y-3'>
          {providerOptions && providerOptions.length > 0 ? (
            providerOptions.map((item, index) => {
              return (
                <ToggleProvider
                  key={index}
                  label={item.label}
                  value={item.value}
                  nameField='enabledProvidersList'
                />
              );
            })
          ) : (
            <span>Không có Provider nào khả thi</span>
          )}
        </div>
      </div>

      <div className='flex justify-end gap-x-5'>
        <Button
          variant='outline'
          onClick={() => {
            // Go back router
            navigate(-1);
          }}>
          Quay lại
        </Button>
        <ButtonLoading
          loading={isLoadingCreateOffice}
          isShowChildWhenLoading
          type='submit'>
          Thêm Agent
        </ButtonLoading>
      </div>
    </Form>
  );
};

export default CreateOfficeIdCtn;
