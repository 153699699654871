import {
  Button,
  ButtonLoading,
  CloseIcon,
  Form,
  FormInput,
  LoadingRing,
  Textarea,
  User3FillIcon,
} from '@common-ui';
import ModalContent from '@payment-portal/components/Modal/ModalContent';
import { useAppMutation, useCurrency } from '@payment-portal/hooks/internals';
import {
  useErrorMessageData,
  useUserStore,
} from '@payment-portal/hooks/stores';
import { EModalMode } from '@payment-portal/types';
import { EModalActionTopUp, TModalImage } from '@payment-portal/types/ui/modal';
import { EPartnershipRole, checkRoleUser } from '@payment-portal/utils';
import { ETopUpRequestStatus, ITopUpRequestInfo } from '@tixlabs/grpc-client';
import {
  IUpdateTopupStatusReq,
  topUpServiceApi,
} from '@tixlabs/grpc-client/web';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  //
};

export const DetailTopUp = (props: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserStore();
  const { formatPrice } = useCurrency();
  const [note, setNote] = useState('');
  const { topUpId } = useParams();
  const methods = useForm<IUpdateTopupStatusReq>({
    defaultValues: {
      updatedAt: 0,
      id: '',
      note: '',
    },
    mode: 'all',
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [topUpDetail, setTopUpDetail] = useState<ITopUpRequestInfo>();
  const [isShowImg, setIsShowImg] = useState<TModalImage>({
    imgUrl: '',
    open: false,
  });
  const { getErrorMessage } = useErrorMessageData();

  const [stateModal, setStateModal] = useState<EModalActionTopUp>(
    EModalActionTopUp.APPROVAL
  );

  const { mutateAsync: getTopupDetail, isLoading } = useAppMutation({
    mutationKey: ['topUpServiceApi', 'getTopupDetail'],
    mutationFn: topUpServiceApi.getTopupDetail,
    onSuccess: async ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        if (
          checkRoleUser(EPartnershipRole.APPRAISAL, userData) ||
          checkRoleUser(EPartnershipRole.APPRAISAL_MANAGER, userData)
        ) {
          if (
            checkRoleUser(EPartnershipRole.APPRAISAL, userData) &&
            data.status !== ETopUpRequestStatus.WAITINGAPPROVAL
          ) {
            toast.error(
              'Bạn không được phép xem thông tin yêu cầu nạp tiền này!'
            );
            navigate('/dashboard/top-up-request-management/');
          }
          if (
            checkRoleUser(EPartnershipRole.APPRAISAL_MANAGER, userData) &&
            !(
              data.status === ETopUpRequestStatus.PROCESSING ||
              data.status === ETopUpRequestStatus.FAILED ||
              data.status === ETopUpRequestStatus.COMPLETED
            )
          ) {
            toast.error(
              'Bạn không được phép xem thông tin yêu cầu nạp tiền này!'
            );
            navigate('/dashboard/top-up-request-management/');
          }
        }
        setTopUpDetail(data);
        setNote(data.note);
        methods.reset({
          updatedAt: data.updatedAt,
          id: data.id,
          // appraisal => waiting approve => process.
          // appraisal_manager => process => approved.
          status: checkRoleUser(EPartnershipRole.APPRAISAL, userData)
            ? ETopUpRequestStatus.PROCESSING
            : ETopUpRequestStatus.APPROVED,
        });
      } else {
        // handle error/ call api get message error
        setErrorMsg(getErrorMessage(errorCode));
        navigate('/dashboard/top-up-request-management/');
      }
    },
    onError: (error) => {
      // handle error when network/system error
      toast.error('Có lỗi xảy ra khi truy cập thông tin nạp tiền này!');
      console.log('error', error);
      navigate('/dashboard/top-up-request-management/');
    },
  });

  const { mutateAsync: updateTopupStatus } = useAppMutation({
    mutationKey: ['topUpServiceApi', 'updateTopupStatus'],
    mutationFn: topUpServiceApi.updateTopupStatus,
    onSuccess: ({ isSuccess, errorCode }) => {
      console.log(isSuccess, errorCode);
      if (isSuccess) {
        setStateModal(
          methods.getValues('status') !== ETopUpRequestStatus.REJECTED
            ? EModalActionTopUp.APPROVAL_SUCCESS
            : EModalActionTopUp.REJECT_SUCCESS
        );
        setIsOpenModal(true);
        return;
      } else {
        // handle error/ call api get message error
        setErrorMsg(getErrorMessage(errorCode));
      }
    },
    onError: (error) => {
      // handle error when network/system error
      console.log('error', error);
    },
  });

  const handleSubmit = async (data: IUpdateTopupStatusReq) => {
    try {
      await updateTopupStatus({
        id: data.id,
        status: data.status,
        updatedAt: data.updatedAt,
        note: data.note,
      });
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleOk = () => {
    setIsOpenModal(false);
    navigate('/dashboard/top-up-request-management/');
  };

  const generateModalBody = () => {
    switch (stateModal) {
      case EModalActionTopUp.APPROVAL:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.CONFIRM}
            content={
              <div className='flex flex-col self-stretch'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Hãy chắc chắn thông tin đã chính xác trước khi phê duyệt
                  </span>
                </div>
                <div className='flex items-center justify-center pt-3 gap-2'>
                  <Button
                    className='flex-1'
                    variant='outline'
                    onClick={() => {
                      setStateModal(EModalActionTopUp.APPROVAL);
                      setIsOpenModal(false);
                    }}>
                    Để sau
                  </Button>
                  <Button
                    className='flex-1'
                    onClick={() => {
                      methods.setValue(
                        'status',
                        checkRoleUser(EPartnershipRole.APPRAISAL, userData)
                          ? ETopUpRequestStatus.PROCESSING
                          : ETopUpRequestStatus.APPROVED
                      );
                      handleSubmit(methods.getValues());
                    }}
                    isDisabled={methods.formState.isSubmitting}>
                    Phê duyệt
                  </Button>
                </div>
              </div>
            }
          />
        );
      case EModalActionTopUp.APPROVAL_SUCCESS:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.SUCCESS}
            content={
              <div className='flex flex-col'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Phê duyệt yêu cầu nạp tiền thành công
                  </span>
                  <span className='text-center'>
                    Phê duyệt yêu cầu nạp tiền thành công.
                  </span>
                </div>
                <div className='flex items-center justify-center pt-3'>
                  <Button className='w-1/5' onClick={handleOk}>
                    Đóng
                  </Button>
                </div>
              </div>
            }
          />
        );

      case EModalActionTopUp.REJECT:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.ERROR}
            content={
              <div className='flex flex-col self-stretch'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Từ chối yêu cầu nạp tiền
                  </span>
                  <FormInput
                    name='note'
                    label={'Vui lòng nhập nội dung phản hồi'}
                    placeholder='Nhập lý do từ chối'
                    className='pt-5'
                    rules={{
                      required: 'Nội dụng không được để trống',
                    }}
                  />
                </div>
                <div className='flex items-center justify-center pt-3 gap-2'>
                  <Button
                    className='flex-1'
                    variant='outline'
                    onClick={() => {
                      setStateModal(EModalActionTopUp.APPROVAL);
                      setIsOpenModal(false);
                    }}>
                    Để sau
                  </Button>
                  <Button
                    className='flex-1'
                    onClick={() => {
                      if (!methods.getValues('note')) {
                        methods.setError('note', {
                          message: 'Nội dung không được để trống',
                        });
                      } else {
                        methods.setValue(
                          'status',
                          ETopUpRequestStatus.REJECTED
                        );
                        handleSubmit(methods.getValues());
                      }
                    }}
                    isDisabled={methods.formState.isSubmitting}>
                    Xác nhận
                  </Button>
                </div>
              </div>
            }
          />
        );
      case EModalActionTopUp.REJECT_SUCCESS:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.SUCCESS}
            content={
              <div className='flex flex-col'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Từ chối yêu cầu nạp tiền thành công
                  </span>
                  <span className='text-center'>
                    Từ chối yêu cầu nạp tiền thành công
                  </span>
                </div>
                <div className='flex items-center justify-center pt-3'>
                  <Button className='w-1/5' onClick={handleOk}>
                    Đóng
                  </Button>
                </div>
              </div>
            }
          />
        );
      case EModalActionTopUp.ERROR:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.ERROR}
            content={
              <div className='flex flex-col'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Xác nhận yêu cầu nạp tiền thất bại
                  </span>
                  <span className='text-center'>
                    Xác nhận yêu cầu nạp tiền thất bại. Vui lòng thử lại sau!
                  </span>
                </div>
                <Button
                  className='w-1/5'
                  onClick={() => {
                    setStateModal(EModalActionTopUp.APPROVAL);
                    setIsOpenModal(false);
                  }}>
                  Đóng
                </Button>
              </div>
            }
          />
        );
    }
  };

  const getInfoTopUp = async () => {
    topUpId &&
      (await getTopupDetail({
        id: topUpId,
      }));
  };

  useEffect(() => {
    getInfoTopUp();
  }, []);

  return isLoading ? (
    <div className='flex justify-center items-center h-[300px] w-full'>
      <LoadingRing />
    </div>
  ) : (
    <Form
      methods={methods}
      onSubmit={handleSubmit}
      className='flex flex-col space-y-3 max-w-[900px]'>
      <Modal open={isOpenModal} closeIcon={false} footer={null} centered={true}>
        <div>{generateModalBody()}</div>
      </Modal>
      <Modal
        open={isShowImg.open}
        closeIcon={
          <CloseIcon
            className='w-5 h-5 text-[#090909] bg-white'
            onClick={() => {
              setIsShowImg({
                open: false,
                imgUrl: '',
              });
            }}
          />
        }
        centered={true}
        footer={null}>
        <div className='flex items-center justify-center max-w-[900px] '>
          <img className='' alt={isShowImg.imgUrl} src={isShowImg.imgUrl} />
        </div>
      </Modal>
      <div className='flex flex-col space-y-5'>
        <div className='flex space-x-2.5 items-center'>
          <User3FillIcon className='w-5 h-5 shrink-0' />
          <span className='font-semibold text-lg'>Yêu cầu nạp tiền</span>
        </div>
        <div className='flex flex-col space-y-5 bg-white border rounded p-4'>
          <div className='flex flex-col space-y-1.5'>
            <div className='flex flex-col space-y-2 5'>
              <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                Thông tin nạp tiền
              </h3>
              <div className='border-b border-neutral-5'></div>
            </div>
            {topUpDetail ? (
              <div className='grid grid-cols-3 items-baseline gap-2.5'>
                <FormInput
                  name='agentCode'
                  placeholder='Nhập agent Id'
                  label='Mã đại lý'
                  inputProps={{
                    disabled: true,
                  }}
                  value={topUpDetail.agentInfo?.agentCode}
                />
                <FormInput
                  inputProps={{
                    disabled: true,
                  }}
                  isShowError={true}
                  name='agentName'
                  label='Tên đại lý'
                  value={topUpDetail.agentInfo?.agentName}
                />
                <FormInput
                  isShowError={true}
                  name='topupAmount'
                  label='Số tiền nạp'
                  placeholder='Nhập số tiền nạp'
                  inputProps={{
                    disabled: true,
                  }}
                  value={formatPrice(topUpDetail.topupAmount)}
                />
                <div className='col-span-2 flex flex-col space-y-2 align-baseline'>
                  <span>Ghi chú</span>
                  <Textarea
                    rows={4}
                    style={{
                      resize: 'none',
                    }}
                    placeholder='Viết ghi chú...'
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                      methods.setValue('note', e.target.value);
                    }}
                    disabled={true}
                  />
                </div>
                <div className='col-span-1'>
                  {!checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) ? (
                    <FormInput
                      isShowError={true}
                      name='createdUser'
                      label='Người tạo đơn'
                      inputProps={{
                        disabled: true,
                      }}
                      value={topUpDetail.createdUser?.name}
                    />
                  ) : null}
                </div>
                <div className='col-span-3 grid grid-cols-3 gap-2'>
                  <span className='col-span-3'>Tệp đính kèm</span>
                  <div className='col-span-3 grid grid-cols-5 gap-2.5 p-2 border border-secondary-3'>
                    {topUpDetail.attachmentsList.length > 0 &&
                      topUpDetail.attachmentsList.map((imgUrl, index) => {
                        return (
                          <div
                            key={index}
                            className='relative w-full aspect-video rounded-lg overflow-hidden shadow-md'>
                            <img
                              className='block mx-auto max-h-full'
                              src={imgUrl}
                              alt={imgUrl || '' + index}
                              onClick={() => {
                                window.open(imgUrl, '_blank');
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {errorMsg && <span className='text-red-5'>{errorMsg}</span>}
        </div>
      </div>
      {topUpDetail ? (
        topUpDetail.status === ETopUpRequestStatus.WAITINGAPPROVAL ||
        topUpDetail.status === ETopUpRequestStatus.PROCESSING ? (
          <div className='flex flex-row items-center justify-end gap-4'>
            <Button
              theme='primary'
              variant='outline'
              onClick={() => {
                setStateModal(EModalActionTopUp.REJECT);
                setIsOpenModal(true);
              }}>
              Từ chối
            </Button>
            <ButtonLoading
              theme='primary'
              isShowChildWhenLoading={true}
              onClick={() => {
                setIsOpenModal(true);
              }}>
              Phê duyệt
            </ButtonLoading>
          </div>
        ) : topUpDetail.status === ETopUpRequestStatus.FAILED ? (
          <div className='flex flex-row items-center justify-end gap-4'>
            <Button
              variant='outline'
              onClick={() => {
                // Go back router
                navigate(-1);
              }}>
              Quay lại
            </Button>
            <ButtonLoading
              theme='primary'
              isShowChildWhenLoading={true}
              onClick={() => {
                setIsOpenModal(true);
              }}>
              Duyệt lại
            </ButtonLoading>
          </div>
        ) : null
      ) : null}
    </Form>
  );
};
