import React, { useEffect, useState } from 'react';
import ListHiddenFee from './ListHiddenFee';
import { Form, FormSelect } from '@common-ui';
import { useConfigHiddenFee } from '@sky-booking-config/hooks/apps';
import HiddenFeeModal, {
  ESwitchModal,
  TModalHiddenFee,
} from './HiddenFeeModal';
import { useWatch } from 'react-hook-form';

const DEFAULT_MODAL_INFO: TModalHiddenFee = {
  open: false,
  state: ESwitchModal.NONE,
  centered: true,
  width: 'auto',
};
export const ConfigHiddenFeeCtn = () => {
  const [modalInfo, setModalInfo] =
    useState<TModalHiddenFee>(DEFAULT_MODAL_INFO);

  const {
    pagination,
    listHiddenFee,
    filterFormMethods,
    isLoadingHiddenFee,
    officeIdOptionList,
    isLoadingAddHiddenFee,
    isLoadingUpdateHiddenFee,
    isLoadingDeleteHiddenFee,

    onChangePage,
    getOfficeIdList,
    onChangeOfficeId,
    getListFlightHiddenFee,
    handleAddFlightHiddenFee,
    handleDeleteFlightHiddenFee,
    handleUpdateFlightHiddenFee,
  } = useConfigHiddenFee();

  const officeIdValue = useWatch({
    name: 'officeId',
    control: filterFormMethods.control,
  });

  useEffect(() => {
    getOfficeIdList();
  }, []);

  useEffect(() => {
    onChangeOfficeId(officeIdValue);
  }, [officeIdValue]);

  return (
    <>
      <Form
        methods={filterFormMethods}
        onSubmit={(data) => getListFlightHiddenFee(data)}
        className='w-full flex flex-col gap-y-2.5'>
        <div className='max-w-[256px]'>
          <FormSelect
            name='officeId'
            label='Agent'
            placeholder='Chọn Agent'
            selectOptions={officeIdOptionList}
            inputProps={{ isOnlyValue: true }}
          />
        </div>
        <ListHiddenFee
          pagination={pagination}
          listHiddenFee={listHiddenFee}
          isLoading={isLoadingHiddenFee}
          onChangePage={onChangePage}
          handleUpdateModalInfo={setModalInfo}
        />
      </Form>

      <HiddenFeeModal
        modalInfo={modalInfo}
        isLoadingDeleteHiddenFee={isLoadingDeleteHiddenFee}
        isLoadingAddHiddenFee={isLoadingAddHiddenFee}
        isLoadingUpdateHiddenFee={isLoadingUpdateHiddenFee}
        handleUpdateModalInfo={setModalInfo}
        handleAddFlightHiddenFee={handleAddFlightHiddenFee}
        handleUpdateFlightHiddenFee={handleUpdateFlightHiddenFee}
        handleDeleteFlightHiddenFee={handleDeleteFlightHiddenFee}
      />
    </>
  );
};

export default ConfigHiddenFeeCtn;
