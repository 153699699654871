import { Button, DeleteBin6Line, Edit2FillIcon } from '@common-ui';
import {
  IAirlineHub,
  IFlightHiddenFee,
  IFlightServiceFee,
} from '@tixlabs/grpc-client';
import { useCurrency } from '@web-admin/hooks/internals';
import { EModalMode } from '@web-admin/types';
import { SERVICE_FEE_LABEL } from '@web-admin/utils';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SetStateAction } from 'react';
import { ESwitchModal, TModalHiddenFee } from './HiddenFeeModal';
import { useAirlines } from '@sky-booking-config/hooks/stores';
import { useVICountry } from '@sky-booking-config/hooks/apps';

type Props = {
  data: IFlightServiceFee[];
  handleUpdateModalInfo: (value: SetStateAction<TModalHiddenFee>) => void;
  pagination: TablePaginationConfig;
  isLoading: boolean;
};

type THiddenFeeItem = {
  key: string;
} & IFlightHiddenFee;

const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const TableHiddenFee = ({
  data,
  pagination,
  isLoading,
  handleUpdateModalInfo,
}: Props) => {
  const { formatCurrency } = useCurrency();

  const { airlinesObjectByCode } = useAirlines();
  const { countriesObjectByCode } = useVICountry();

  const dataSource: THiddenFeeItem[] = data.map((serviceFee, index) => ({
    ...serviceFee,
    key: serviceFee.id,
  }));

  const columns: ColumnsType<THiddenFeeItem> = [
    {
      key: 'type',
      dataIndex: 'type',
      title: <TitleColumns title='Loại chuyến bay' />,
      render: (text, record) => {
        return SERVICE_FEE_LABEL[record.type];
      },
    },
    {
      key: 'route',
      dataIndex: 'route',
      title: <TitleColumns title='Hành trình bay' />,
      render: (text, record) => {
        if (record.route) {
          return record.route
            .split('-')
            .map((item) => countriesObjectByCode[item].country)
            .join('-');
        }
        return 'Tất cả';
      },
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: <TitleColumns title='Số tiền' />,
      render: (text, record) => {
        return record.amount || !record.percent
          ? formatCurrency(record.amount)
          : '-';
      },
    },
    {
      key: 'percent',
      dataIndex: 'percent',
      title: <TitleColumns title='Phần trăm' />,
      render: (text, record) => {
        return record.percent ? `${record.percent * 100}%` : '-';
      },
    },
    {
      key: 'airlineCode',
      dataIndex: 'airlineCode',
      title: <TitleColumns title='Hãng hàng không' />,
      render: (value, record) => {
        return record.airlineCode
          ? airlinesObjectByCode?.[record.airlineCode]?.name?.vi
          : 'Tất cả';
      },
    },
    {
      key: 'bookingClass',
      dataIndex: 'bookingClass',
      title: <TitleColumns title='Hạng đặt chỗ' />,
      render: (value, record) => {
        return record.bookingClass || 'Tất cả';
      },
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: <TitleColumns title='Thao tác' />,
      render: (text, record) => {
        return (
          <div className='flex items-center gap-x-1.5'>
            <Button
              variant='ghost'
              prefixIcon={<Edit2FillIcon />}
              onClick={() =>
                handleUpdateModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.UPDATE_HIDDEN_FEE,
                  hiddenFee: record,
                }))
              }>
              Sửa
            </Button>
            <Button
              variant='ghost'
              theme='red'
              prefixIcon={<DeleteBin6Line />}
              onClick={() => {
                handleUpdateModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.DELETE_HIDDEN_FEE,
                  hiddenFee: record,
                }));
              }}>
              Xóa
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      size='small'
      pagination={pagination}
      loading={isLoading}
    />
  );
};

export default TableHiddenFee;
