import {
  EBookingStatus,
  EPaxType,
  ETransferBookingConfigStatus,
} from '@tixlabs/grpc-client';
import { EGender, EPassengerType, SelectOptionItem } from '@tixlabs/types';
import { IPassengerForm } from '@web-booker/types/ui/booking';

export enum EPronoun {
  MALE = 'mr',
  FEMALE = 'mrs',
}

export const PASSENGER_TYPE_LABEL = {
  [EPassengerType.ADT]: 'Người lớn',
  [EPassengerType.CHD]: 'Trẻ em',
  [EPassengerType.INF]: 'Trẻ sơ sinh',
};

export const PAX_TYPE_LABEL = {
  [EPaxType.ADT]: 'Người lớn',
  [EPaxType.CHD]: 'Trẻ em',
  [EPaxType.INF]: 'Trẻ sơ sinh',
};

export const PAX_TYPE_SHORT_LABEL = {
  [EPaxType.ADT]: 'adt',
  [EPaxType.CHD]: 'chd',
  [EPaxType.INF]: 'inf',
};

export const GENDER_TYPE_LABEL = {
  [EGender.GENDER_TYPE_MALE]: 'Nam',
  [EGender.GENDER_TYPE_FEMALE]: 'Nữ',
};

export const PRONOUN_TYPE_LABEL = {
  [EPronoun.MALE]: 'Ông',
  [EPronoun.FEMALE]: 'Bà',
};

export const DEFAULT_PASSENGER_INFO: IPassengerForm = {
  firstName: '',
  lastName: '',
  dob: null,
  gender: EGender.GENDER_TYPE_NONE,
  type: EPassengerType.ADT,
  passportNumber: '',
  expirationDate: null,
  nationality: 'VN',
  seatOptionsList: [],
  baggageOptionsList: [],
};

export const GENDER_OPTIONS = [
  {
    label: GENDER_TYPE_LABEL[EGender.GENDER_TYPE_MALE],
    value: EGender.GENDER_TYPE_MALE,
  },
  {
    label: GENDER_TYPE_LABEL[EGender.GENDER_TYPE_FEMALE],
    value: EGender.GENDER_TYPE_FEMALE,
  },
];

export const PRONOUN_OPTIONS = [
  {
    label: PRONOUN_TYPE_LABEL[EPronoun.MALE],
    value: EPronoun.MALE,
  },
  {
    label: PRONOUN_TYPE_LABEL[EPronoun.FEMALE],
    value: EPronoun.FEMALE,
  },
];

export const BOOKING_STATUS_LABEL = {
  [EBookingStatus.NONE]: 'Tất cả',
  [EBookingStatus.COMPLETE]: 'Đã xuất vé',
  [EBookingStatus.INPROGRESS]: 'Đang giữ chỗ',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'Xuất vé thất bại',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'Đang được xử lý',
  [EBookingStatus.BOOKINGSTATUSTRANSFERRED]: 'Đang xuất hộ',
  [EBookingStatus.BOOKINGSTATUSNOTCOMPLETE]: 'Đã xuất vé 1 phần',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'Xuất vé thất bại',
};

export enum EIssueTicketError {
  BOOKING_NOT_FOUND = 'Không tìm thấy đơn hàng',
  BOOKING_IS_EXPIRED = 'Đơn hàng đã hết hạn',
  BOOKING_IS_CANCELLED = 'Đơn hàng đã bị hủy',
  BOOKING_IS_FAILED = 'Đơn hàng bị lỗi',
  BOOKING_IS_COMPLETED = 'Đơn hàng đã được xuất trước đó',
  INSUFFICIENT_BALANCE = 'Số dư ví không đủ để xuất vé',
  SOMETHING_ERROR = 'Lỗi xuất vé, vui lòng chọn lại chuyến bay khác!',
  ISSUE_FAILED = 'Xuất vé thất bại, vui lòng chọn lại chuyến bay khác!',
}

export enum EBookingTicketError {
  // team product define.
  FLIGHT_SOLD_OUT = 'Rất tiếc, vé chuyến bay bạn chọn đã bán hết.',
  INVALID_SEAT_REQUEST = 'Rất tiếc, vé chuyến bay bạn chọn đã bán hết.',
  SEAT_NOT_AVAILABLE = 'Rất tiếc, vé chuyến bay bạn chọn đã bán hết.',
}

export const SELECT_STATUS_BOOKING_OPTIONS: SelectOptionItem[] = [
  {
    value: EBookingStatus.NONE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.NONE],
  },
  {
    value: EBookingStatus.INPROGRESS,
    label: BOOKING_STATUS_LABEL[EBookingStatus.INPROGRESS],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSNOTCOMPLETE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSNOTCOMPLETE],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSTICKETFAILED,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSTICKETFAILED],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSPAID,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSPAID],
  },
  {
    value: EBookingStatus.COMPLETE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.COMPLETE],
  },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSREFUND,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSREFUND],
  // },
  {
    value: EBookingStatus.BOOKINGSTATUSCANCELLED,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSCANCELLED],
  },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSEXPIRED,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSEXPIRED],
  // },
];

export const TRANSFER_BOOKING_CONFIG_STATUS_LABEL = {
  [ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSNONE]: 'Tất cả',
  [ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE]: 'Hoạt động',
  [ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE]: 'Tạm tắt',
};

export const SELECT_TRANSFER_BOOKING_CONFIG_STATUS_OPTIONS: SelectOptionItem[] =
  [
    {
      value: ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSNONE,
      label:
        TRANSFER_BOOKING_CONFIG_STATUS_LABEL[
          ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSNONE
        ],
    },
    {
      value: ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE,
      label:
        TRANSFER_BOOKING_CONFIG_STATUS_LABEL[
          ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE
        ],
    },
    {
      value: ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE,
      label:
        TRANSFER_BOOKING_CONFIG_STATUS_LABEL[
          ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE
        ],
    },
  ];
