import { EFlightItineraryType } from '@tixlabs/grpc-client';
import { EItineraryType } from '@web-admin/types/ui';

export const SERVICE_FEE_LABEL = {
  [EFlightItineraryType.IDOMESTIC]: 'Bay trong nước',
  [EFlightItineraryType.IINTERNATIONAL]: 'Bay quốc tế',
};
export const itineraryTypeLabel = {
  [EItineraryType.ONE_WAY]: 'Một chiều',
  [EItineraryType.ROUND_TRIP]: 'Khứ hồi',
  [EItineraryType.MULTIPLE_TRIP]: 'Đa chặng',
};
export const OPTION_SERVICE_FEE = [
  {
    label: 'Tất cả',
    value: EFlightItineraryType.INONE,
  },
  {
    label: SERVICE_FEE_LABEL[EFlightItineraryType.IDOMESTIC],
    value: EFlightItineraryType.IDOMESTIC,
  },
  {
    label: SERVICE_FEE_LABEL[EFlightItineraryType.IINTERNATIONAL],
    value: EFlightItineraryType.IINTERNATIONAL,
  },
];
