/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partner_admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partner_admin/wallet.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var airplane_base_pb = require('../../airplane/base_pb.js')

var airplane_transaction_pb = require('../../airplane/transaction_pb.js')

var airplane_hub_pb = require('../../airplane/hub_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partner_admin = require('./wallet_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner_admin.WalletServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner_admin.WalletServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.RetrieveBalanceReq,
 *   !proto.airplane.web_partner_admin.RetrieveBalanceRes>}
 */
const methodDescriptor_WalletService_RetrieveBalance = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.WalletService/RetrieveBalance',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.RetrieveBalanceReq,
  proto.airplane.web_partner_admin.RetrieveBalanceRes,
  /**
   * @param {!proto.airplane.web_partner_admin.RetrieveBalanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.RetrieveBalanceRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveBalanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.RetrieveBalanceRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.RetrieveBalanceRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.WalletServiceClient.prototype.retrieveBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/RetrieveBalance',
      request,
      metadata || {},
      methodDescriptor_WalletService_RetrieveBalance,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveBalanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.RetrieveBalanceRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.WalletServicePromiseClient.prototype.retrieveBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/RetrieveBalance',
      request,
      metadata || {},
      methodDescriptor_WalletService_RetrieveBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletReq,
 *   !proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletRes>}
 */
const methodDescriptor_WalletService_RetrieveAgencyCommissionWallet = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.WalletService/RetrieveAgencyCommissionWallet',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletReq,
  proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletRes,
  /**
   * @param {!proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.WalletServiceClient.prototype.retrieveAgencyCommissionWallet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/RetrieveAgencyCommissionWallet',
      request,
      metadata || {},
      methodDescriptor_WalletService_RetrieveAgencyCommissionWallet,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.RetrieveAgencyCommissionWalletRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.WalletServicePromiseClient.prototype.retrieveAgencyCommissionWallet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/RetrieveAgencyCommissionWallet',
      request,
      metadata || {},
      methodDescriptor_WalletService_RetrieveAgencyCommissionWallet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.ListTransactionReq,
 *   !proto.airplane.web_partner_admin.ListTransactionRes>}
 */
const methodDescriptor_WalletService_ListTransaction = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.WalletService/ListTransaction',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.ListTransactionReq,
  proto.airplane.web_partner_admin.ListTransactionRes,
  /**
   * @param {!proto.airplane.web_partner_admin.ListTransactionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.ListTransactionRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.ListTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.ListTransactionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.ListTransactionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.WalletServiceClient.prototype.listTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/ListTransaction',
      request,
      metadata || {},
      methodDescriptor_WalletService_ListTransaction,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.ListTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.ListTransactionRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.WalletServicePromiseClient.prototype.listTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/ListTransaction',
      request,
      metadata || {},
      methodDescriptor_WalletService_ListTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.ExportTransactionReq,
 *   !proto.airplane.web_partner_admin.ExportTransactionRes>}
 */
const methodDescriptor_WalletService_ExportTransactions = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.WalletService/ExportTransactions',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.ExportTransactionReq,
  proto.airplane.web_partner_admin.ExportTransactionRes,
  /**
   * @param {!proto.airplane.web_partner_admin.ExportTransactionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.ExportTransactionRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.ExportTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.ExportTransactionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.ExportTransactionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.WalletServiceClient.prototype.exportTransactions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/ExportTransactions',
      request,
      metadata || {},
      methodDescriptor_WalletService_ExportTransactions,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.ExportTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.ExportTransactionRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.WalletServicePromiseClient.prototype.exportTransactions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/ExportTransactions',
      request,
      metadata || {},
      methodDescriptor_WalletService_ExportTransactions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner_admin.TopUpAgencyReq,
 *   !proto.airplane.web_partner_admin.TopUpAgencyRes>}
 */
const methodDescriptor_WalletService_TopUpAgency = new grpc.web.MethodDescriptor(
  '/airplane.web_partner_admin.WalletService/TopUpAgency',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner_admin.TopUpAgencyReq,
  proto.airplane.web_partner_admin.TopUpAgencyRes,
  /**
   * @param {!proto.airplane.web_partner_admin.TopUpAgencyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner_admin.TopUpAgencyRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner_admin.TopUpAgencyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner_admin.TopUpAgencyRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner_admin.TopUpAgencyRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner_admin.WalletServiceClient.prototype.topUpAgency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/TopUpAgency',
      request,
      metadata || {},
      methodDescriptor_WalletService_TopUpAgency,
      callback);
};


/**
 * @param {!proto.airplane.web_partner_admin.TopUpAgencyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner_admin.TopUpAgencyRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner_admin.WalletServicePromiseClient.prototype.topUpAgency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner_admin.WalletService/TopUpAgency',
      request,
      metadata || {},
      methodDescriptor_WalletService_TopUpAgency);
};


module.exports = proto.airplane.web_partner_admin;

